import { useEffect, useState } from "react";
import APIManager from '../../../../../../lib/apiManager';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from "../../../../../../components/IconButton";
import Palanquita from "../../../../../../components/Palanquita";
import TarjetaDeProducto from "../../../../../../components/TarjetaDeProducto";


const Formulario = ({productoSeleccionado, errores, setErrores, limpiarErrores, onClose, onSaveProducto, bajaItem}) => {

  const [nombre, setNombre] = useState('');
  const [codigo, setCodigo] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [active, setActive] = useState(false);
  const [imagenes, setImagenes] = useState([]);
  const [tags, setTags] = useState([]);

  const [imagenCambiada, setImagenCambiada] = useState(false);

  const noImageURL = useSelector(state => state.sistema.noImageURL);

  const [etiqueta, setEtiqueta] = useState('');
  const [etiquetasSugeridas, setEtiquetasSugeridas] = useState([]);

  const [tarjetaRef, setTarjetaRef] = useState(null);
  const [productoAgrandado, setProductoAgrandado] = useState(null);

  useEffect(() => {
    limpiarErrores();

    if (productoSeleccionado) {
      setNombre(productoSeleccionado.nombre ? productoSeleccionado.nombre : '');
      setCodigo(productoSeleccionado.codigo ? productoSeleccionado.codigo : '');
      setDescripcion(productoSeleccionado.descripcion ? productoSeleccionado.descripcion : '');
      setActive(productoSeleccionado.active);
      setTags(productoSeleccionado.tags);
      setImagenes(productoSeleccionado.imagenes);
    }
  },[productoSeleccionado]);

  const guardarFormulario = (event) => {

    const erroresDelFormulario = [];

    if (!nombre) {
      console.log('nombre:', nombre);
      erroresDelFormulario.push({message: 'El nombre del producto no puede estar vacio'});
    }

    setErrores(erroresDelFormulario);

    if ((erroresDelFormulario.length === 0)) {
      const itemModificado = {};

      Object.assign(itemModificado, productoSeleccionado);

      itemModificado.nombre = nombre;
      itemModificado.codigo = codigo;
      itemModificado.descripcion = descripcion;
      itemModificado.tags = tags;
      itemModificado.imagenes = imagenes;
      itemModificado.active = active;

      if (onSaveProducto) {
        onSaveProducto(itemModificado);
      }

    }
  }

  const cancelarFormulario = () => {
    if (onClose) {
      onClose();
    }
  }

  const agregarEtiqueta = (item) => {
    setTags([...tags.filter(tag => tag.id !== item.id), item]);
  }

  const quitarEtiqueta = (item) => {
    setTags(tags.filter(tag => tag.id !== item.id));
  }

  useEffect(() => {
    if (!!etiqueta) {
      APIManager.current.getList('tags', { nombre: etiqueta}, (response) => {
        setEtiquetasSugeridas(response.data.listado.filter(tag => !tags.find(element => element.id === tag.id)));
      });
    }
  }, [etiqueta, tags]);

  const stringsSimilares = (stringA, stringB) => {
    return stringA.toLocaleUpperCase().replaceAll(' ', '').localeCompare(stringB.toLocaleUpperCase().replaceAll(' ', ''), undefined, { sensitivity: 'base' }) === 0;
  }

  const esNuevaEtiqueta = (tagName) => {
    return (!tags.find(item => stringsSimilares(tagName, item.nombre))
      && !etiquetasSugeridas.find(item => stringsSimilares(tagName, item.nombre)));
  }

  const cambiarImagen = async (event) => {
    setImagenCambiada(true);
    const imageFiles = event.target.files;
    if (imageFiles.length > 0) {
      const base64file = await toBase64(imageFiles[0]);
      setImagenes([{file: base64file, imagen: imageFiles[0].name, tipo: 1, orden: 1, url: URL.createObjectURL(imageFiles[0]) }]);
    } else {
      setImagenes([]);
    }

  }

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

  return (
    <div className="producto-formulario formulario">
      <div>
        <h2 className="form__title">Datos del Producto</h2>
        <div className="form__row">
          <div className="input-combo producto__nombre">
            <input className={nombre ? 'has-value' : ''} type='text' maxLength={100} value={nombre} onChange={(event) => setNombre(event.target.value)}/>
            <label>Nombre</label>
          </div>
          <div className="input-combo producto__codigo">
            <input className={codigo ? 'has-value' : ''} type='text' value={codigo} onChange={(event) => setCodigo(event.target.value)}/>
            <label>Código</label>
          </div>
          <div className="palanquita-combo producto__active has-value">
            <label>Activo</label>
            <Palanquita title="Activo" active={active} onToggle={() => setActive(!active)} />
            {/* <input type="checkbox" checked={active} onChange={(event) => setActive(event.target.checked)}/> */}
          </div>
        </div>
        <div className="form__row">
          <div className="input-combo producto__descripcion">
            <textarea className={descripcion ? 'has-value' : ''} rows={4} value={descripcion} onChange={(event) => setDescripcion(event.target.value)}/>
            <label>Descripción</label>
          </div>
          <div className="palanquita-combo producto--preview">
            <label>Preview</label>
            <TarjetaDeProducto setMyRef={setTarjetaRef} estaAgrandado={productoAgrandado} setEstaAgrandado={setProductoAgrandado} nombre={nombre} descripcion={descripcion} imagenes={imagenes} precio={productoSeleccionado.precio} />
          </div>
        </div>
        <div className="form__row">
          <div className="producto--imagen">
            <label>Imagen</label>
            <div className="imagen-combo">
              <div className="producto--img" style={{ backgroundImage: "url(" + (imagenes && (imagenes.length > 0) ? imagenes[0].url : noImageURL) + ")" }}></div>
              {/* <img className="producto--img" src={(imagenes && (imagenes.length > 0)) ? imagenes[0].url : noImageURL} alt='Imagen' /> */}
              <input type="file" onChange={cambiarImagen} />
            </div>
          </div>
        </div>
        <div className="form__row">
          <div className="producto--tags">
            <label>Etiquetas</label>
            <ul className="filtros__list">
              {tags.map((tag) =>
                  <li className={"filtros__item is--activo"} key={tag.id} >
                    <button className="control--filtro" onClick={() => quitarEtiqueta(tag)}>
                      <span className="button__label">
                        <FontAwesomeIcon className={"button__icon icon--minus"} icon={["fas", "minus"]} />
                        {tag.nombre}
                      </span>
                    </button>
                  </li>
              )}
            </ul>
            <div className="tags__sugerencias">
              <input placeholder="Escriba la etiqueta para recibir sugerencias" value={etiqueta} onInput={(event) => setEtiqueta(event.target.value)} ></input>
              {!!etiqueta && esNuevaEtiqueta(etiqueta) && <button onClick={() => agregarEtiqueta({id: null, nombre: etiqueta, descripcion: ''})}>Nueva etiqueta</button>}
              {!!etiqueta && (etiquetasSugeridas.length > 0) && <ul className="filtros__list">
                {etiquetasSugeridas.map((tag) =>
                  <li className={"filtros__item" + ' is--activo'} key={tag.id} >
                    <button className="control--filtro" onClick={() => agregarEtiqueta(tag)}>
                      <span className="button__label">
                        <FontAwesomeIcon className={"button__icon icon--" + 'plus'} icon={["fas", "plus"]} />
                        {tag.nombre}
                      </span>
                    </button>
                  </li>
                )}
              </ul>}
            </div>
          </div>
        </div>
      </div>
      <div>
        {!productoSeleccionado.id && <button onClick={guardarFormulario}>Crear Producto</button>}
        {productoSeleccionado.id && <button onClick={guardarFormulario}>Guardar</button>}
        <button onClick={cancelarFormulario}>Cancelar</button>
        <IconButton iconName="trash" onClick={bajaItem} label="Eliminar" />
      </div>
    </div>
  )

}

export default Formulario;