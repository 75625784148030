import IconButton from '../IconButton';

const NavMenuItem = ({className, buttonClassName, iconClassName, iconLibName, iconName, label, labelClassName, onClick}) => {
  return (
    <li className={className}>
      <IconButton className={buttonClassName} onClick={onClick} iconName={iconName} iconClassName={iconClassName} iconLibName={iconLibName} label={label} labelClassName={labelClassName} />
    </li>
    );
}

export default NavMenuItem;
