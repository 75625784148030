import { useEffect, useState } from "react";
import ListaDeSeleccion from '../../components/ListaDeSeleccion';
import { useSelector } from 'react-redux';
import APIManager from '../../../../lib/apiManager';
import Formulario from './components/Formulario';
import IconButton from '../../../../components/IconButton';

const Agrupaciones = ({ setTitulo }) => {

  const [items, setItems] = useState([]);

  const [itemsSeleccionados, setItemsSeleccionados] = useState([]);
  const [itemSeleccionado, setItemSeleccionado] = useState(null);

  const comercio = useSelector(state => state.admin.comercio);
  const [errores, setErrores] = useState([]);


  const columnas = [
    { field: 'nombre', title: 'Nombre' },
    { field: 'descripcion', title: 'descripcion' },
    { field: 'acciones', component: item => (!!itemSeleccionado && item.id === itemSeleccionado.id) ? <IconButton iconName="trash" disabled={(item.id === -1)} visible={item.id !== -1} onClick={bajaItem} /> : null}
  ];

  const recargarLista = (callback) => {
    const queryData = {
      comercioID: comercio.id,
      asArray: true,
    }
    APIManager.current.getList('agrupaciones', queryData, (response) => {
      setItems(response.data.listado.map(item => {
        return item;
      }));
    });
  };

  const actualizar = () => {
    // Cierra el formulario, quita la seleccion y recarga la lista
    onFormularioClose();
    recargarLista();
  }

  const limpiarErrores = () => {
    setErrores([]);
  }

  const onFormularioClose = () => {
    if (!!itemSeleccionado && (itemSeleccionado.id === -1)) {
      setItems(items.filter(item => item.id !== -1));
    }
    setItemSeleccionado(null);
    setItemsSeleccionados([]);
  }

  const nuevoItem = () => {
    const itemNuevo = {
      id: -1,
      idcomercios: comercio.id,
      nombre: '',
      descripcion: '',
      tags: [],
      miembros: [],
      active: true,
    };

    setItems([...items, itemNuevo]);
    setItemSeleccionado(itemNuevo);
    setItemsSeleccionados([itemNuevo]);
  }

  const guardarItem = (item) => {
    APIManager.current.sendRequest('miembro', 'modificarAgrupacion', { agrupacion: item }, (response) => {
      if (response.success) {
        setItemSeleccionado(response.data);
        // onFormularioClose();
        // recargarLista();
      }
    });
  }

const bajaItem = () => {
    if (window.confirm('Desea eliminar la agrupación: "' + itemSeleccionado.nombre + '" ?'))
    {
      APIManager.current.sendRequest('miembro', 'bajaDeAgrupacion', { agrupacion: itemSeleccionado }, (response) => {
        if (response.success) {
          onFormularioClose();
          recargarLista();
        }
      });

    }
  }

const onItemChange = (item) => {
  if (!itemSeleccionado || (itemSeleccionado.id !== item.id)) {
    setItemSeleccionado(item);
    setItemsSeleccionados([item]);
  } else {
    setItemSeleccionado(null);
    setItemsSeleccionados([]);
  }

}

  useEffect(() => {
    setTitulo('Agrupaciones', comercio);
    recargarLista();
  }, []);

return (
  <>
    <div className="screen-title">
      <h2>Listado de Agrupaciones</h2>
      <div className="screen-title__controls">
        <IconButton className="admin-button" onClick={actualizar} label="Actualizar" />
        <IconButton className="admin-button" onClick={nuevoItem} label="Nueva Agrupación"/>
      </div>
    </div>
    <ListaDeSeleccion className='items' columnas={columnas} items={items} itemSeleccionado={itemSeleccionado} itemsSeleccionados={itemsSeleccionados} disabledOnSeleccion={true} onSelectionChange={onItemChange} />
    {!!itemSeleccionado &&
        <Formulario agrupacionSeleccionada={itemSeleccionado} errores={errores} setErrores={setErrores} limpiarErrores={limpiarErrores} onClose={onFormularioClose} onSaveItem={guardarItem} bajaItem={bajaItem} />
      }

  </>
);
}

export default Agrupaciones;