import { useEffect, useState } from "react";
import APIManager from '../../../../../../lib/apiManager';


const Formulario = ({comercioSeleccionado, errores, setErrores, limpiarErrores, onClose, onSaveComercio}) => {

  const [nombre, setNombre] = useState('');
  const [codigo, setCodigo] = useState('');
  const [direccion, setDireccion] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [nombres, setNombres] = useState('');
  const [apellidos, setApellidos] = useState('');
  const [email, setEmail] = useState('');
  const [emailModificado, setEmailModificado] = useState(false);
  const [emailValido, setEmailValido] = useState(false);
  const [staffVerificado, setStaffVerificado] = useState(false);
  const [staffValido, setStaffValido] = useState(false);
  const [staffDeComercios, setStaffDeComercios] = useState([]);
  const [usuarioExistente, setUsuarioExistente] = useState(false);

  useEffect(() => {

    limpiarErrores();
    resetStaffData();

    if (comercioSeleccionado) {
      setNombre(comercioSeleccionado.nombre ? comercioSeleccionado.nombre : '');
      setCodigo(comercioSeleccionado.codigo ? comercioSeleccionado.codigo : '');
      setDireccion(comercioSeleccionado.direccion ? comercioSeleccionado.direccion : '');
      setDescripcion(comercioSeleccionado.descripcion ? comercioSeleccionado.descripcion : '');

      const administrador = comercioSeleccionado.staff.find(staff => (staff.rol === 'ROL_ADMINISTRADOR') && staff.active);

      if (administrador) {
        setNombres(administrador.usuario.nombres ? administrador.usuario.nombres : '');
        setApellidos(administrador.usuario.apellidos ? administrador.usuario.apellidos : '');
        setEmail(administrador.usuario.email ? administrador.usuario.email : '');
      }
      setEmailModificado(false);
      setEmailValido(!!administrador && !!administrador.usuario.email);
      setStaffVerificado(!!administrador);
      setStaffValido(!!administrador);
      setUsuarioExistente(!!administrador);
    }
  },[comercioSeleccionado]);

  const validarStaff = () => {
    setStaffValido(true);
    limpiarErrores();
  }

  const verificarStaff = () =>{

    limpiarErrores();
    resetStaffData();

    // Consultamos si el usuario con ese email existe y si es Staff de un comercio.
    APIManager.sendRequest('post', 'usuario', 'checkStaff', { email }, (response) => {
      if (response.success) {
        setUsuarioExistente(!!response.data.usuario);
        setStaffVerificado(true);
        if (response.data.usuario) {
          // Como existe usuarios, le asigno Nombres y Apellidos
          setNombres(response.data.usuario.nombres ? response.data.usuario.nombres : '');
          setApellidos(response.data.usuario.apellidos ? response.data.usuario.apellidos : '');
          setStaffDeComercios(response.data.staff);
          setStaffValido(!!response.data.staff.find(staff => staff.comercio.id === comercioSeleccionado.id));
        }
      } else {
        setErrores(response.errors);
      }
    });
  }

  const resetStaffData = () => {
    setStaffValido(false);
    setStaffDeComercios([]);
    setNombres('');
    setApellidos('');
    setUsuarioExistente(false);
  }

  const handleEmail = (event) => {
    setEmailModificado(true);
    setEmailValido(!!event.target.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i));
    setStaffVerificado(false);
    setEmail(event.target.value);
    resetStaffData();
  }

  const guardarFormulario = (event) => {

    const erroresDelFormulario = [];

    if (!emailValido) {
      console.log('emailValido:', emailValido);
      erroresDelFormulario.push({message: 'Email no valido'});
    }

    if (!staffValido) {
      console.log('staffValido:', staffValido);
      erroresDelFormulario.push({message: 'Staff no validado'});
    }

    if (!nombre) {
      console.log('nombre:', nombre);
      erroresDelFormulario.push({message: 'El nombre del comercio no puede estar vacio'});
    }

    if (!codigo) {
      console.log('codigo', codigo);
      erroresDelFormulario.push({message: 'El codigo del comercio no puede estar vacio'});
    }

    if (codigo.indexOf(' ') !== -1) {
      console.log('codigo', codigo);
      erroresDelFormulario.push({message: 'El codigo del comercio no puede contener espacios'});
    }

    setErrores(erroresDelFormulario);

    if ((erroresDelFormulario.length === 0) && staffValido && emailValido) {
      const itemModificado = {};

      Object.assign(itemModificado, comercioSeleccionado);

      itemModificado.nombre = nombre;
      itemModificado.codigo = codigo;
      itemModificado.direccion = direccion;
      itemModificado.descripcion = descripcion;
      itemModificado.staff = [];

      const administrador = {nombres, apellidos, email};

      itemModificado.staff.push(administrador);

      if (onSaveComercio) {
        onSaveComercio(itemModificado, administrador);
      }

      setStaffDeComercios([]);
    }
  }

  const cancelarFormulario = () => {
    setStaffValido(false);
    setStaffDeComercios([]);
    if (onClose) {
      onClose();
    }
  }

  return (
    <div className="formulario">
      <div>
        <h2>Datos del Comercio</h2>
        <div>
          <label>Nombre</label>
          <input type='text' value={nombre} onChange={(event) => setNombre(event.target.value)}/>
        </div>
        <div>
          <label>Código</label>
          <input type='text' value={codigo} onChange={(event) => setCodigo(event.target.value)}/>
        </div>
        <div>
          <label>Dirección</label>
          <input type='text' value={direccion} onChange={(event) => setDireccion(event.target.value)}/>
        </div>
        <div>
          <label>Descripción</label>
          <input type='text' value={descripcion} onChange={(event) => setDescripcion(event.target.value)}/>
        </div>
      </div>
      <div>
        <h2>Datos del Administrador</h2>
        <div>
          <label>Dirección de correo electrónico </label>
          <input type='text' className={'email input--' + (emailValido? 'valid' : 'invalid')} value={email} onChange={handleEmail}/>
          <button disabled={!(emailValido && !staffVerificado)} onClick={verificarStaff}>Verificar Staff</button>
        </div>
        {emailModificado && staffValido && <h3>Usuario verificado correctamente</h3>}
        {emailValido && staffVerificado && (!staffValido || !usuarioExistente) &&
        <div>
          <h3>{usuarioExistente ? 'El usuario ya existe' : 'Usuario nuevo'}</h3>
          <div className={'staff ' + (staffValido? 'valid' : 'invalid')}>
            {staffDeComercios.length > 0 &&
              <>
                <label>El usuario es administrador de los siguientes comercios : </label>
                <ul>
                  {staffDeComercios.map((item, index) => {
                    return <li key={index}>{item.comercio.nombre}</li>
                  })}
                </ul>
              </>
            }
          </div>

          <div>
            <label>Nombres</label>
            <input type='text' disabled={usuarioExistente} value={nombres} onChange={(event) => setNombres(event.target.value)}/>
          </div>
          <div>
            <label>Apellidos</label>
            <input type='text' disabled={usuarioExistente} value={apellidos} onChange={(event) => setApellidos(event.target.value)}/>
          </div>
          <button disabled={staffValido} onClick={validarStaff}>Validar Staff</button>
        </div>
        }
      </div>
      <div>
        {!comercioSeleccionado.id && <button onClick={guardarFormulario}>Crear Comercio</button>}
        {comercioSeleccionado.id && <button onClick={guardarFormulario}>Guardar</button>}
        <button onClick={cancelarFormulario}>Cancelar</button>
      </div>
    </div>
  )

}

export default Formulario;