import { useEffect } from 'react';
import { render } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import NavMenuItem from '../../../../components/NavMenuItem';
import APIManager from '../../../../lib/apiManager';

const AdminNavegacion = ({actualizarMenu}) => {

  const dispatch = useDispatch();
  const location = useLocation();
  const adminMenu = useSelector(state => state.admin.menu);

  const navigateTo = (menuItem) => {
    const pagina = Object.assign({}, menuItem);
    pagina.seccion = 'admin/' + menuItem.seccion;
    dispatch({ type: 'sistema/setPaginaActual', payload: pagina });

  }

  const isSelected = (path) => {
    return location.pathname.includes(path);
  }

  const menuItem = (item, index) => {
    if (!!item.seccion && !!item.label !== '-') {
      return <NavMenuItem
      key={!!item.seccion ? item.seccion : item.label}
      className={"admin-navegacion__item" + (isSelected('admin/' + item.seccion) ? " is-selected" : "")}
      iconName={item.icon}
      onClick={() => {
        if (!!item.seccion) { navigateTo(item) }
      }}
      label={item.label}
      />
    } else {
      return <hr key={index} className="admin-navegacion__divisor"></hr>
    }
  }

  const salir = () => {
    // Salimos de administracion
    actualizarMenu(null);

  }

  return (
    <div className="admin-navegacion">
      <ul className="admin-navegacion__item-list">
        {adminMenu && (adminMenu.length > 0) && adminMenu.map((item, index) => menuItem(item, index))}
        <>
          {/* Combo "Salir" */}
          {menuItem({ label: '-' })}
          <NavMenuItem className="admin-navegacion__item" iconName="sign-out" label="Salir" onClick={salir} />
        </>
      </ul>
    </div>
  );
}

export default AdminNavegacion