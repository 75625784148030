import { createAction, createReducer } from '@reduxjs/toolkit';

const addPedido = createAction('pedidos/add');
const removePedido = createAction('pedidos/remove');
const setPedidos = createAction('pedidos/set');

const initialState = [];

const pedidosReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(addPedido, (state, action) => {
      state.pedidos[action.payload.id] = action.payload;
    })
    .addCase(removePedido, (state, action) => {
      delete(state.pedidos[action.payload.id]);
    })
    .addCase(setPedidos, (state, action) => {
      return [...action.payload];
    })
})

export default pedidosReducer;