import { useEffect, useState } from "react";
import {
  Switch,
  Route,
  useHistory,
  useLocation
} from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import MainLayoutBar from '../MainLayoutBar';
import MainLayout from '../MainLayout';
import APIManager from '../../lib/apiManager';
import Notificaciones from "../../components/Notificaciones";
import QRScanner from '../QRScan';

const AppRouter = ({isPortrait, ...props}) => {

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const paginaActual = useSelector(state => state.sistema.paginaActual);
  const comercio = useSelector(state => state.sistema.comercio);

  const [showQRScanner, setShowQRScanner] = useState(false);

  const [menuClassName, setMenuClassName] = useState('');

  useEffect(() => {
    if (paginaActual !== null) {
      let pagina = (typeof paginaActual === 'string' ? paginaActual : paginaActual.seccion);
      if (pagina.substr(0, 1) !== '/') {
        pagina = '/' + pagina;
      }
      if (history) {
        APIManager.navigate(dispatch, null);
        history.push(pagina);
      }
    } else {
      if (location.pathname === '/') {
        if (comercio) {
          history.push('/carta');
        } else {
          history.push('/recepcion');
        }
      }
    }
  }, [paginaActual, history, dispatch]);

  return (
    <main className={"app" + (isPortrait ? ' portrait' : ' landscape')}>
      <Notificaciones {...props} />
      <QRScanner active={showQRScanner} setActive={setShowQRScanner} />
      <Switch>
        <Route path="/test" render={(myProps) => <MainLayoutBar {...myProps} setScanQR={setShowQRScanner} {...props} />} />
        <Route path="/fede" render={(myProps) => <MainLayoutBar {...myProps} setScanQR={setShowQRScanner} {...props} />} />
        <Route path="/admin" render={(myProps) => <MainLayoutBar {...myProps} setScanQR={setShowQRScanner} {...props} />} />
        <Route path="/login" render={(myProps) => <MainLayoutBar {...myProps} />} {...props} />
        <Route path="/signup" render={(myProps) => <MainLayout {...myProps} />} {...props} />
        <Route path="/logout" render={(myProps) => <MainLayout {...myProps} />} {...props} />
        <Route path="/historial" render={(myProps) => <MainLayoutBar {...myProps} setScanQR={setShowQRScanner} {...props} />} />
        <Route path="/carta" render={(myProps) => <MainLayoutBar {...myProps} setScanQR={setShowQRScanner} {...props} />} />
        <Route path="/recepcion" render={(myProps) => <MainLayoutBar {...myProps} menuClassName={menuClassName} setMenuClassName={setMenuClassName} setScanQR={setShowQRScanner} {...props} />} />
        <Route path="/pedir" render={(myProps) => <MainLayoutBar {...myProps} menuClassName={menuClassName} setMenuClassName={setMenuClassName} setScanQR={setShowQRScanner} {...props} />} />
        <Route path="/codigos" render={(myProps) => <MainLayoutBar {...myProps} setScanQR={setShowQRScanner} {...props} />} />
        <Route path="/:codigo" component={MainLayout} />
      </Switch>
    </main>
  );
}

export default AppRouter;