import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import APIManager from '../../../../lib/apiManager';
import ListaDeSeleccion from '../../components/ListaDeSeleccion';
import { formatoDeFecha } from '../../../../lib/format';
import EstadoDePedido from "../../../../components/EstadoDePedido";

const Pedidos = ({ setTitulo }) => {

  const dispatch = useDispatch();

  const [items, setItems] = useState([]);

  const [itemsSeleccionados, setItemsSeleccionados] = useState([]);
  const [itemSeleccionado, setItemSeleccionado] = useState(null);

  const comercio = useSelector(state => state.admin.comercio);
  const estadosDePedido = useSelector(state => state.admin.estadosDePedido);

  const columnas = [
    { field: 'ubicacion_nombre', title: 'Ubicacion' },
    { field: 'cliente_nombre', title: 'Cliente'},
    { field: 'date', title: 'Fecha', formatField: 'date_format' },
    { field: 'estado', title: 'Estado', component: (estado) => {return <EstadoDePedido estadosDePedido={estadosDePedido} estadoActual={estado} /> } },
    { field: 'codigo', title: 'Codigo' },
    { field: 'productos', title: 'Productos', component: (items) => <ol className="pedido__lista-de-productos">{items.map((item, index) => <li key={index}><span>{item.cantidad + ' x '}</span><span> {item.producto.nombre}</span></li>)}</ol> },
    {
      field: 'acciones', title: '', component: (item) => {
        return (
          <div className="pedidos__acciones">
            {/* <button onClick={() => modificarPedido(item)}>M</button> */}
            <div className="control__estado">
              <button className="control__avanzar-estado admin-button" onClick={() => cambiarEstado(item)}>{'Avanzar estado'}</button>
              {estadosDePedido.length > 0 &&
              <select className="control__selector-estado" onChange={(event) => setEstadoSelect(event, item)}>{estadosDePedido.map(estadoActual => {
                if (estadoActual.orden > item.estado.orden) {
                  if (!item.proximoEstado) {
                    item.proximoEstado = estadoActual;
                  }
                  return <option key={estadoActual.id} value={estadoActual.id}>{estadoActual.nombre}</option>
                } else {return null}
              })}</select>
              }
            </div>
          </div>
        )
      }
    }
  ];

  const [incluirTerminados, setIncluirTerminados] = useState(false);

  const actualizarEstadosDePedido = () => {
    if (comercio) {
      APIManager.getList('estadosDePedido', { comercioID: comercio.id }, (response) => {
        dispatch({ type: 'admin/setEstadosDePedido', payload: response.data.listado });
      })
    }
  }

  const recargarLista = (callback) => {
    const queryData = {
      conProductos: true,
      paraAdmin: true,
      active: true,
      comercioID: comercio.id,
      orderBy: 'fecha desc',
      asArray: true,
    }
    if (!incluirTerminados) {
      queryData.estados = [1, 2, 3]; // TODO: Cambiar por estados correspondientes segun lo definido por el comercio actual
    }
    APIManager.current.getList('pedidos', queryData, (response) => {
      setItems(response.data.listado.map(item => {
        item.date = new Date(item.fecha);
        item.date_format = formatoDeFecha(item.date);
        item.estado = estadosDePedido.find(estado => estado.id === item.estado);
        return item;
      }));
    });
  };

  const onItemChange = (item) => {
    setItemSeleccionado(item);
    setItemsSeleccionados([item]);
  }

  const cambiarEstado = (item) => {
    item.estado = item.proximoEstado.id;
    APIManager.current.sendRequest('pedido', 'cambiarEstado', { pedido: item }, (response) => {
      if (response.success) {
        recargarLista();
      }
    })
  }

  const modificarPedido = (item) => {
    alert(item);
  }

  const setEstadoSelect = (event, item) => {
    item.proximoEstado = estadosDePedido.find(estado => estado.id === parseInt(event.target.value));
  }

  useEffect(() => {
    actualizarEstadosDePedido();
    setTitulo('Pedidos',comercio);
  }, []);

  useEffect(() => {
    (estadosDePedido.length > 0) && recargarLista();
  }, [incluirTerminados, estadosDePedido]);

  return (
    <>
      <h2 className="screen-title">Listado de Pedidos</h2>
      <button onClick={recargarLista}>Actualizar</button>
      <label htmlFor="incluirTerminados">Incluir terminados </label><input id="incluirTerminados" type="checkbox" value={incluirTerminados} onChange={() => setIncluirTerminados(!incluirTerminados)} />
      {estadosDePedido.length > 0 && <ListaDeSeleccion className='items' columnas={columnas} items={items} itemSeleccionado={itemSeleccionado} itemsSeleccionados={itemsSeleccionados} onSelectionChange={onItemChange} disabledOnSeleccion={false} />}
    </>
  );
}

export default Pedidos;