import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import APIManager from '../../lib/apiManager';
import { useSelector } from 'react-redux';

const Codigos = () => {

  const history = useHistory();

  const [codigo, setCodigo] = useState('');
  const [dataDeCodigo, setDataDeCodigo] = useState(null);
  const usuario = useSelector(state => state.sistema.usuario);

  const verificarCodigo = () => {
    APIManager.current.getUIData('getCodigoParaActivar', { codigo }, (data) => {
      setDataDeCodigo(data);
    }, (response) => {
      setDataDeCodigo(null);
      if (!!response.errors && (response.errors.length > 0)) {
        alert(response.errors[0].message);
      }

    });
  }

  const aceptarInvitacion = () => {
    if (usuario.rol === 'ROL_ADMINISTRADOR') {
      // Los Administradores del sistema NO pueden ser STAFF de un comercio
      APIManager.current.navigate('/');
    } else {
      APIManager.current.sendRequest('staff', 'aceptarInvitacion', { invitacion: dataDeCodigo }, (response) => {
        if (response.success) {
          APIManager.current.navigate('/');
        }
      });
    }
  }

  const rechazarInvitacion = () => {

  }

  const renderParaData = () => {

    switch (dataDeCodigo.tipo) {
      case 'STAFF_INVITADOS':
        return <div>
          <p>Te invitaron a formar parte del staff del comercio { dataDeCodigo.comercio.nombre } !</p>
          <button onClick={aceptarInvitacion}>Aceptar invitación</button>
          <button onClick={rechazarInvitacion}>Rechazar invitación</button>
        </div>;

      default:
        return null;
    }

  }

  return (
    <div className="layout--base">
      <div className={"main-container"}>
        <section className="section--codigos">
          <div>
            <label htmlFor="inputCodigo">Introduzca el codigo que desea activar</label>
            <input id="inputCodigo" type="text" value={codigo} onChange={(event) => setCodigo(event.target.value)} />
            <button onClick={verificarCodigo}>Verificar codigo</button>
          </div>
          {!!dataDeCodigo && renderParaData()}
        </section>
      </div>
    </div>
  );
}

export default Codigos;