import './fontawesome.js';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './App.css';
import APIManager from './lib/apiManager';
import {
  BrowserRouter as Router, useHistory
} from "react-router-dom";
import AppRouter from './screens/AppRouter';
import { useInterval } from './lib/useInterval';

function App() {

  const dispatch = useDispatch();
  const history = useHistory();

  const [isPortrait, setIsPortrait] = useState(true);
  const comercio = useSelector(state => state.sistema.comercio);
  const [comercioAnterior, setComercioAnterior] = useState(null);
  const cliente = useSelector(state => state.sistema.cliente);
  const usuario = useSelector(state => state.sistema.usuario);
  const [usuarioAnterior, setUsuarioAnterior] = useState(null);
  const adminComercio = useSelector(state => state.admin.comercio);
  const [tiposDeNotificacionVisibles, setTiposDeNotificacionVisibles] = useState([]);
  const [mediosDeNotificacion, setMediosDeNotificacion] = useState(['LOG']);
  const notificaciones = useSelector(state => state.notificaciones);


  const apiManager = new APIManager(dispatch, history);

  const actualizarCliente = () => {
    apiManager.getUIData('getCliente', {}, (data) => {
      APIManager.current.dispatch({ type: 'sistema/setCliente', payload: data });
    });
  }

  const actualizarMiembro = () => {
    if (!!comercio && !!comercio.id) {
      apiManager.getUIData('getMiembro', {comercioID: comercio.id}, (data) => {
        APIManager.current.dispatch({ type: 'sistema/setMiembro', payload: data });
      }, (response) => {
        APIManager.current.dispatch({ type: 'sistema/setMiembro', payload: null });
      });
    } else {
      APIManager.current.dispatch({ type: 'sistema/setMiembro', payload: null });
    }
  }

  const actualizarNotificaciones = () => {
    const postData = {
      cliente: cliente,
      usuario: usuario,
      comercio: comercio,
      adminComercio: adminComercio,
      fechaDeActualizacion: notificaciones.ultimaActualizacion,
      mediosDeNotificacion: ['LOG', 'POPUP', 'PUSH'],
    };
    APIManager.current.getUIData('getNotificaciones', postData, data => {
      data.lista.forEach(notificacion => {
        notificacion.nueva = true;
        // console.log('notificacionAGuardar: ', notificacion);
      });
      APIManager.current.dispatch({type: 'notificaciones/addAll', payload: data});
    });
  }

  const displayNotifications = () => {
    // Displays PUSH notifications
    const pushNotifications = notificaciones.lista.filter(notificacion => notificacion.medio_nombre === 'PUSH');
    if (pushNotifications.length > 0)
    Notification.requestPermission().then(() => {
      if (Notification.permission === 'granted') {
        navigator.serviceWorker.getRegistration().then(function (reg) {
          pushNotifications.forEach(notificacion => {
            if (!!reg) {
              reg.showNotification(notificacion.mensaje.title, { body: notificacion.mensaje.message });
            } else {
              new Notification(notificacion.mensaje.title, { body: notificacion.mensaje.message });
            }
          });
        });
      }
    });
  }

  const mostrarPushNotifications = () => {
    try {
      Notification.requestPermission().then(function (result) {
        notificaciones.lista.forEach(notificacion => {
          new Notification(notificacion.mensaje.title, { body: notificacion.mensaje.message });
        });
      });
    } catch (e) {
      alert(e);
      return false;
    }
  }

  useEffect(() => {
    if (!!notificaciones) {
      displayNotifications();
      // mostrarPushNotifications();
    }
  }, [notificaciones]);

  useEffect(() => {
    if (!!comercio && (!comercioAnterior || (comercioAnterior.id !== comercio.id))) {
      APIManager.getUIData('getMonedas', { comercioID: comercio.id, active: true}, (data) => {
        APIManager.current.dispatch({ type: 'sistema/setMonedas', payload: data.listado });
      });
      APIManager.getUIData('getListasDePrecios', { comercioID: comercio.id, active: true }, (data) => {
        APIManager.current.dispatch({ type: 'sistema/setListasDePrecios', payload: data.listado });
      });
      APIManager.current.getUIData('getUbicaciones', { comercioID: comercio.id, active: true }, data => {
        APIManager.current.dispatch({ type: 'sistema/setUbicaciones', payload: data.listado });
      })
      //      dispatch({ type: 'sistema/setUbicacion', payload: { id: 1, comercio: comercio } });
    }
    actualizarCliente();
    setComercioAnterior(comercio);
  }, [comercio]);

  useEffect(() => {
    if (!!usuario && (!usuarioAnterior || (usuario.id !== usuarioAnterior.id))) {
      actualizarMiembro();
    }
    setUsuarioAnterior(usuario);
  }, [usuario]);

  /*
  useInterval(() => {
    // Actualiza la info del cliente
    actualizarCliente();
  }, 5 * 60 * 1000); // periodo de 5 minutos
 */

  useInterval(() => {
    // Actualiza la info del cliente
    actualizarNotificaciones();
  // }, 1 * 60 * 1000); // periodo de 1 minuto
  }, 5 * 1000); // periodo de 5 segundos

  useLayoutEffect(() => {
    function updateIsPortrait() {
      setIsPortrait(window.innerWidth < window.innerHeight);
    }
    window.addEventListener("resize", updateIsPortrait);
    updateIsPortrait();
    return () => window.removeEventListener("resize", updateIsPortrait);
  }, []);

  return (
    <Router>
      <AppRouter isPortrait={isPortrait} tiposDeNotificacionVisibles={tiposDeNotificacionVisibles} setTiposDeNotificacionVisibles={ setTiposDeNotificacionVisibles } />
    </Router>
  );
}

export default App;
