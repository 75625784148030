import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import IconButton from '../IconButton';

const TarjetaDeProductoEnCarrito = ({ nombre, descripcion, precio, cantidad, imagenes, agregarProducto, quitarProducto, restarProducto }) => {
  const nombreRef = useRef(null);
  const moneda = useSelector(state => state.sistema.monedaUltima);
  const [estaAgrandado, setEstaAgrandado] = useState(false);

  useEffect( () => {

    // The 'current' property contains info of the reference:
    // align, title, ... , width, height, etc.
    if(nombreRef.current){

      let height = nombreRef.current.offsetHeight;
      let width = nombreRef.current.offsetWidth;

      console.log(height, width);
    }

  }, [nombreRef]);

  const mostrarTarjetita = () => {
    setEstaAgrandado(!estaAgrandado);
  };

  return (
    <div className="tarjeta-de-producto--in-cart">
      <div className="tarjeta-de-producto--in-cart__information-column">
        <h1 className="tarjeta-de-producto--in-cart__name">{nombre}</h1>
        <div className="tarjeta-de-producto--in-cart__meta">
          <div className="tarjeta-de-producto--in-cart__controls">
            <button className="control--remove-from-cart" onClick={restarProducto}>
              <div className="wrapper--icon">
                <FontAwesomeIcon className="icon--minus" icon={["fas", "minus"]} />
              </div>
            </button>
            <div className="tarjeta-de-producto--in-cart__wrapper-quantity">
              <p className="tarjeta-de-producto--in-cart__quantity">{cantidad}</p>
            </div>
            <button className="control--add-to-cart" onClick={agregarProducto}>
              <div className="wrapper--icon">
                <FontAwesomeIcon className="icon--plus" icon={["fas", "plus"]} />
              </div>
            </button>
            <IconButton 
              className="control--remove-all-from-cart" 
              onClick={quitarProducto} 
              iconName="trash"
              iconFixedWidth={true} 
              label="Quitar" />
          </div>
        </div>
      </div>
      <div className="tarjeta-de-producto--in-cart__price-column">
        <span className="tarjeta-de-producto--in-cart__total-price">
          {(moneda ? moneda.simbolo : '') + (cantidad * precio) + ' '}
          {/* <span className="currency-name">{(moneda ? moneda.nombre : '')}</span> */}
        </span>
        <div className="tarjeta-de-producto--in-cart__information--price">
          {precio && <p className="tarjeta-de-producto--in-cart__unit-price">
            <span className="currency-name">{(moneda ? moneda.nombre : '')}</span>
            {'(' + (moneda ? moneda.simbolo : '') + precio}
            <span>{' x ' + cantidad + ')'}</span>
          </p>}
        </div>
      </div>
      {/* <div className="tarjeta-de-producto__image" onClick={mostrarTarjetita} style={{"backgroundImage": "url(" + (imagenes && imagenes.length > 0 ? imagenes[0].url : '') + ")"}}></div> */}
    </div>
  );
}

export default TarjetaDeProductoEnCarrito