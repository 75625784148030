import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import APIManager from '../../lib/apiManager';
import IconButton from '../../components/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router';

const NavMenu = ({ visible, navMenuToggle, setScanQR }) => {
  const usuario = useSelector(state => state.sistema.usuario);
  const cliente = useSelector(state => state.sistema.cliente);
  const monedas = useSelector(state => state.sistema.monedas);
  const monedaPreferida = useSelector(state => state.sistema.monedaPreferida);
  const monedaUltima = useSelector(state => state.sistema.monedaUltima);
  const comercio = useSelector(state => state.sistema.comercio);
  const ubicacion = useSelector(state => state.sistema.ubicacion);
  const [moneda, setMoneda] = useState(null);

  const dispatch = useDispatch();
  const location = useLocation();

  const puedeAdministrar = () => {
    // Devuelve si hay usuario logueado y tiene permisos para administrar (es staff o super-admin)
    return (
      usuario && usuario.rol &&
      ((usuario.rol === 'ROL_ADMINISTRADOR') || (usuario.rol === 'ROL_STAFF'))
    );
  };

  const showQRScan = () => {
    setScanQR(true);
    navMenuToggle();
  }

  const setUbicacion = (nuevaUbicacion) => {
    if (!!nuevaUbicacion) {
      dispatch({ type: 'sistema/setUbicacion', payload: nuevaUbicacion });
      navMenuToggle();
    }
  }

  useEffect(() => {
    if (!moneda && monedas && (monedas.length > 0)) {
      let monedaSeleccionada = null;
      // 1. Si existe la monedaUltima, seleccionamos esa
      if (!monedaSeleccionada && !!monedaUltima) {
        monedaSeleccionada = monedas.find(
          (item) => item.idmonedas === monedaUltima.idmonedas
        );
      }
      // 2. Si existe la monedaPreferida, seleccionamos esa
      if (!monedaSeleccionada && !!monedaPreferida) {
        monedaSeleccionada = monedas.find(
          (item) => item.idmonedas === monedaPreferida.idmonedas
        );
      }
      // 3. Si existe la preferida del comercio, seleccionamos esa
      // 4. Seleccionamos la primera
      if (!monedaSeleccionada) {
        monedaSeleccionada = monedas[0];
      }

      setMoneda(monedaSeleccionada);
    } else {
      dispatch({ type: 'sistema/setMonedaUltima', payload: moneda });
    }
  }, [monedas, moneda, monedaPreferida, monedaUltima, dispatch]);

  return (
    <div className={'nav-menu' + (!visible ? ' is--hidden' : '')}>
      <div className="nav-menu__contents">
        <div className="nav-menu__information">
          <div className="location">
            {!!comercio && !!comercio.active && (
              <span className="location__business">
                <div className="layout--icon-container">
                  <FontAwesomeIcon
                    className="location__icon icon--map-marker fa-fw"
                    icon={['fas', 'map-marker-alt']}
                  />
                </div>
                <span className="location__business--name">{comercio.nombre}</span>
                {ubicacion && (
                  <span className="location__seating"> {'– ' + ubicacion.nombre} </span>
                )}
              </span>
            )}
          </div>
          <div className="currency">
            <div className="layout--icon-container">
              <FontAwesomeIcon className="currency__icon icon--money-bill fa-fw" icon={["fas", "coins"]} />
            </div>
            <span className="currency__text">
              <span>Precios en:</span> {moneda ? (moneda.nombre + ' – ' + moneda.descripcion) : ''}
            </span>
          </div>
          <div className="user">
            <div className="layout--icon-container">
              <FontAwesomeIcon
                className="user__icon icon--user fa-fw"
                icon={["fas", "user"]}
              />
            </div>
            {(!!usuario || (!!cliente && !!cliente.nombre))
              && <span>{usuario ? usuario.nombre : cliente.nombre}</span>}
          </div>
        </div>
        <ul className="nav-menu__list">
          <li className="nav-menu-item--qr-reader">
            <IconButton
              // className="control--ubicacion-qr-selector"
              className="control--navigation navigation--qr-reader"
              iconName="qrcode"
              iconFixedWidth={true}
              label="Escanear código QR"
              onClick={showQRScan}
            />
          </li>
          {puedeAdministrar() && (
          <li className="nav-menu-item--navigation">
            <IconButton
              className="control--navigation navigation--administracion"
              onClick={() => {
                APIManager.navigate(dispatch, 'admin', navMenuToggle);}}
              iconName="tools"
              iconFixedWidth={true}
              label="Administración" />
          </li>
          )}
          {(location.pathname !== '/recepcion') && <li className="nav-menu-item--comercio">
            <IconButton
              className="control--navigation navigation--cambiar-comercio pibun-logo"
              onClick={() => {
                APIManager.navigate(dispatch, 'recepcion', navMenuToggle);
              }}
              iconName="home"
              iconFixedWidth={true}
              label="Pibun" />
          </li>}
          {comercio && (<>
            {moneda && monedas.length > 1 && (
              <li className="nav-menu-item--moneda">
                <div className="control--currency-selector">
                  <label className="moneda__label" htmlFor="moneda-select">
                    <div className="layout--icon-container">
                      <FontAwesomeIcon className="label__icon icon--money-bill fa-fw" icon={["fas", "money-bill-wave-alt"]} />
                    </div>
                    <span className="label__text">Cambiar Moneda</span>
                  </label>
                  <select
                    className="moneda__select"
                    id="moneda-select"
                    onChange={(event) => {
                      setMoneda(monedas[event.target.value]);
                    }}
                    defaultValue={monedas.findIndex(
                      (item) => item.idmonedas === moneda.idmonedas
                    )}
                  >
                    {monedas &&
                      moneda &&
                      monedas.map((item, index) => (
                        <option key={index} value={index}>
                          {item.nombre + ' - ' + item.descripcion}
                        </option>
                      ))}
                  </select>
                </div>
              </li>
            )}
            {!!comercio.ubicaciones && (comercio.ubicaciones.length > 1) &&
              <li className="nav-menu-item--ubicacion">
                <div className="control--ubicacion-selector">
                  <label className="ubicacion__label" htmlFor="ubicacion-select">
                    <div className="layout--icon-container">
                      <FontAwesomeIcon className="label__icon icon--chair fa-fw" icon={["fas", "chair"]} />
                    </div>
                    <span className="label__text">Elegir Ubicación</span>
                  </label>
                  <select
                    className="ubicacion__select"
                    id="ubicacion-select"
                    defaultValue={''}
                    onChange={event => { setUbicacion(comercio.ubicaciones.find(item => item.id === parseInt(event.target.value))) }}
                  >
                    <>
                    <option value=''>Seleccione una ubicación</option>
                    {comercio.ubicaciones.map((item, index) =>
                      <option value={item.id} >{item.nombre}</option>
                    )}
                    </>
                  </select>
                </div>
                {/* <IconButton
                  className="control--ubicacion-qr-selector"
                  iconName="qrcode"
                  iconFixedWidth={true}
                  onClick={showQRScan}
                /> */}
              </li>
            }
          {/* </> */}
          {/* )} */}
            <li className="nav-menu-item--navigation">
              <IconButton
                className="control--navigation navigation--carta-de-productos"
                onClick={() => {
                  APIManager.navigate(dispatch, 'carta', navMenuToggle);}}
                iconName="utensils"
                iconFixedWidth={true}
                label="Carta de productos" />
            </li>
          </>)}
        <li className="nav-menu-item--navigation">
          <IconButton
              className="control--navigation navigation--historial-de-pedidos"
              onClick={() => {
                APIManager.navigate(dispatch, 'historial', navMenuToggle);}}
              iconName="list"
              iconFixedWidth={true}
              label="Historial de Pedidos" />
        </li>
        <li className="nav-menu-item--usuario">
          <button
            className="control--logout-and-login"
            onClick={() =>
              APIManager.navigate(dispatch, usuario ? 'logout' : 'login', navMenuToggle)}>
            <div className="layout--icon-container">
              <FontAwesomeIcon
                className={
                  'button__icon ' + (usuario ? 'icon--sign-out' : 'icon--sign-in')}
                icon={usuario ? ['fas', 'sign-out'] : ['fas', 'sign-in']}
              />
            </div>
            <span className="button__label">
              {usuario ? 'Logout' : 'Login'}
            </span>
          </button>
        </li>
      </ul>
      </div>
      <button className="control--nav-menu-close" onClick={navMenuToggle}>
        <FontAwesomeIcon
          className="icon--arrow-to-top button__icon"
          icon={['fas', 'arrow-to-top']}
        />
      </button>
    </div>
  );
};

export default NavMenu;
