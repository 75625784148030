import { createAction, createReducer } from '@reduxjs/toolkit';

const addNotificacion = createAction('notificaciones/add');
const addNotificaciones = createAction('notificaciones/addAll');
const dismissNotificaciones = createAction('notificaciones/dismiss');
const setNotificaciones = createAction('notificaciones/set');
const resetNotificaciones = createAction('notificaciones/reset');
const marcarComoLeida = createAction('notificaciones/read');

const initialState = {
  lista: [],
  ultimaActualizacion: null,
  hayNuevas: false
};

const notificacionesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(addNotificacion, (notificaciones, action) => {
      const notificacion = action.payload;
      notificacion.nueva = true;
      notificaciones.lista.push(notificacion);
      notificaciones.hayNuevas = true;
    })
    .addCase(addNotificaciones, (notificaciones, action) => {
      const notificacionesNuevas = action.payload;
      notificaciones.ultimaActualizacion = notificacionesNuevas.fechaDeActualizacion;
      notificaciones.hayNuevas = notificacionesNuevas.lista.length > 0;
      notificacionesNuevas.lista.forEach(notificacion => {
        notificaciones.lista.push(notificacion);
      })
    })
    .addCase(dismissNotificaciones, (notificaciones) => {
      notificaciones.hayNuevas = false;
      if (notificaciones.lista.length > 10) {
        notificaciones.lista = notificaciones.lista.slice(notificaciones.lista.length - 10, notificaciones.lista.length);
      }
      notificaciones.lista.forEach(notificacion => notificacion.nueva = false);
    })
    .addCase(setNotificaciones, (notificaciones, action) => {
      notificaciones.lista = action.payload;
    })
    .addCase(resetNotificaciones, (notificaciones) => {
      notificaciones.lista = [];
      notificaciones.ultimaActualizacion = null;
      notificaciones.hayNuevas = false;
    })
    .addCase(marcarComoLeida, (notificaciones, action) => {
      const notificacion = notificaciones.lista.find(item => item.id === action.payload.id);
      notificacion.nueva = false;
      notificacion.fecha_lectura = new Date().toISOString();
    })
})

export default notificacionesReducer;