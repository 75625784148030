import React from 'react';
import { useSelector } from 'react-redux';
import IconButton from '../IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ConfirmarPedidoCTA = ({ className, visible, productos, precio, label, onClick }) => {
const moneda = useSelector(state => state.sistema.monedaUltima);

  return (
    <div className={"confirmar-CTA " + className + (visible ? '' : ' is--hidden')}>
      <h1 className="confirmar-CTA__information">
        <div className="information__items-in-cart">
          <FontAwesomeIcon className="icon--cart" icon={["fas", "shopping-cart"]} />
          <span className="items-in-cart__quantity">{ productos }</span>
        </div>
        <div className="information__total-price">
          {/*<span className="total-price__label">Total:</span>*/}
          <span className="total-price__price">
            {(moneda ? moneda.simbolo : '') + precio }
            <span className="currency-name">{(moneda ? moneda.nombre : '')}</span>
          </span>
        </div>
      </h1>
      <IconButton
        className="control--confirmar-pedido icon-position--right" 
        onClick={onClick}
        iconName="check-circle"
        iconFixedWidth={true}
        label={label} />
    </div>
  );
}

export default ConfirmarPedidoCTA;