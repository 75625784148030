import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

const TarjetaDeProducto = ({ nombre, descripcion, precio, cantidad, imagenes, agregarProducto, estaAgrandado, setEstaAgrandado, quitarProducto, restarProducto, setMyRef }) => {
  const nombreRef = useRef(null);
  const moneda = useSelector(state => state.sistema.monedaUltima);

  const mostrarTarjetita = () => {
    setMyRef(nombreRef);
    setEstaAgrandado(!estaAgrandado);
  };

  return (
    <div ref={nombreRef} className={"tarjeta-de-producto" + (cantidad > 0 ? " is--in-cart" : "") + (estaAgrandado ? " is--expanded" : "")}>
      <div className="tarjeta-de-producto__meta">
        <div className="tarjeta-de-producto__meta--cart">
          <FontAwesomeIcon className="icon--cart" icon={["fas", "shopping-cart"]} />
          <p className="tarjeta-de-producto__meta--cart-amount">{cantidad}</p>
          {precio && <p className="tarjeta-de-producto__meta--cart-price">
            <span className="tarjeta-de-producto__meta--price-divider">x</span>
            <span className="tarjeta-de-producto__meta--price-per-unit">{(moneda ? moneda.simbolo : '') + precio}</span>
            <span className="tarjeta-de-producto__meta--total-price">
              {(moneda ? moneda.simbolo : '') + (cantidad * precio)}
              <span className="currency-name">{(moneda ? moneda.nombre : '')}</span>
            </span>
          </p>}
        </div>
        <div className="tarjeta-de-producto__cart-controls">
          <button className="control--remove-from-cart" onClick={restarProducto}>
            <div className="wrapper--icon">
              <FontAwesomeIcon className="icon--minus" icon={["fas", "minus"]} />
            </div>
          </button>
          <button className="control--add-to-cart" onClick={agregarProducto}>
            <div className="wrapper--icon">
              <FontAwesomeIcon className="icon--plus" icon={["fas", "plus"]} />
            </div>
          </button>
        </div>
      </div>
      <div className="tarjeta-de-producto__information" onClick={mostrarTarjetita}>
        <div className="tarjeta-de-producto__information--text">
          <h1 className="tarjeta-de-producto__name">{nombre}</h1>
          <p className="tarjeta-de-producto__description">{descripcion}</p>
        </div>
        <div className="information__row">
          <div className="tarjeta-de-producto__information--price">
            {precio && <p className="tarjeta-de-producto__price">
              {(moneda ? moneda.simbolo : '') + precio}
              <span className="currency-name">{(moneda ? moneda.nombre : '')}</span>
            </p>}
            {/* <FontAwesomeIcon className="el-icono-de-fede" icon={["fas", "coffee"]} /> */}
          </div>
          {!estaAgrandado && <div className="affordance--can-expand">
            <FontAwesomeIcon className="icon--expand" icon={["fas", "angle-down"]} />
          </div>}
          {estaAgrandado && <div className="affordance--can-compress">
            <FontAwesomeIcon className="icon--compress" icon={["fas", "angle-up"]} />
          </div>}
        </div>
      </div>
      <div className="tarjeta-de-producto__image" onClick={mostrarTarjetita} style={{"backgroundImage": "url(" + (imagenes && imagenes.length > 0 ? imagenes[0].url : '') + ")"}}></div>
    </div>
  );
}

export default TarjetaDeProducto