import react, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import APIManager from '../../../../lib/apiManager';
import ListaDeSeleccion from '../../components/ListaDeSeleccion';
import Formulario from './components/Formulario';

const Monedas = ({errores, setErrores, limpiarErrores}) => {

  const dispatch = useDispatch();

  const monedasSeleccionadas = useSelector(state => state.admin.itemsSeleccionados);
  const monedaSeleccionada = useSelector(state => state.admin.itemActual);

  const [monedas, setMonedas] = useState([]);

  const [monedaAEditar, setMonedaAEditar] = useState(null);

  const columnas = [
    {field: 'nombre', title: 'ISO Nombre'},
    {field: 'simbolo', title: 'Símbolo'},
  ];

  const recargarLista = (callback) => {
    APIManager.getList('monedas', {}, (response) => {
      // Si los monedas seleccionados NO estan en el listado, los elimino de la seleccion.

      const listadoFiltrado = response.data.listado.filter(
        item => monedasSeleccionadas.find(
          moneda => moneda.id === item.id
          )
        );

      if(monedaSeleccionada && !listadoFiltrado.find(item => item.id === monedaSeleccionada.id)) {
        limpiarItemActual();
      };

      dispatch({
        type: 'admin/setItemsSeleccionados',
        payload: listadoFiltrado
      });

      setMonedas(response.data.listado);
      callback && callback();
    });
  };

  useEffect(recargarLista,[]);

  useEffect(() => {
    if (monedaAEditar && monedasSeleccionadas.find(item => monedaAEditar === item)) {
      setItemActual(monedaAEditar);
    }
  },[monedaAEditar, monedasSeleccionadas]);

  const monedaChange = (moneda) => {
    if (!monedaSeleccionada) {
      if (monedasSeleccionadas && monedasSeleccionadas.find(item => item.id === moneda.id)) {
        dispatch({type: 'admin/setItemsSeleccionados', payload: []});
      } else {
        dispatch({type: 'admin/setItemsSeleccionados', payload: [moneda]});
      }
    }
  }

  const limpiarItemActual = () => {
    setItemActual(null);
    setMonedaAEditar(null);
  }

  const setItemActual = (item) => {
    dispatch({ type: 'admin/setItemActual', payload: item });
  }


  const modificarMoneda = () => {

    setItemActual(monedasSeleccionadas[0]);

  }

  const nuevaMoneda = () => {
    const itemNuevo = {
      "id": null,
      "idmonedas": null,
      "simbolo": "",
      "nombre": "",
      "descripcion": "",
      "active": null,
      "fecha_alta": null,
    };

    setMonedas([...monedas, itemNuevo]);
    dispatch({type: 'admin/setItemsSeleccionados', payload: [itemNuevo]});
    setMonedaAEditar(itemNuevo);
  }

  // const activarMonedas = () => {
  //   // TODO GRABAR
  //   APIManager.sendRequest('post', 'moneda', 'activarMonedas', {monedas: monedasSeleccionadas}, (response) => {
  //     if (response.success) {
  //       limpiarItemActual();
  //       recargarLista();
  //     }
  //   });
  // }

  // const desactivarMonedas = () => {
  //   // TODO GRABAR
  //   APIManager.sendRequest('post', 'moneda', 'desactivarMonedas', {monedas: monedasSeleccionadas}, (response) => {
  //     if (response.success) {
  //       limpiarItemActual();
  //       recargarLista();
  //     }
  //   });
  // }

  const guardarMoneda = (itemModificado, administrador) => {
    APIManager.sendRequest('post', 'moneda', itemModificado.id ? 'modificarMoneda' : 'nuevaMoneda', {moneda: itemModificado}, (response) => {
      if (response.success) {
        limpiarItemActual();
        recargarLista();
        limpiarErrores();
      } else {
        setErrores(response.errors);
        return;
      }
    });
  }

  const onFormularioClose = () => {
    limpiarErrores();
    limpiarItemActual();
    recargarLista();
  }

  return (
    <>
      <h2 className="screen-title">Lista de Monedas</h2>
      <div>
        <ListaDeSeleccion className='monedas' columnas={columnas} items={monedas} itemSeleccionado={monedaSeleccionada} itemsSeleccionados={monedasSeleccionadas} onSelectionChange={monedaChange} />
        {monedasSeleccionadas && <div className="controles">
          <button onClick={nuevaMoneda} disabled={!!monedaSeleccionada}>Agregar Moneda</button>
          <button onClick={modificarMoneda} disabled={monedaSeleccionada || !(monedasSeleccionadas.length === 1)} >Modificar Moneda</button>
          {/* <button onClick={activarMonedas} disabled={monedaSeleccionada || !monedasSeleccionadas.find(moneda => !moneda.active)}>Activar Moneda</button>
          <button onClick={desactivarMonedas} disabled={monedaSeleccionada || !monedasSeleccionadas.find(moneda => moneda.active)}>Desactivar Moneda</button> */}
        </div>}
        {monedaSeleccionada &&
        <Formulario monedaSeleccionada={monedaSeleccionada} errores={errores} setErrores={setErrores} limpiarErrores={limpiarErrores} onClose={onFormularioClose} onSaveMoneda={guardarMoneda} />
        }
      </div>
    </>
  );
}

export default Monedas;