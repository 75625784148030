import { useEffect, useRef, useState } from 'react';
import ListaDeSeleccion from '../../components/ListaDeSeleccion';
import { useSelector, useDispatch } from 'react-redux';
import APIManager from '../../../../lib/apiManager';
import IconButton from '../../../../components/IconButton';
import Palanquita from '../../../../components/Palanquita';

const Precios = ({ setTitulo, errores, setErrores }) => {

  const itemsRef = useRef();
  // const productosRef = useRef();

  const [items, setItems] = useState([]);

  const [itemsSeleccionados, setItemsSeleccionados] = useState([]);
  const [itemSeleccionado, setItemSeleccionado] = useState(null);

  const [listasDePrecios, setListasDePrecios] = useState([]);
  // const [productos, setProductos] = useState([]);

  const comercio = useSelector(state => state.admin.comercio);
  const [monedas, setMonedas] = useState([]);

  const columnasDefault = [
    { field: 'nombre', title: 'Producto' },
  ];

  const [columnas, setColumnas] = useState(columnasDefault);

  const [hayCambios, setHayCambios] = useState(false);

  const [listaDePrecios, setListaDePrecios] = useState(null);
  const [nombre, setNombre] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [moneda, setMoneda] = useState(null);

  const onItemChange = (item, event) => {
    console.log('item seleccionado:', item, ' event: ',event, ' desde: ', items);
    setItemSeleccionado(item);
  }

  const columnaPara = (lista) => {
    const columna = {
      field: 'precio'+lista.id,
      //field: 'lp'+lista.id,
      title: lista.nombre,
      editable: true,
      active: lista.active,
      placeholder: lista.moneda.nombre + ' ' + lista.moneda.simbolo,
      // onChange: (producto, value) => onChangePrecio(producto, lista, value),
    };

    columna.component = item => {
      return (
        <div className={"precio-combo" + (item['changed' + lista.id] ? ' has-changed' : '')}>
          <input type="number" className={'precio-combo__input'} placeholder={columna.placeholder} value={item['lp' + lista.id]} onChange={(event) => onChangePrecio(item, lista, event.target.value, itemsRef.current/* , productosRef.current */)} />
          <IconButton className="admin-button precio-combo__cancel" iconName="undo" onClick={event => resetPrecio(item, lista, itemsRef.current)} />
        </div>
      )
    }

    columna.headComponent = columna => {
      return (
        <>
          <div className="column-heading">
            <span className="column-title">{columna.title}</span>
            <IconButton className="control--hide" iconName="eye-slash" />
          </div>
          <div className="column-meta">
            <div className="column-meta__data">
              <span className="moneda">{columna.placeholder}</span>
            </div>
            <div className="column-meta__controles">
              <Palanquita active={columna.active} title="Activar lista" />
              {/* <button className={"control--activate" + (columna.active ? ' is--active' : '')} title="Activar lista"></button> */}
            </div>
          </div>
        </>
      )
    }

    return columna;
  }

  // TODO:
  // x- Guardar el importe original para cada producto/lista de precios
  // x- Permitir deshacer el cambio de un valor (boton para -volver / deshacer -)
  // - Permitir guardar / cancelar todos los cambios (boton para "guardar cambios" / "cancelar cambios")
  ///// Para enviar los cambios, marcar "CHANGED" como ARRAY con KEYS para cada lista.id, con true para el que cambia
  ///// Ej: producto.changed = [1: true, 3: true] (cambiaron solo los precios de las listas con IDs 1 y 3)
  ///// Entonces el (changed === true) pasa a ser: (changed.length > 0)
  // x- Marcar los valores cambiados (background color del input / td / tr )
  // x- Permitir diferenciar entre "sin precio" y "precio 0 (cero)"
  // - Permitir agregar una nueva lista de precios (nueva lista)
  // - Filtro de productos por tags (simil carta de productos)
  // - Filtro de productos por todo o parte del nombre
  // - Filtro de listas de precios (seleccion multiple)
  // - Marcar (y permitir deshacer) cambios en una lista de precios (marcando el titulo de la columna + boton deshacer)

  const onChangePrecio = (producto, lista, value, listaDeItems/* , listadoDeProductos */) => {

    if (!!lista.id && !!producto && !!listaDeItems && listaDeItems.length > 0) {

      const fieldName = 'lp' + lista.id;
      const nuevoItem = Object.assign({}, producto);

      if (isNaN(value) || (value === '')) {
        // No hay un numero, quitamos el precio
        nuevoItem.precios = nuevoItem.precios.filter(item => item.id !== lista.id);
        nuevoItem[fieldName] = '';
      } else {
        nuevoItem[fieldName] = parseFloat(value);

        let precio = nuevoItem.precios.find(precio => precio.id === lista.id);

        if (!precio) {
          precio = Object.assign({}, lista);
          nuevoItem.precios.push(precio);
        }

        precio.importe = parseFloat(value);

      }

      const precioOriginal = producto.preciosOriginales.find(item => item.id === lista.id);
      if (!producto.listasCambiadas) {
        producto.listasCambiadas = [];
        nuevoItem.listasCambiadas = [];
      }

      const precioCambiado = (precioOriginal.importe !== parseFloat(value)) && !((precioOriginal.importe === null) && (value === ''));

      if (precioCambiado) {
        if (!producto.listasCambiadas.some(item => item.id === lista.id)) {
          producto.listasCambiadas.push({ id: lista.id, changed: true });
        }
        if (!nuevoItem.listasCambiadas.some(item => item.id === lista.id)) {
          nuevoItem.listasCambiadas.push({id: lista.id, changed: true});
        }
      } else {
        producto.listasCambiadas = producto.listasCambiadas.filter(item => item.id !== lista.id);
        nuevoItem.listasCambiadas = nuevoItem.listasCambiadas.filter(item => item.id !== lista.id);
      }

      producto['changed'+lista.id] = precioCambiado;
      nuevoItem['changed'+lista.id] = precioCambiado;

      // producto.changed = Object.keys(producto).some(key => key.includes('changed') && (key !== 'changed') && producto[key]);
      producto.changed = (producto.listasCambiadas.length > 0);
      nuevoItem.changed = (nuevoItem.listasCambiadas.length > 0);

      setItems(listaDeItems.map(viejoItem => viejoItem.id === nuevoItem.id ? nuevoItem : viejoItem));
      setItemSeleccionado(nuevoItem);

      // setItemSeleccionado(producto);
    }
  }

  const resetPrecio = (item, listaDePrecios, listaDeItems) => {
    // Cambia el precio del item al precioOriginal indicado por la lista
    const precioOriginal = item.preciosOriginales.find(precio => precio.id === listaDePrecios.id);

    const value = !!precioOriginal && !!precioOriginal.importe ? precioOriginal.importe : '';

    onChangePrecio(item, listaDePrecios, value, listaDeItems);

  }

  const agregarListaDePrecios = () => {
    const nuevaLista = {
      id: null,
      moneda: monedas.length > 1 ? null : monedas[0],
      nombre: '',
      descripcion: '',
    };

    setListaDePrecios(nuevaLista);
  }

  const administrarListasDePrecios = () => {
    APIManager.current.navigate('admin/listasDePrecios', false);
  }

  const guardarListaDePrecios = () => {
    const errores = [];

    if (!nombre) {
      errores.push({message: 'El Nombre no puede quedar vacío'});
    }
    if (!moneda) {
      errores.push({ message: 'Debe seleccionar una moneda' });
    }
    const datosOk = errores.length === 0;
    if (datosOk) {
      listaDePrecios.moneda = moneda;
      listaDePrecios.nombre = nombre;
      listaDePrecios.descripcion = descripcion;
      listaDePrecios.active = false;
      listaDePrecios.comercio = comercio;
      APIManager.current.sendRequest('comercio', 'modificarListaDePrecios', { listaDePrecios }, response => {
        if (response.success) {
          setListaDePrecios(null);
          cargarListasDePrecios();
        } else {
          setErrores(response.errors);
        }
      })
    } else {
      setErrores(errores);
    }
  }

  const guardarPrecios = () => {
    APIManager.current.sendRequest('producto', 'modificarPrecios', { precios: items.filter(item => item.changed) }, response => {
      if (response.success) {
        cargarListasDePrecios();
      }
    });
  }

  const cargarMonedas = () => {
    APIManager.current.getList('monedas', {}, response => {
      setMonedas(response.data.listado);
    });
  }

  const cargarListasDePrecios = () => {
    APIManager.current.getList('listasDePrecios', { comercioID: comercio.id }, (response) => {
      setListasDePrecios(response.data.listado);
    });
  }

  const recargarLista = () => {
    if (!!listasDePrecios && listasDePrecios.length > 0) {
      APIManager.current.getList('precios', { comercioID: comercio.id, orderBy: 'id' }, (response) => {
        response.data.listado.forEach(element => {
          element.editable = true;
          element.precios = Object.values(element.precios); // Convert object to array
          element.preciosOriginales = listasDePrecios.map(lista => {
            const precio = element.precios.find(precio => precio.id === lista.id);
            return Object.assign({ importe: !!precio ? precio.importe : null }, lista);
          });
          listasDePrecios.forEach(lista => {
            const precio = element.precios.find(item => item.id === lista.id);
            element['lp' + lista.id] = precio ? precio.importe : '';
          })
        });
        setItems(response.data.listado);
      });
    }
  };

  useEffect(() => {
    // Actualiza los valores del formulario
    if (!!listaDePrecios) {
      setNombre(listaDePrecios.nombre);
      setDescripcion(listaDePrecios.descripcion);
      setMoneda(listaDePrecios.moneda);
    }
  }, [listaDePrecios]);

  useEffect(() => {
    let huboCambios = false;
    itemsRef.current = items;
    items.forEach(item => { if (!!item.changed) { huboCambios = true } });
    setHayCambios(huboCambios);
  }, [items]);

  useEffect(() => {
    setColumnas([...columnasDefault, ...listasDePrecios.map(lista => columnaPara(lista))]);
  }, [listasDePrecios]);

  useEffect(() => {
    if (columnas.length > 1) {
      recargarLista();
    }
  }, [columnas]);

  useEffect(() => {
    setTitulo('Precios',comercio);
    cargarListasDePrecios();
    cargarMonedas();
  }, []);

/*
  useEffect(() => {
    if (itemSeleccionado) {
      console.log(itemSeleccionado, itemSeleccionado.listasCambiadas);
      window.lista = itemSeleccionado.listasCambiadas;
    }
  }, [itemSeleccionado])
 */

  return (
    <>
      <div className="screen-title">
        <h2>Listas de precios</h2>
        <button onClick={administrarListasDePrecios}>Administrar listas de precios</button>
      </div>
      {/* {!listaDePrecios && <button onClick={cargarListasDePrecios}>Actualizar</button>} */}
      {hayCambios && <>
        <button onClick={guardarPrecios}>Guardar cambios</button>
        <button onClick={cargarListasDePrecios}>Cancelar cambios</button>
      </>}
      <div className={"listaDePrecios" + (!listaDePrecios ? ' hidden' : '')}>
        <h2>Nueva lista de precios</h2>
        <div className="input-combo">
          <input className={nombre ? 'has-value' : ''} type='text' maxLength={100} value={nombre} onChange={(event) => setNombre(event.target.value)}/>
          <label>Nombre</label>
        </div>
        <div className="input-combo">
          <input className={descripcion ? 'has-value' : ''} type='text' maxLength={100} value={descripcion} onChange={(event) => setDescripcion(event.target.value)}/>
          <label>Descripción</label>
        </div>
        <div className="select-combo">
          <label>Moneda</label>
          {!!monedas && (monedas.length > 0) &&
            <select value={moneda ? moneda.id : 0} onChange={(event) => setMoneda(monedas.find(item => item.id === parseInt(event.target.value)))}>
            {
              [{ id: 0, nombre: 'Seleccione una moneda' }, ...monedas].map(option => <option key={option.id} value={option.id} >{!option.id ? option.nombre : '[' + option.nombre + ' ' + option.simbolo + '] ' + option.descripcion}</option>)
            }</select>
          }
        </div>
        <button onClick={guardarListaDePrecios}>Agregar lista</button>
        <button onClick={() => {setListaDePrecios(null)}}>Cancelar</button>
      </div>
      {!listaDePrecios && ((columnas.length > 1) && (items.length > 0)
        ? <ListaDeSeleccion className='precios' columnas={columnas} items={items} itemSeleccionado={itemSeleccionado} disabledOnSeleccion={false} itemsSeleccionados={itemsSeleccionados} />
        : <h2>Cargando...</h2>)
      }
    </>
  );
}

export default Precios;