import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ConfirmarCTA = ({ productos, precio, label, onClick }) => {

  return (
    <div className="confirmar-CTA">
      <h1 className="confirmar-CTA__information">
        <div className="information__items-in-cart">
          <FontAwesomeIcon className="icon--cart" icon={["fas", "shopping-cart"]} />
          <span className="items-in-cart__quantity">{ productos }</span>
        </div>
        <div className="information__total-price">
          <span className="total-price__label">Total:</span>
          <span className="total-price__price">${ precio }</span>
        </div>
      </h1>
      <button className="control--confirmar-pedido" onClick={onClick}>
        <span className="button__label">{ label }</span>
        <FontAwesomeIcon className="button__icon icon--check" icon={["fas", "check-circle"]} />
      </button>
    </div>
  );
}

export default ConfirmarCTA;