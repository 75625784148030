import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router";
import APIManager from '../../../../lib/apiManager';

const Recepcion = ({setScanQR, setTitulo, ...props}) => {
  // Presenta seleccion de acciones (comerciante / consumidor)

  const dispatch = useDispatch();
  const history = useHistory();

  const usuario = useSelector(state => state.sistema.usuario);
  const staff = useSelector(state => state.sistema.staff);

  const [showComerciosAAdministrar, setShowComerciosAAdministrar] = useState(true);

  const setComercioAAdministrar = () => {
    if (staff.length === 1) {
      administrarComercio(staff[0].comercio);
    } else {
      setShowComerciosAAdministrar(true);
    }
  }

  const administrarComercio = (comercio) => {
/*
    APIManager.current.sendRequest('comercio', 'getComercio', { comercioID: comercio.id }, (response) => {
      dispatch({ type: 'admin/setComercio', payload: response.data });
      APIManager.navigate(dispatch, 'admin');
    });
 */
    dispatch({ type: 'admin/setComercio', payload: comercio });
    APIManager.current.getUIData('getAdminMenu', { comercio: comercio }, data => {
      dispatch({ type: 'admin/setMenu', payload: data });
    })

  }

  const misComercios = () => {
    if (!!staff && staff.length > 0) {
      return <>
        <h3>Querés administrar tu comerció?</h3>
        {!showComerciosAAdministrar && <button onClick={setComercioAAdministrar}>Administrar mi comercio</button>}
        {
          showComerciosAAdministrar &&
          <ul>
            {staff.map((item, index) =>
              <li key={index}>
                <button onClick={() => administrarComercio(item.comercio)}>{item.comercio.nombre}</button>
              </li>)}
          </ul>
        }
      </>;
    }

  }

  const seccionUsuario = (rol) => {
    let result = null;
    switch (rol) {
      case 'ROL_ADMINISTRADOR':
        result = <div>
          <h2>Administración</h2>
          <h3>Querés administrar el sistema?</h3>
          <button onClick={() => APIManager.navigate(dispatch, 'admin')}>Administrar el sistema</button>
          {misComercios()}
        </div>;
        break;
      case 'ROL_STAFF':
        result = <div>
          <h2>Administración</h2>
          {misComercios()}
        </div>;
        break;
      default:
    }
    return result;
  }

  useEffect(() => {
    setTitulo('Recepción', null);
    setComercioAAdministrar();
  }, []);

  return (
    <div className="layout--base">
      <div className="main-container">
        {/* <QRScanner active={scanQR} setActive={setScanQR} onQRScan={handleQRScan} /> */}
        {!usuario
          ? <div className="login">
            <span>Para mantener tu información entre varios dispositivos, te recomendamos registrarte</span>
            <button onClick={() => APIManager.navigate(dispatch, 'signup')}>Registrarme</button><button onClick={() => APIManager.navigate(dispatch, 'login')}>Ya tengo usuario</button>
          </div>
          : <div>{seccionUsuario(usuario.rol)}</div>
        }
      </div>
    </div>
  )
}

export default Recepcion;