import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import moment from "moment";
import APIManager from '../../../../lib/apiManager';
import IconButton from '../../../../components/IconButton';
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveBar } from "@nivo/bar";

const Dashboard = ({ setTitulo }) => {

  const history = useHistory();

  const comercio = useSelector(state => state.admin.comercio);

  const [fechaDesde, setFechaDesde] = useState(null);
  const [fechaHasta, setFechaHasta] = useState(null);
  const [reportData, setReportData] = useState({
    pedidos: [
      { estado: 'CANCELADO', cantidad: 7 },
      { estado: 'RECHAZADO', cantidad: 3 },
      { estado: 'TERMINADO', cantidad: 64 },
    ],
    tiempos: { // tiempo de atencion en segundos
      minimo: 35,
      maximo: 3225,
      promedio: 980,
      objetivo: 600,
    },
    rendimiento: {
      estados: [],
      dias: [0, 1, 8, 10, 15, 20, 20],
      horarios: [
        { hora: '20:00', cantidad: '12' },
        { hora: '21:00', cantidad: '22' },
        { hora: '22:00', cantidad: '20' },
        { hora: '23:00', cantidad: '20' },
      ],
    }
  });

  const [diaSeleccionado, setDiaSeleccionado] = useState(null);
  const [horas, setHoras] = useState([]);

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  const [mustGetData, setMustGetData] = useState(false);

  const resetFechas = () => {
    let hoy = moment(new Date());
    let comienzoDeMes = moment(new Date()).startOf('month');

    setFechaDesde(comienzoDeMes.format('YYYY-MM-DD'));
    setFechaHasta(hoy.format('YYYY-MM-DD'));
    setMustGetData(true);
  }

  const getReportData = () => {
    if (comercio) {
      APIManager.current.getUIData('getReportData', { comercioID: comercio.id, fechaDesde, fechaHasta }, (data) => {
        let horaInicial = null;
        let horaFinal = null;

        const dias = [
          { numero: 0, nombre: 'domingo', cantidad: 0, horas: [] },
          { numero: 1, nombre: 'lunes', cantidad: 0, horas: [] },
          { numero: 2, nombre: 'martes', cantidad: 0, horas: [] },
          { numero: 3, nombre: 'miercoles', cantidad: 0, horas: [] },
          { numero: 4, nombre: 'jueves', cantidad: 0, horas: [] },
          { numero: 5, nombre: 'viernes', cantidad: 0, horas: [] },
          { numero: 6, nombre: 'sabado', cantidad: 0, horas: [] },];

        data.semanal.listado.forEach(item => {
          const diaActual = dias.find(dia => dia.numero === item.dia);
          diaActual.cantidad += item.cantidad;
          diaActual.horas.push({ hora: item.hora, cantidad: item.cantidad });
          if ((horaInicial === null) || (horaInicial > item.hora)) {
            horaInicial = item.hora;
          }
          if ((horaFinal === null) || (horaFinal < item.hora)) {
            horaFinal = item.hora;
          }
        });
        setReportData({
          pedidos: data.estados.listado, tiempos: data.tiempos.listado[0], rendimiento: {
            dias: dias,
            horaInicial,
            horaFinal,
            estados: Array.from(new Set(data.semanal.listado.map(item => item.estado)))
          }
        });
      });
    }

  }

  useEffect(() => {
    if (!history.location.pathname.includes('/dashboard')) {
      history.replace('/admin/dashboard');
    }
    setTitulo('Dashboard',comercio);
    resetFechas();
  }, []);

  useEffect(() => {
    if (!!mustGetData) {
      getReportData();
      setMustGetData(false);
    }
  }, [mustGetData]);

  useEffect(() => {
    if (diaSeleccionado !== null) {
      setHoras(reportData.rendimiento.dias.find(dia => dia.numero === diaSeleccionado).horas);
    }

  }, [diaSeleccionado, reportData]);

  const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
    let total = 0
    dataWithArc.forEach(datum => {
        total += datum.value
    })

    return (
        <text
            x={centerX}
            y={centerY}
            textAnchor="middle"
            dominantBaseline="central"
        >
            {total}
        </text>
    )
  }

  return (
    <>
      <h2 className="screen-title">Información actual</h2>
      <div className="dashboard-pedidos__filtros">
        <h3>Filtros</h3>
        <div className="dashboard-pedidos__filtros-row">
          <div className="input-combo">
            <input className={fechaDesde ? 'has-value' : ''} type='date' value={fechaDesde} onChange={(event) => setFechaDesde(event.target.value)}/>
            <label>Desde</label>
          </div>
          <div className="input-combo">
            <input className={fechaHasta ? 'has-value' : ''} type='date' value={fechaHasta} onChange={(event) => setFechaHasta(event.target.value)}/>
            <label>Hasta</label>
          </div>
          <IconButton className="admin-button" onClick={getReportData} label="Actualizar" />
          {/* <button className="icon-button" onClick={getReportData}>Actualizar</button> */}
        </div>
      </div>
      <h3>Pedidos</h3>
      {!!reportData && <div className="dashboard-pedidos">
        <div className="dashboard-pedidos__pie-container">
          <div className="dashboard-pedidos__pie-chart">
            <ResponsivePie
              data={reportData.pedidos}
              id='estado'
              value='cantidad'
              margin={{ top: 10, right: 0, bottom: 10, left: -80 }}
              innerRadius={0.7}
              padAngle={0.7}
              cornerRadius={3}
              activeOuterRadiusOffset={10}
              borderWidth={1}
              borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
              //enableArcLabels={false}
              enableArcLinkLabels={false}
              // arcLinkLabelsSkipAngle={10}
              // arcLinkLabelsTextColor="#333333"
              // arcLinkLabelsThickness={2}
              // arcLinkLabelsColor={{ from: 'color' }}
              // arcLabelsSkipAngle={10}
              arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
              layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', CenteredMetric]}
            legends={[
              {
                anchor: 'right',
                direction: 'column',
                justify: false,
                translateX: 0,
                translateY: 0,
                itemsSpacing: 0,
                itemWidth: 100,
                itemHeight: 24,
                itemTextColor: '#999',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 18,
                symbolShape: 'circle',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemTextColor: '#000'
                    }
                  }
                ]
              }
            ]}
            />
          </div>
        </div>
        <div className="dashboard-pedidos__bars-container">
          <div className="dashboard-pedidos__dias-container">
          <ResponsiveBar
            data={reportData.rendimiento.dias}
            keys={['cantidad']}
            indexBy="nombre"
            margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            valueFormat={{ format: '', enabled: false }}
            colors={'var(--color--primary)'}
            borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
            axisTop={null}
            axisRight={null}
            axisLeft={null}
            gridYValues={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                // legend: 'dia',
                legendPosition: 'middle',
                legendOffset: 32
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
            // legends={[
            //     {
            //         dataFrom: 'keys',
            //         anchor: 'bottom-right',
            //         direction: 'column',
            //         justify: false,
            //         translateX: 120,
            //         translateY: 0,
            //         itemsSpacing: 2,
            //         itemWidth: 100,
            //         itemHeight: 20,
            //         itemDirection: 'left-to-right',
            //         itemOpacity: 0.85,
            //         symbolSize: 20,
            //         effects: [
            //             {
            //                 on: 'hover',
            //                 style: {
            //                     itemOpacity: 1
            //                 }
            //             }
            //         ]
            //     }
            // ]}
            />
          </div>
          <div className="dashboard-pedidos__horas-container">
          <select className="dashboard-pedidos__horas-select" value={diaSeleccionado} onChange={(event) => {setDiaSeleccionado(parseInt(event.target.value))}}>
            {reportData.rendimiento.dias.map(item => <option value={item.numero} >{ item.nombre }</option>)}
          </select>
          {!!horas && (horas.length > 0) ?
            <ResponsiveBar
              data={horas}
              keys={['cantidad']}
              indexBy="hora"
              margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
              padding={0.3}
              valueScale={{ type: 'linear' }}
              indexScale={{ type: 'band', round: true }}
              valueFormat={{ format: '', enabled: false }}
              colors={'var(--color--primary)'}
              borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              axisTop={null}
              axisRight={null}
              axisLeft={null}
              gridYValues={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'horario',
                legendPosition: 'middle',
                legendOffset: 32
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              />
            : <h2>Sin datos</h2>}
            </div>
        </div>
      </div>}
      {/* Tiempo promedio de atencion, con minimo / maximo y objetivo - grafico de agujas */}
      {/* Rendimiento por dia de la semana (cuales dias se pide mas) - grafico de barras */}
      {/* Rendimiento por horario (cual es el horario de mas movimiento) - grafico de barras */}
      {/* Cual es el producto mas pedido - ranking */}
      {/* Cual es el cliente que mas pide - ranking */}
      {/* {[...Array(100)].map((item, index) => <li key={index} className="admin-navegacion__item">{'Algo '+index}</li>)} */}
    </>

  );
}

export default Dashboard;