import { useEffect, useState } from "react";
import {
  useParams,
  useHistory
} from "react-router-dom";
import APIManager from '../../lib/apiManager';
import { useDispatch, useSelector } from 'react-redux';

const CodigoTest = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { codigo } = useParams();
  const [ubicacion, setUbicacion] = useState(null);
  const [comercio, setComercio] = useState(null);
  const ultimoComercio = useSelector(state => state.sistema.comercio);
  const monedaPreferida = useSelector(state => state.sistema.monedaPreferida);

  useEffect(() => {
    if (!!codigo) {
      APIManager.getUIData('getUbicacion', { codigo: codigo }, (data) => {
        // console.log(data);
        // Vemos si los parametros coinciden con algun comercio/ubicacion
        if (!!data && !!data.ubicacion && !!data.ubicacion.id) {
          // Si la ubicacion no esta active llevamos al comercio
          if (!data.ubicacion.active) {
            setUbicacion(null);
            dispatch({ type: 'sistema/setUbicacion', payload: null });
            if (!data.ubicacion.comercio.active) {
              // El comercio tampoco esta active, llevamos a la home
              setComercio(null);
              dispatch({ type: 'sistema/setComercio', payload: null });
              history.push('/recepcion');
            } else {
              // Seleccionamos el comercio, pero no la ubicacion
              setComercio(data.ubicacion.comercio);
              dispatch({ type: 'sistema/setComercio', payload: data.ubicacion.comercio });
              if (!!data.tags) {
                dispatch({ type: 'sistema/setFiltros', payload: data.tags });
              }
              history.push('/carta');
            }
          } else {
            setUbicacion(data.ubicacion);
            dispatch({ type: 'sistema/setUbicacion', payload: data.ubicacion });
            if (!ultimoComercio || (data.ubicacion.comercio.id !== ultimoComercio.id)) {
              // Cambiamos de comercio, reset de monedaUltima
              dispatch({ type: 'sistema/setMonedaUltima', payload: monedaPreferida });
            }
            setComercio(data.ubicacion.comercio);
            dispatch({ type: 'sistema/setComercio', payload: data.ubicacion.comercio });
            if (!!data.tags) {
              dispatch({ type: 'sistema/setFiltros', payload: data.tags });
            }
            history.push('/carta');
          }
        } else {
          // Si no coincide con nada, llevamos a la home (o cartel de error)
          setUbicacion(null);
          dispatch({ type: 'sistema/setUbicacion', payload: null });
          setComercio(null);
          dispatch({ type: 'sistema/setComercio', payload: null });
          history.push('/recepcion');
        }

      });
    }
  }, [codigo, history, dispatch, monedaPreferida, ultimoComercio]);

  return (
    <>
    <span>Codigo: {codigo}</span>
      {comercio && comercio.active ? <span>Comercio: {comercio.nombre} {ubicacion ? 'Ubicacion: ' + ubicacion.nombre : ''} </span> : <></>}
    </>
  );
}

export default CodigoTest;