import { createAction, createReducer } from '@reduxjs/toolkit';

export const filtroEnPedido = {id: 'enPedido', nombre: 'pedido', descripcion: 'productos en pedido'};

const productosReset = createAction('productos/reset');
const productosSet = createAction('productos/set');
const agregarProducto = createAction('productos/agregarProducto');
const quitarProducto = createAction('productos/quitarProducto');
const restarProducto = createAction('productos/restarProducto');

const initialState = [];

const addEnPedidoTag = (producto) => {
  if (producto.cantidad > 0) {
    producto.tags.push(filtroEnPedido);
  }
}

const removeEnPedidoTag = (producto) => {
  if (producto.cantidad === 0) {
    producto.tags = producto.tags.filter(item => item.id !== filtroEnPedido.id);
  }
}

const productosReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(productosReset, (productos, action) => {
      productos.forEach(producto => {
        producto.cantidad = 0;
        producto.estaAgrandado = false;
        removeEnPedidoTag(producto);
      });
    })
    .addCase(productosSet, (productos, action) => {
      const nuevosProductos =  action.payload.map((producto, index) => {

        // Si vino un producto que estaba en la lista, copiamos la cantidad que teniamos
        const productoPrevio = productos.find(item => item.id === producto.id);
        if (productoPrevio) {
          producto.cantidad = productoPrevio.cantidad;
          addEnPedidoTag(producto);
        } else {
          producto.cantidad = 0;
          removeEnPedidoTag(producto);
        }
        return producto;
      });

      // Si teniamos algun producto con cantidad que no vino en el listado nuevo, lo agregamos y marcamos como invalido
      const productosQuitadosConCantidad = productos.filter(item => (item.cantidad > 0) && !nuevosProductos.find(itemNuevo => ((itemNuevo.id === item.id))));


      // Estamos devolviendo los productos que vienen (listado nuevo) + productos quitados
      return [...productosQuitadosConCantidad, ...nuevosProductos];
    })
    .addCase(agregarProducto, (productos, action) => {
      productos.map((producto) => {
        if (producto.id === action.payload.id) {
          producto.cantidad += 1;
          addEnPedidoTag(producto);
        }
        return producto;
      })
    })
    .addCase(restarProducto, (productos, action) => {
      productos.map((producto) => {
        if (producto.id === action.payload.id) {
          if (producto.cantidad > 0) {
            producto.cantidad -= 1;
          }
          removeEnPedidoTag(producto);
        }
        return producto;
      })
    })
    .addCase(quitarProducto, (productos, action) => {
      productos.map((producto) => {
        if (producto.id === action.payload.id) {
          producto.cantidad = 0;
          removeEnPedidoTag(producto);
        }
        return producto;
      })
    })
})

export default productosReducer;