import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const IconButton = ({className, iconClassName, iconLibName = "fas", iconFixedWidth, iconName, label, labelClassName, disabled, onClick, visible = true}) => {
  return (
      <button disabled={disabled} className={"icon-button" + (className ? " " + className : "") + (!label ? " no-label" : "") + (!visible ? " is--invisible" : "")} onClick={onClick} >
        {!!iconName && <div className="layout--icon-container">
          <FontAwesomeIcon
            className={"button__icon icon--" + iconName
              + (iconClassName ? " " + iconClassName : "")
              + (iconFixedWidth ? " fa-fw" : "")}
            icon={[iconLibName, iconName]}
          />
        </div>}
        {!!label &&
          <span className={"button__label" + (labelClassName ? " " + labelClassName : "")}>{label}</span>}
      </button>
    );
}

export default IconButton;
