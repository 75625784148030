import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EstadoDePedido = ({ estadosDePedido, estadoActual }) => {
  let ultimoOrden = 0;
  let agregar = true;

  return (
    <div className="pedido__estados" style={{"--color-del-estado": estadoActual.color}}>
      <div className="mapa-de-estados">
        {estadosDePedido.map(estado => {
          if (estado.orden > ultimoOrden) {
            ultimoOrden = estado.orden;
            agregar = true;
          } else {
            agregar = false;
          }
          return agregar ? (
          <span className={"marcador-de-estado"
          + (estado.orden < estadoActual.orden ? " is--usado" : "")
          + (estado.orden === estadoActual.orden ? " is--actual" : "")
        }></span>
        ) : null})}
      </div>
      <span className="pedido__estado">
        <FontAwesomeIcon className="icon--angle-double-right" icon={["fas", "angle-double-right"]} />
        <span>{estadoActual.nombre}</span>
      </span>
    </div>
  )
}

export default EstadoDePedido;