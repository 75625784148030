import react, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import APIManager from '../../../../lib/apiManager';
import ListaDeSeleccion from '../../components/ListaDeSeleccion';
import Formulario from './components/Formulario';

const Tags = ({errores, setErrores, limpiarErrores}) => {

  const dispatch = useDispatch();

  const tagsSeleccionadas = useSelector(state => state.admin.itemsSeleccionados);
  const tagSeleccionada = useSelector(state => state.admin.itemActual);

  const [tags, setTags] = useState([]);

  const [tagAEditar, setTagAEditar] = useState(null);

  const columnas = [
    {field: 'nombre', title: 'Etiqueta'},
    {field: 'descripcion', title: 'Descripcion'},
  ];

  const recargarLista = (callback) => {
    APIManager.getList('tags', {}, (response) => {
      // Si los tags seleccionados NO estan en el listado, los elimino de la seleccion.

      const listadoFiltrado = response.data.listado.filter(
        item => tagsSeleccionadas.find(
          tag => tag.id === item.id
          )
        );

      if(tagSeleccionada && !listadoFiltrado.find(item => item.id === tagSeleccionada.id)) {
        limpiarItemActual();
      };

      dispatch({
        type: 'admin/setItemsSeleccionados',
        payload: listadoFiltrado
      });

      setTags(response.data.listado);
      callback && callback();
    });
  };

  useEffect(recargarLista,[]);

  useEffect(() => {
    if (tagAEditar && tagsSeleccionadas.find(item => tagAEditar === item)) {
      setItemActual(tagAEditar);
    }
  },[tagAEditar, tagsSeleccionadas]);

  const tagChange = (tag) => {
    if (!tagSeleccionada) {
      if (tagsSeleccionadas && tagsSeleccionadas.find(item => item.id === tag.id)) {
        dispatch({type: 'admin/setItemsSeleccionados', payload: []});
      } else {
        dispatch({type: 'admin/setItemsSeleccionados', payload: [tag]});
      }
    }
  }

  const limpiarItemActual = () => {
    setItemActual(null);
    setTagAEditar(null);
  }

  const setItemActual = (item) => {
    dispatch({ type: 'admin/setItemActual', payload: item });
  }


  const modificarTag = () => {

    setItemActual(tagsSeleccionadas[0]);

  }

  const nuevaTag = () => {
    const itemNuevo = {
      "id": null,
      "idtags": null,
      "simbolo": "",
      "nombre": "",
      "descripcion": "",
      "active": null,
      "fecha_alta": null,
    };

    setTags([...tags, itemNuevo]);
    dispatch({type: 'admin/setItemsSeleccionados', payload: [itemNuevo]});
    setTagAEditar(itemNuevo);
  }

  // const activarTags = () => {
  //   // TODO GRABAR
  //   APIManager.sendRequest('post', 'tag', 'activarTags', {tags: tagsSeleccionadas}, (response) => {
  //     if (response.success) {
  //       limpiarItemActual();
  //       recargarLista();
  //     }
  //   });
  // }

  // const desactivarTags = () => {
  //   // TODO GRABAR
  //   APIManager.sendRequest('post', 'tag', 'desactivarTags', {tags: tagsSeleccionadas}, (response) => {
  //     if (response.success) {
  //       limpiarItemActual();
  //       recargarLista();
  //     }
  //   });
  // }

  const guardarTag = (itemModificado, administrador) => {
    APIManager.sendRequest('post', 'tag', itemModificado.id ? 'modificarTag' : 'nuevaTag', {tag: itemModificado}, (response) => {
      if (response.success) {
        limpiarItemActual();
        recargarLista();
        limpiarErrores();
      } else {
        setErrores(response.errors);
        return;
      }
    });
  }

  const onFormularioClose = () => {
    limpiarErrores();
    limpiarItemActual();
    recargarLista();
  }

  return (
    <>
      <h2 className="screen-title">Lista de Estiquetas</h2>
      <div>
        <ListaDeSeleccion className='tags' columnas={columnas} items={tags} itemSeleccionado={tagSeleccionada} itemsSeleccionados={tagsSeleccionadas} onSelectionChange={tagChange} />
        {tagsSeleccionadas && <div className="controles">
          <button onClick={nuevaTag} disabled={!!tagSeleccionada}>Agregar Etiqueta</button>
          <button onClick={modificarTag} disabled={tagSeleccionada || !(tagsSeleccionadas.length === 1)} >Modificar Etiqueta</button>
          {/* <button onClick={activarTags} disabled={tagSeleccionada || !tagsSeleccionadas.find(tag => !tag.active)}>Activar Tag</button>
          <button onClick={desactivarTags} disabled={tagSeleccionada || !tagsSeleccionadas.find(tag => tag.active)}>Desactivar Tag</button> */}
        </div>}
        {tagSeleccionada &&
        <Formulario tagSeleccionada={tagSeleccionada} errores={errores} setErrores={setErrores} limpiarErrores={limpiarErrores} onClose={onFormularioClose} onSaveTag={guardarTag} />
        }
      </div>
    </>
  );
}

export default Tags;