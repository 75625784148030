import { useEffect, useState } from 'react';
import ListaDeSeleccion from '../../components/ListaDeSeleccion';
import { useSelector, useDispatch } from 'react-redux';
import APIManager from '../../../../lib/apiManager';
import Formulario from './components/Formulario';

const Productos = ({ setTitulo }) => {

  const dispatch = useDispatch();

  const [items, setItems] = useState([]);

  const [itemsSeleccionados, setItemsSeleccionados] = useState([]);
  const [itemSeleccionado, setItemSeleccionado] = useState(null);

  const [errores, setErrores] = useState([]);

  const comercio = useSelector(state => state.admin.comercio);

  const onItemChange = (item) => {
    if (!itemSeleccionado || (itemSeleccionado.id !== item.id)) {
      setItemSeleccionado(item);
      setItemsSeleccionados([item]);
    } else {
      setItemSeleccionado(null);
      setItemsSeleccionados([]);
    }

  }

  const guardarItem = (item) => {
    // APIManager.current.uploadImages(item.imagenes, (response) => {
    //   if (response.success) {
    //     const productoAGuardar = Object.assign({}, item);
    //     productoAGuardar.imagenes[0].imagen = response.data.imagenes[0].imagen;
        APIManager.current.sendRequest('producto', 'modificarProducto', { producto: item }, (response) => {
          if (response.success) {
            onFormularioClose();
            recargarLista();
          }
        });
      // }
    // });
  }

  const limpiarErrores = () => {
    setErrores([]);
  }

  const onFormularioClose = () => {
    if (!itemSeleccionado.id) {
      setItems(items.filter(item => !!item.id));
    }
    setItemSeleccionado(null);
    setItemsSeleccionados([]);
  }

  const columnas = [
    { field: 'nombre', title: 'Producto' },
    { field: 'descripcion', title: 'Descripción' },
    { field: 'active', title: 'Activo' },
  ];

  const recargarLista = (callback) => {
    APIManager.current.getList('productos', { comercioID: comercio.id, bajas: false, orderBy: 'id' }, (response) => {
      response.data.listado.forEach(element => {
        element.tags = Object.values(element.tags);
        element.imagenes = Object.values(element.imagenes);
      });
      setItems(response.data.listado);
    });
  };

  const nuevoProducto = () => {
    const itemNuevo = {
      id: null,
      comercioID: comercio.id,
      nombre: '',
      codigo: '',
      descripcion: '',
      tags: [],
      active: false,
      disponible: false,
    };

    setItems([...items, itemNuevo]);
    setItemSeleccionado(itemNuevo);
    setItemsSeleccionados([itemNuevo]);
  }

  const bajaItem = () => {
    if (window.confirm('Desea eliminar el producto: "' + itemSeleccionado.nombre + '" ?'))
    {
      APIManager.current.sendRequest('producto', 'bajaDeProducto', { producto: itemSeleccionado }, (response) => {
        if (response.success) {
          onFormularioClose();
          recargarLista();
        }
      });

    }
  }

  useEffect(() => {
    setTitulo('Productos',comercio);
    recargarLista();
  }, []);

  return (
    <>
      <h2 className="screen-title">Oferta de productos</h2>
      <button onClick={recargarLista} disabled={!!itemSeleccionado}>Actualizar</button>
      <button onClick={nuevoProducto} disabled={!!itemSeleccionado}>Nuevo producto</button>
      <ListaDeSeleccion className='items' columnas={columnas} items={items} itemSeleccionado={itemSeleccionado} itemsSeleccionados={itemsSeleccionados} onSelectionChange={onItemChange} />
      {/* Formulario con seleccion de tags para agregar (textbox con sugerencia de "parecidos" con opcion de agregar nuevo tag) */}
      {/* Los Tags agregados quedan como los "filtros activos", haciendo click en un tag se "quita" del producto */}

      {!!itemSeleccionado &&
        <Formulario productoSeleccionado={itemSeleccionado} errores={errores} setErrores={setErrores} limpiarErrores={limpiarErrores} onClose={onFormularioClose} onSaveProducto={guardarItem} bajaItem={bajaItem} />
      }

    </>
  );
}

export default Productos;