import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';

import { store, persistor } from "./redux/store";

import App from './App';

import 'normalize.css';
import './scss/styles.scss';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import * as serviceWorker from './serviceWorker';
import swConfig from './swConfig';

//import reportWebVitals from './reportWebVitals';


const rootElement = document.getElementById("root");

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
  , rootElement
);
// serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register(swConfig);
// serviceWorker.register(swConfig);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
