import react, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import APIManager from '../../../../lib/apiManager';
import ListaDeSeleccion from '../../components/ListaDeSeleccion';
import Formulario from './components/Formulario';

const EstadosDePedido = ({errores, setErrores, limpiarErrores}) => {

  const dispatch = useDispatch();

  const estadosDePedidoSeleccionados = useSelector(state => state.admin.itemsSeleccionados);
  const estadoDePedidoSeleccionado = useSelector(state => state.admin.itemActual);

  const [estadosDePedido, setEstadosDePedido] = useState([]);

  const [estadoDePedidoAEditar, setEstadoDePedidoAEditar] = useState(null);

  const columnas = [
    {field: 'nombre', title: 'Estado De Pedido'},
    {field: 'descripcion', title: 'Descripcion'},
    {field: 'orden', title: 'Orden'},
  ];

  const recargarLista = (callback) => {
    APIManager.getList('estadosDePedido', {}, (response) => {
      // Si los estadosDePedido seleccionados NO estan en el listado, los elimino de la seleccion.

      const listadoFiltrado = response.data.listado.filter(
        item => estadosDePedidoSeleccionados.find(
          estadoDePedido => estadoDePedido.id === item.id
          )
        );

      if(estadoDePedidoSeleccionado && !listadoFiltrado.find(item => item.id === estadoDePedidoSeleccionado.id)) {
        limpiarItemActual();
      };

      dispatch({
        type: 'admin/setItemsSeleccionados',
        payload: listadoFiltrado
      });

      setEstadosDePedido(response.data.listado.sort((a, b) => a.orden - b.orden));
      callback && callback();
    });
  };

  useEffect(recargarLista,[]);

  useEffect(() => {
    if (estadoDePedidoAEditar && estadosDePedidoSeleccionados.find(item => estadoDePedidoAEditar === item)) {
      setItemActual(estadoDePedidoAEditar);
    }
  },[estadoDePedidoAEditar, estadosDePedidoSeleccionados]);

  const estadoDePedidoChange = (estadoDePedido) => {
    if (!estadoDePedidoSeleccionado) {
      if (estadosDePedidoSeleccionados && estadosDePedidoSeleccionados.find(item => item.id === estadoDePedido.id)) {
        dispatch({type: 'admin/setItemsSeleccionados', payload: []});
      } else {
        dispatch({type: 'admin/setItemsSeleccionados', payload: [estadoDePedido]});
      }
    }
  }

  const limpiarItemActual = () => {
    setItemActual(null);
    setEstadoDePedidoAEditar(null);
  }

  const setItemActual = (item) => {
    dispatch({ type: 'admin/setItemActual', payload: item });
  }


  const modificarEstadoDePedido = () => {

    setItemActual(estadosDePedidoSeleccionados[0]);

  }

  const nuevaEstadoDePedido = () => {
    const itemNuevo = {
      "id": null,
      "idestadosDePedido": null,
      "nombre": "",
      "descripcion": "",
      "orden": "",
    };

    setEstadosDePedido([...estadosDePedido, itemNuevo]);
    dispatch({type: 'admin/setItemsSeleccionados', payload: [itemNuevo]});
    setEstadoDePedidoAEditar(itemNuevo);
  }

  const activarEstadosDePedido = () => {
    // TODO GRABAR
    APIManager.sendRequest('post', 'estadoDePedido', 'activarEstadosDePedido', {estadosDePedido: estadosDePedidoSeleccionados}, (response) => {
      if (response.success) {
        limpiarItemActual();
        recargarLista();
      }
    });
  }

  const desactivarEstadosDePedido = () => {
    // TODO GRABAR
    APIManager.sendRequest('post', 'estadoDePedido', 'desactivarEstadosDePedido', {estadosDePedido: estadosDePedidoSeleccionados}, (response) => {
      if (response.success) {
        limpiarItemActual();
        recargarLista();
      }
    });
  }

  const guardarEstadoDePedido = (itemModificado, administrador) => {
    APIManager.sendRequest('post', 'estadoDePedido', itemModificado.id ? 'modificarEstadoDePedido' : 'nuevaEstadoDePedido', {estadoDePedido: itemModificado}, (response) => {
      if (response.success) {
        limpiarItemActual();
        recargarLista();
        limpiarErrores();
      } else {
        setErrores(response.errors);
        return;
      }
    });
  }

  const onFormularioClose = () => {
    limpiarErrores();
    limpiarItemActual();
    recargarLista();
  }

  return (
    <>
      <h2 className="screen-title">Lista de Estado De Pedido</h2>
      <div>
        <ListaDeSeleccion className='estadosDePedido' columnas={columnas} items={estadosDePedido} itemSeleccionado={estadoDePedidoSeleccionado} itemsSeleccionados={estadosDePedidoSeleccionados} onSelectionChange={estadoDePedidoChange} />
        {estadosDePedidoSeleccionados && <div className="controles">
          <button onClick={nuevaEstadoDePedido} disabled={!!estadoDePedidoSeleccionado}>Agregar Estado De Pedido</button>
          <button onClick={modificarEstadoDePedido} disabled={estadoDePedidoSeleccionado || !(estadosDePedidoSeleccionados.length === 1)} >Modificar Estado De Pedido</button>
          {/* <button onClick={activarEstadosDePedido} disabled={estadoDePedidoSeleccionado || !estadosDePedidoSeleccionados.find(estadoDePedido => !estadoDePedido.active)}>Activar Estado De Pedido</button>
          <button onClick={desactivarEstadosDePedido} disabled={estadoDePedidoSeleccionado || !estadosDePedidoSeleccionados.find(estadoDePedido => estadoDePedido.active)}>Desactivar Estado De Pedido</button> */}
        </div>}
        {estadoDePedidoSeleccionado &&
        <Formulario estadoDePedidoSeleccionado={estadoDePedidoSeleccionado} errores={errores} setErrores={setErrores} limpiarErrores={limpiarErrores} onClose={onFormularioClose} onSaveEstadoDePedido={guardarEstadoDePedido} />
        }
      </div>
    </>
  );
}

export default EstadosDePedido;