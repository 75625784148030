import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import IconButton from "../../../../components/IconButton";
import TarjetaDeComercio from "../../../../components/TarjetaDeComercio";
import APIManager from '../../../../lib/apiManager';


const Comercio = ({ setTitulo }) => {

  const comercio = useSelector(state => state.admin.comercio);
  const comercioDelSistema = useSelector(state => state.sistema.comercio);

  const history = useHistory();
  const dispatch = useDispatch();

  const [nombre, setNombre] = useState('');
  const [codigo, setCodigo] = useState('');
  const [direccion, setDireccion] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [imagen, setImagen] = useState(null);

  const [imagenCambiada, setImagenCambiada] = useState(false);

  const noImageURL = useSelector(state => state.sistema.noImageURL);


  useEffect(() => {

    if (!!comercio) {
      setTitulo('Comercio', comercio);
      setNombre(comercio.nombre ? comercio.nombre : '');
      setCodigo(comercio.codigo ? comercio.codigo : '');
      setDireccion(comercio.direccion ? comercio.direccion : '');
      setDescripcion(comercio.descripcion ? comercio.descripcion : '');
      setImagen({ url: comercio.logo });
    }

  }, [comercio]);

  const cambiarImagen = async (event) => {
    setImagenCambiada(true);
    const imageFiles = event.target.files;
    if (imageFiles.length > 0) {
      const base64file = await toBase64(imageFiles[0]);
      setImagen({file: base64file, imagen: imageFiles[0].name, tipo: 1, orden: 1, url: URL.createObjectURL(imageFiles[0]) });
    } else {
      setImagen(null);
    }

  }

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const guardarComercio = (itemModificado) => {
    APIManager.sendRequest('post', 'comercio', itemModificado.id ? 'modificarComercio' : 'nuevoComercio', {comercio: itemModificado}, (response) => {
      if (response.success) {
        dispatch({ type: 'admin/setComercio', payload: response.data });
      }
    });
  }

  const guardarFormulario = (event) => {

    const erroresDelFormulario = [];

    if (!nombre) {
      console.log('nombre:', nombre);
      erroresDelFormulario.push({message: 'El nombre del comercio no puede estar vacio'});
    }

    if (!codigo) {
      console.log('codigo', codigo);
      erroresDelFormulario.push({message: 'El codigo del comercio no puede estar vacio'});
    }

    if (codigo.indexOf(' ') !== -1) {
      console.log('codigo', codigo);
      erroresDelFormulario.push({message: 'El codigo del comercio no puede contener espacios'});
    }

    if ((erroresDelFormulario.length === 0)) {
      const itemModificado = {};

      Object.assign(itemModificado, comercio);

      itemModificado.nombre = nombre;
      itemModificado.codigo = codigo;
      itemModificado.direccion = direccion;
      itemModificado.descripcion = descripcion;
      itemModificado.imagen = imagen;
      delete (itemModificado.staff);

      guardarComercio(itemModificado);

    }
  }

  return (
    <div className="formulario-comercio">
      <div className="screen-title">
        <h2>Datos del Comercio</h2>
        <div className="screen-title__controls">
          <IconButton className="admin-button" onClick={guardarFormulario} label="Guardar cambios"/>
        </div>
      </div>
      <div className="formulario">
        <div className="form__row">
          <div className="input-combo comercio__nombre">
            <input className={nombre ? 'has-value' : ''} type='text' maxLength={100} value={nombre} onChange={(event) => setNombre(event.target.value)}/>
            <label>Nombre</label>
          </div>
          <div className="input-combo comercio__codigo">
            <input className={codigo ? 'has-value' : ''} type='text' value={codigo} onChange={(event) => setCodigo(event.target.value)}/>
            <label>Código</label>
          </div>
        </div>
        <div className="form__row">
          <div className="input-combo comercio__direccion">
            <input className={direccion ? 'has-value' : ''} type='text' value={direccion} onChange={(event) => setDireccion(event.target.value)}/>
            <label>Dirección</label>
          </div>
        </div>
        <div className="form__row">
          <div className="input-combo comercio__descripcion">
            <textarea className={descripcion ? 'has-value' : ''} type='text' value={descripcion} onChange={(event) => setDescripcion(event.target.value)}/>
            <label>Descripción</label>
          </div>
        </div>
        <div className="form__row">
          <div className="comercio--imagen">
            <label>Logo</label>
            <div className="imagen-combo">
              <div className="comercio--img" style={{ backgroundImage: "url(" + (!!imagen ? imagen.url : noImageURL) + ")" }} alt="Logo"></div>
              <input type="file" onChange={cambiarImagen} />
            </div>
          </div>
          <TarjetaDeComercio nombre={nombre} codigo={codigo} descripcion={descripcion} direccion={direccion} logo={!!imagen ? imagen.url : noImageURL} />
        </div>
      </div>
    </div>
  )

}

export default Comercio;