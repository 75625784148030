import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ConfirmarCTA from '../../components/ConfirmarCTA';
import APIManager from '../../lib/apiManager';
import { useHistory } from 'react-router';
import ListadoDeProductos from '../../components/ListadoDeProductos';

const Test = () => {
  const productos = useSelector(state => state.productos);
  const cliente = useSelector(state => state.sistema.cliente);
  const ubicacion = useSelector(state => state.sistema.ubicacion);
  const listasDePrecios = useSelector(state => state.sistema.listasDePrecios);

  const [listaDePrecios, setListaDePrecios] = useState(null);
  const [itemsEnPedido, setItemsEnPedido] = useState(0);
  const [precioDelPedido, setPrecioDelPedido] = useState(0);
  const moneda = useSelector(state => state.sistema.monedaUltima);

  const dispatch = useDispatch();

  const enviarPedido = () => {
    if (ubicacion) {
      if ((itemsEnPedido > 0)) {
        const pedido = {
          estado: 'nuevo',
          cliente: cliente,
          ubicacion: ubicacion,
          productos: productos.filter(item => item.cantidad > 0),
          precio: precioDelPedido
        };
        APIManager.sendRequest('post', 'pedidos', 'nuevoPedido', { pedido }, ( response ) => {
          if (response.success) {
            dispatch({ type: 'productos/reset' });
          }
        })
      } else {
        alert('seleccionar productos');
      }
    } else {
      alert('seleccionar ubicacion');
    }
  }

  useEffect(() => {
    let cantidad = 0;
    let precio = 0;
    productos.forEach(item => {
      cantidad += item.cantidad;
      precio += item.precio * item.cantidad;
    });
    setItemsEnPedido(cantidad);
    setPrecioDelPedido(precio);
  }, [productos]);

  useEffect(() => {
    if (!!moneda && listasDePrecios && listasDePrecios.length > 0) {
      const lista = listasDePrecios.find(item => item.idmonedas === moneda.idmonedas);
      setListaDePrecios(lista);
      dispatch({ type: 'sistema/setMonedaUltima', payload: moneda });
    }
  }, [moneda, listasDePrecios, dispatch]);

  return (
    <div className="layout--base">
      <ConfirmarCTA leyenda={"Productos: " + itemsEnPedido + " precio: " + precioDelPedido} label="Confirmar pedido" onClick={enviarPedido} />
      <div className="main-container">
        <section className="layout--menu">
          {listaDePrecios && <ListadoDeProductos listaDePrecios={ listaDePrecios }/>}
        </section>
      </div>
    </div>
  );
}

export default Test;