import React, { useState } from 'react';
import Moment from 'react-moment';
import 'moment/locale/es';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EstadoDePedido from '../EstadoDePedido';

const ListadoDePedidos = ({ pedidos }) => {
  const estadosDePedido = useSelector(state => state.sistema.estadosDePedido);

  moment.updateLocale('es', {
    weekdays : ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']
  });
  moment.locale('es');

  const formatoDeFecha = (fecha) => {
    const hoy = moment(new Date());
    const enElAnio = hoy.year() === moment(fecha).year();
    const enLaSemana = enElAnio && (hoy.week() === moment(fecha).week());
    const enElDia = enLaSemana && (hoy.weekday() === moment(fecha).weekday());

    return (enElDia && "[Hoy] HH:mm")
    || (enLaSemana && "dddd")
    || (enElAnio && "DD/MM")
    || ("DD/MM/YYYY")
  }

  return (
    <ul className="listado-de-pedidos">
      {pedidos.map((pedido) => {
        let estadoActual = null;
        if (!!estadosDePedido && estadosDePedido.length > 0) {
          estadoActual = estadosDePedido.find(item => item.id === pedido.estado);
        }

        return (
        <li className={"listado-de-pedidos__item"} key={pedido.id} >
          <button className="control--pedido" >
            <div className="listado-de-pedidos__pedido">

              <div className="pedido__wrapper--information">
                <div className="layout--wrapper">
                  <span className="pedido__codigo">{pedido.codigo}</span>
                  <Moment className="pedido__fecha" date={pedido.fecha} format={formatoDeFecha(pedido.fecha)} />
                </div>

                {/* <span className="pedido__estado">
                  <FontAwesomeIcon className="icon--angle-double-right" icon={["fas", "angle-double-right"]} />
                  <span>{estadosDePedido && estadosDePedido.length > 0 && (estadosDePedido.find(item => item.id === pedido.estado) ? estadosDePedido.find(item => item.id === pedido.estado).nombre : pedido.estado)}</span>
                </span> */}

                {(!!estadosDePedido && estadosDePedido.length > 0) &&
                <EstadoDePedido estadosDePedido={estadosDePedido} estadoActual={estadoActual} />}

{/*
                <div className="pedido__estados">
                  <div className="mapa-de-estados">
                    <span className="marcador-de-estado is--usado"></span>
                    <span className="marcador-de-estado is--usado"></span>
                    <span className="marcador-de-estado is--actual"></span>
                    <span className="marcador-de-estado"></span>
                  </div>

                  <span className="pedido__estado">
                    <FontAwesomeIcon className="icon--angle-double-right" icon={["fas", "angle-double-right"]} />
                    <span>{estadosDePedido && estadosDePedido.length > 0 && (estadosDePedido.find(item => item.id === pedido.estado) ? estadosDePedido.find(item => item.id === pedido.estado).nombre : pedido.estado)}</span>
                  </span>
                </div>
 */}
              </div>

              <ol className="pedido__lista-de-productos">
                {pedido.productos.map(item => <li className="pedido__producto" key={item.producto.id}>
                  <span>{item.cantidad + ' x '}</span><span> {item.producto.nombre}</span>
                </li>
                )}
              </ol>
            </div>
          </button>
        </li>
      )})}
    </ul>
  );
}

export default ListadoDePedidos