import { createAction, createReducer } from '@reduxjs/toolkit';

const setCliente = createAction('sistema/setCliente');
const setComercio = createAction('sistema/setComercio');
const setMiembro = createAction('sistema/setMiembro');
const setUbicacion = createAction('sistema/setUbicacion');
const setListasDePrecios = createAction('sistema/setListasDePrecios');
const setMonedas = createAction('sistema/setMonedas');
const setUsuario = createAction('sistema/setUsuario');
const setMonedaPreferida = createAction('sistema/setMonedaPreferida');
const setMonedaUltima = createAction('sistema/setMonedaUltima');
const setStaff = createAction('sistema/setStaff');
const setEstadosDePedido = createAction('sistema/setEstadosDePedido');
const setNoImageURL = createAction('sistema/setNoImageURL');
const setUbicaciones = createAction('sistema/setUbicaciones');
const setFiltros = createAction('sistema/setFiltros');

const setPaginaActual = createAction('sistema/setPaginaActual');

const initialState = {
  paginaActual: null,
  usuario: null,
  cliente: null,
  comercio: null,
  staff: [],
  monedas: [],
  monedaPreferida: null,
  monedaUltima: null,
  listaDePrecios: null,
  estadosDePedido: [],
  ubicacion: null,
  noImageURL: null,
  filtros: [],
  miembro: null,
};

const productosReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setCliente, (state, action) => {
      state.cliente = action.payload;
    })
    .addCase(setUsuario, (state, action) => {
      state.usuario = action.payload;
    })
    .addCase(setComercio, (state, action) => {
      state.comercio = action.payload;
    })
    .addCase(setUbicacion, (state, action) => {
      state.ubicacion = action.payload;
    })
    .addCase(setListasDePrecios, (state, action) => {
      state.listasDePrecios = action.payload;
    })
    .addCase(setMonedas, (state, action) => {
      state.monedas = action.payload;
    })
    .addCase(setMonedaPreferida, (state, action) => {
      state.monedaPreferida = action.payload;
    })
    .addCase(setMonedaUltima, (state, action) => {
      state.monedaUltima = action.payload;
    })
    .addCase(setPaginaActual, (state, action) => {
      state.paginaActual = action.payload;
    })
    .addCase(setStaff, (state, action) => {
      state.staff = action.payload;
    })
    .addCase(setEstadosDePedido, (state, action) => {
      state.estadosDePedido = action.payload;
    })
    .addCase(setNoImageURL, (state, action) => {
      state.noImageURL = action.payload;
    })
    .addCase(setUbicaciones, (state, action) => {
      state.comercio.ubicaciones = action.payload;
    })
    .addCase(setFiltros, (state, action) => {
      state.filtros = action.payload;
    })
    .addCase(setMiembro, (state, action) => {
      state.miembro = action.payload;
    })
})

export default productosReducer;