import { Route, Switch } from "react-router-dom";
import MainBar from "../../components/MainBar"
import Test from "../Test";
import TestFede from "../TestFede";
import CartaDeProductos from '../CartaDeProductos';
import Admin from '../Admin';
import { useState } from "react";
import NavMenu from "../NavMenu";
import Historial from '../Historial';
import Recepcion from "../Recepcion";
import Codigos from "../Codigos";
import QuieroPedir from "../QuieroPedir";
import Login from '../Login';
import Logout from '../Logout';

const MainLayoutBar = ({...props}) => {

  const [menuVisible, setMenuVisible] = useState(false);

  const navMenuToggle = () => {
    setMenuVisible(!menuVisible);
  }

  return (
    <>
      <MainBar className={props.menuClassName} navMenuToggle={navMenuToggle} {...props} />
      <NavMenu navMenuToggle={navMenuToggle} visible={menuVisible} setScanQR={props.setScanQR} />
      <Switch>
        <Route path="/login" render={(myProps) => <Login {...myProps} />} />
        <Route path="/logout" render={(myProps) => <Logout {...myProps} />} />
        <Route path="/test" render={(myProps) => <Test {...myProps} {...props} />} />
        <Route path="/fede" render={(myProps) => <TestFede {...myProps} {...props} />} />
        <Route path="/admin" render={(myProps) => <Admin {...myProps} {...props} />} />
        <Route path="/historial" render={(myProps) => <Historial {...myProps} {...props} />} />
        <Route path="/carta" render={(myProps) => <CartaDeProductos {...myProps} {...props} />} />
        <Route path="/codigos" render={(myProps) => <Codigos {...myProps} {...props} />} />
        <Route path="/recepcion" render={(myProps) => <Recepcion {...myProps} {...props} />} />
        <Route path="/pedir" render={(myProps) => <QuieroPedir {...myProps} {...props} />} />
      </Switch>
    </>
  );
}

export default MainLayoutBar;