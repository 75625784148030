import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import IconButton from '../../../../../../components/IconButton';
import APIManager from '../../../../../../lib/apiManager';
import ListaDeSeleccion from '../../../../components/ListaDeSeleccion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Formulario = ({ agrupacionSeleccionada, onClose, onSaveItem }) => {

  const comercio = useSelector(state => state.admin.comercio);
  const [otrosMiembros, setOtrosMiembros] = useState([]);
  const [nombre, setNombre] = useState(agrupacionSeleccionada.nombre);
  const [descripcion, setDescripcion] = useState(agrupacionSeleccionada.descripcion);

  const [tags, setTags] = useState([]);
  const [tagsSeleccionados, setTagsSeleccionados] = useState([]);
  const [etiqueta, setEtiqueta] = useState('');
  const [etiquetasSugeridas, setEtiquetasSugeridas] = useState([]);
  const [miembros, setMiembros] = useState([]);

  const agregarEtiqueta = (item) => {
    APIManager.current.sendRequest('miembro', 'agregarEtiqueta', { comercioID: comercio.id, agrupacionID: agrupacionSeleccionada.id, tagID: item.id }, (response) => {
      if (response.success) {
        setTagsSeleccionados([...tagsSeleccionados, item]);
      }
    });
  }

  const quitarEtiqueta = (item) => {
    APIManager.current.sendRequest('miembro', 'quitarEtiqueta', { comercioID: comercio.id, agrupacionID: agrupacionSeleccionada.id, tagID: item.id }, (response) => {
      if (response.success) {
        setTagsSeleccionados(tagsSeleccionados.filter(tag => tag.id !== item.id));
      }
    });
  }

  useEffect(() => {
    if (!!etiqueta) {
      APIManager.current.getList('tags', { nombre: etiqueta}, (response) => {
        setEtiquetasSugeridas(response.data.listado.filter(tag => !tags.find(element => element.id === tag.id)));
      });
    } else {
      setEtiquetasSugeridas(tags);
    }
  }, [etiqueta, tags]);

  const stringsSimilares = (stringA, stringB) => {
    return stringA.toLocaleUpperCase().replaceAll(' ', '').localeCompare(stringB.toLocaleUpperCase().replaceAll(' ', ''), undefined, { sensitivity: 'base' }) === 0;
  }

  const esNuevaEtiqueta = (tagName) => {
    return (!tags.find(item => stringsSimilares(tagName, item.nombre))
      && !etiquetasSugeridas.find(item => stringsSimilares(tagName, item.nombre)));
  }

  const cargarMiembros = (callback) => {
    setOtrosMiembros([]);
    APIManager.current.getList('miembros', { comercioID: comercio.id, agrupacionID: agrupacionSeleccionada.id, bajas: false, orderBy: 'id' }, (response) => {
      setMiembros(response.data.listado);
    });
  }

  const cargarOtrosMiembros = (callback) => {
    APIManager.current.getList('miembros', { comercioID: comercio.id, noAgrupacionID: agrupacionSeleccionada.id, active: true, asArray: true }, (response) => {
      setOtrosMiembros(response.data.listado);
    });
  }

  const cargarTags = (callback) => {
    APIManager.current.getList('tags', { comercioID: comercio.id }, (response) => {
      setTags(response.data.listado);
    });
  }

  const cargarTagsSeleccionados = (callback) => {
    APIManager.current.getList('tags', { comercioID: comercio.id, agrupacionID: agrupacionSeleccionada.id }, (response) => {
      setTagsSeleccionados(response.data.listado);
    });
  }

  const toggleTag = (tag) => {
    if (tagsSeleccionados.find(item => item.id === tag.id)) {
      quitarEtiqueta(tag);
    } else {
      agregarEtiqueta(tag);
    }
  }

  const columnasMiembros = [
    { field: 'nombre', title: 'Miembros' },
    { field: '', component: item => agrupacionSeleccionada.id !== 0 ? <IconButton iconName='minus' onClick={() => quitarMiembro(item)}/> : null},
  ];

  const columnasOtrosMiembros = [
    { field: 'nombre', title: 'Nombre' },
    { field: '', component: item => <IconButton iconName='plus' onClick={() => agregarMiembro(item)}/>},
  ];

  const agregarMiembro = item => {
    // Agregamos al miembro (item) de la agrupacionSeleccionada
    APIManager.current.sendRequest('miembro', 'agregarMiembro', { comercioID: comercio.id, agrupacionID: agrupacionSeleccionada.id, miembroID: item.id }, (response) => {
      cargarMiembros();
      cargarOtrosMiembros();
    });
  }

  const quitarMiembro = item => {
    // Quitamos al miembro (item) de la agrupacionSeleccionada
    APIManager.current.sendRequest('miembro', 'quitarMiembro', { comercioID: comercio.id, agrupacionID: agrupacionSeleccionada.id, miembroID: item.id }, (response) => {
      cargarMiembros();
      cargarOtrosMiembros();
    });
  }

  const guardarItem = () => {
    const nuevoItem = Object.assign({}, agrupacionSeleccionada);
    if (agrupacionSeleccionada.id === -1) {
      nuevoItem.id = null;
    }
    nuevoItem.nombre = nombre;
    nuevoItem.descripcion = descripcion;
    onSaveItem(nuevoItem);
  }

  useEffect(() => {
    cargarTags();
    cargarTagsSeleccionados();
    cargarMiembros();
    cargarOtrosMiembros();
  }, []);

  return (
    <div className="admin-agrupacion">
      <div>
        <h2 className="form__title">Datos de la Agrupación</h2>
      </div>
      <div className='form__row'>
        <div className="input-combo agrupacion__nombre">
          <input className={nombre ? 'has-value' : ''} type='text' maxLength={100} value={nombre} onChange={(event) => setNombre(event.target.value)}/>
          <label>Nombre</label>
        </div>
        <div className="input-combo agrupacion__descripcion">
          <input className={descripcion ? 'has-value' : ''} type='text' maxLength={200} value={descripcion} onChange={(event) => setDescripcion(event.target.value)}/>
          <label>Descripción</label>
        </div>
        <IconButton className="admin-button" label="Guardar" onClick={guardarItem} />
        <IconButton className="admin-button" label="Cerrar" onClick={onClose} />
      </div>
      {(agrupacionSeleccionada.id === -1) ? null :
        <>
          <div className="form__row">
            <div className="miembro--tags">
              <label className='form__title etiquetas-title'>Etiquetas</label>
              <div className='tags__todos'>
                {tags && (tags.length > 0) && <div className={"filtros is--expanded has--active-filters"}>
                  <ul className="filtros__list">
                    {tags.map((tag) => {
                      const activo = tagsSeleccionados.find(item => item.id === tag.id);
                      // return activo ?
                      // (
                      return <li className={"filtros__item" + (activo ? ' is--activo' : '')} key={tag.id} >
                        <button onClick={() => toggleTag(tag)} className="control--filtro" >
                          <span className="button__label">
                            <FontAwesomeIcon className={"button__icon icon--" + (activo ? 'minus' : 'plus')} icon={["fas", (activo ? "minus" : "plus")]} />
                            {tag.nombre}
                          </span>
                        </button>
                      </li>
                      // ) : null
                    }
                    )}
                  </ul>
                </div>}
              </div>
            </div>
          </div>
          <div className='form__row row__miembros'>
            <ListaDeSeleccion className="miembros form__col" columnas={columnasMiembros} items={miembros} />
            <ListaDeSeleccion className='otrosMiembros form__col' columnas={columnasOtrosMiembros} items={otrosMiembros} />
          </div>
        </>
      }
    </div>
  );


}

export default Formulario;