import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import APIManager from '../../lib/apiManager';
import IconButton from "../../components/IconButton";

const Recepcion = ({setScanQR, setTiposDeNotificacionVisibles, ...props}) => {
  // Presenta seleccion de acciones (comerciante / consumidor)

  const dispatch = useDispatch();

  const usuario = useSelector(state => state.sistema.usuario);
  const [comercios, setComercios] = useState([]);
  const comercioAnterior = useSelector(state => state.sistema.comercio);
  const staff = useSelector(state => state.sistema.staff);

  const [showComerciosAAdministrar, setShowComerciosAAdministrar] = useState(false);

//  const [scanQR, setScanQR] = useState(false);

  const ultimoComercio = useSelector(state => state.sistema.comercio);
  const monedaPreferida = useSelector(state => state.sistema.monedaPreferida);

  const setComercioAAdministrar = () => {
    if (staff.length === 1) {
      administrarComercio(staff[0].comercio);
    } else {
      setShowComerciosAAdministrar(true);
    }
  }

  const administrarComercio = (comercio) => {
/*
    APIManager.current.sendRequest('comercio', 'getComercio', { comercioID: comercio.id }, (response) => {
      dispatch({ type: 'admin/setComercio', payload: response.data });
      APIManager.navigate(dispatch, 'admin');
    });
 */
    dispatch({ type: 'admin/setComercio', payload: comercio });
    APIManager.navigate(dispatch, 'admin');
  }

  const misComercios = () => {
    if (!!staff && staff.length > 0) {
      return <>
        <h3>Querés administrar tu comerció?</h3>
        {!showComerciosAAdministrar && <button onClick={setComercioAAdministrar}>Administrar mi comercio</button>}
        {
          showComerciosAAdministrar &&
          <ul>
            {staff.map((item, index) =>
              <li key={index}>
                <button onClick={() => administrarComercio(item.comercio)}>{item.comercio.nombre}</button>
              </li>)}
          </ul>
        }
      </>;
    }

  }

  const seccionUsuario = (rol) => {
    let result = null;
    switch (rol) {
      case 'ROL_ADMINISTRADOR':
        result = <div>
          <h2>Administración</h2>
          <h3>Querés administrar el sistema?</h3>
          <button onClick={() => APIManager.navigate(dispatch, 'admin')}>Administrar el sistema</button>
          {misComercios()}
        </div>;
        break;
      case 'ROL_STAFF':
        result = <div>
          <h2>Administración</h2>
          {misComercios()}
        </div>;
        break;
      default:
    }
    return result;
  }

  const setComercio = (comercio) => {
    if (!comercioAnterior || ((comercio.id !== comercioAnterior.id) )) {
      dispatch({ type: 'sistema/setUbicacion', payload: null });
      dispatch({ type: 'sistema/setComercio', payload: comercio });
      dispatch({ type: 'productos/set', payload: [] });
      dispatch({ type: 'productos/reset'});
    }
  }

  const verCartaDe = (comercio) => {
    if (!!comercio && !!comercio.ubicaciones) {
      comercio.ubicaciones = Object.values(comercio.ubicaciones);
    }
    setComercio(comercio);
    APIManager.navigate(dispatch, 'carta');
  }

  const actualizarComercios = () => {
    APIManager.current.getList('comercios', { active: true }, (response) => {
      setComercios(response.data.listado);
    })
  }

  useEffect(() => {
    if (comercios.length === 0) {
      actualizarComercios();
    }
  }, [comercios]);

  useEffect(() => {
    if (props.setMenuClassName) {
      props.setMenuClassName('menu-recepcion');
    }
    APIManager.current.dispatch({ type: 'sistema/setComercio', payload: null });
    setTiposDeNotificacionVisibles([]); // No mostramos notificaciones
  }, []);

  return (
    <div className="layout--base">
      {/* <div className="main-container"> */}
        <div className="recepcion">
          <div className="recepcion__titulo-container">
            <h1 className="titulo"><span className="highlight">Tomá el control</span><br /> de tus pedidos</h1>
            <p className="subtitulo">en bares, restó, clubes, hoteles<br /> y otros comercios</p>
          </div>
          <div className="recepcion__pasos">
            <ol className="pasos-list">
              <li className="paso">
                <span className="paso__number">1</span>
                <span className="paso__text">Escaneá</span>
              </li>
              <li className="paso">
                <span className="paso__number">2</span>
                <span className="paso__text">Pedí</span>
              </li>
              <li className="paso">
                <span className="paso__number">3</span>
                <span className="paso__text pibun-logo">Pibun</span>
              </li>
            </ol>
          </div>
          <div className="recepcion__controls">
            <IconButton label="Quiero pedir" className="control--quiero-pedir" onClick={() => APIManager.current.navigate('pedir')} />
            <IconButton label="Mi comercio" className="control--mi-comercio" />
          </div>
          <div className="recepcion__bottom-art"></div>
        </div>
      {/* </div> */}
    </div>
  )
}

export default Recepcion;