import moment from 'moment';
import 'moment/locale/es';

export const updateLocale = () => {
  moment.updateLocale('es', {
    weekdays : ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']
  });
  moment.locale('es');
}

export const formatoDeFecha = (fecha) => {
  updateLocale();
  const hoy = moment(new Date());
  const enElAnio = hoy.year() === moment(fecha).year();
  const enLaSemana = enElAnio && (hoy.week() === moment(fecha).week());
  const enElDia = enLaSemana && (hoy.weekday() === moment(fecha).weekday());

  return (enElDia && "[Hoy] HH:mm")
  || (enLaSemana && "dddd")
  || (enElAnio && "DD/MM")
  || ("DD/MM/YYYY")
}
