import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import APIManager from '../../lib/apiManager';
import { useHistory } from "react-router";
import IconButton from "../../components/IconButton";
import TarjetaDeComercio from "../../components/TarjetaDeComercio";

const QuieroPedir = ({setScanQR, ...props}) => {
  // Presenta seleccion de acciones (comerciante / consumidor)

  const dispatch = useDispatch();
  const history = useHistory();

  const usuario = useSelector(state => state.sistema.usuario);
  const [comercios, setComercios] = useState([]);
  const comercioAnterior = useSelector(state => state.sistema.comercio);
  const staff = useSelector(state => state.sistema.staff);

  const [showComerciosAAdministrar, setShowComerciosAAdministrar] = useState(false);

//  const [scanQR, setScanQR] = useState(false);

  const ultimoComercio = useSelector(state => state.sistema.comercio);
  const monedaPreferida = useSelector(state => state.sistema.monedaPreferida);

  const setComercioAAdministrar = () => {
    if (staff.length === 1) {
      administrarComercio(staff[0].comercio);
    } else {
      setShowComerciosAAdministrar(true);
    }
  }

  const administrarComercio = (comercio) => {
/*
    APIManager.current.sendRequest('comercio', 'getComercio', { comercioID: comercio.id }, (response) => {
      dispatch({ type: 'admin/setComercio', payload: response.data });
      APIManager.navigate(dispatch, 'admin');
    });
 */
    dispatch({ type: 'admin/setComercio', payload: comercio });
    APIManager.navigate(dispatch, 'admin');
  }

  const misComercios = () => {
    if (!!staff && staff.length > 0) {
      return <>
        <h3>Querés administrar tu comerció?</h3>
        {!showComerciosAAdministrar && <button onClick={setComercioAAdministrar}>Administrar mi comercio</button>}
        {
          showComerciosAAdministrar &&
          <ul>
            {staff.map((item, index) =>
              <li key={index}>
                <button onClick={() => administrarComercio(item.comercio)}>{item.comercio.nombre}</button>
              </li>)}
          </ul>
        }
      </>;
    }

  }

  const seccionUsuario = (rol) => {
    let result = null;
    switch (rol) {
      case 'ROL_ADMINISTRADOR':
        result = <div>
          <h2>Administración</h2>
          <h3>Querés administrar el sistema?</h3>
          <button onClick={() => APIManager.navigate(dispatch, 'admin')}>Administrar el sistema</button>
          {misComercios()}
        </div>;
        break;
      case 'ROL_STAFF':
        result = <div>
          <h2>Administración</h2>
          {misComercios()}
        </div>;
        break;
      default:
    }
    return result;
  }

  const setComercio = (comercio) => {
    if (!comercioAnterior || ((comercio.id !== comercioAnterior.id) )) {
      dispatch({ type: 'sistema/setUbicacion', payload: null });
      dispatch({ type: 'sistema/setComercio', payload: comercio });
      dispatch({ type: 'productos/set', payload: [] });
      dispatch({ type: 'productos/reset'});
    }
  }

  const verCartaDe = (comercio) => {
    if (!!comercio && !!comercio.ubicaciones) {
      comercio.ubicaciones = Object.values(comercio.ubicaciones);
    }
    setComercio(comercio);
    APIManager.navigate(dispatch, 'carta');
  }

  const actualizarComercios = () => {
    APIManager.current.getList('comercios', { active: true }, (response) => {
      setComercios(response.data.listado);
    })
  }

  const showQRScan = () => {
    setScanQR(true);
  }

  useEffect(() => {
    if (props.setMenuClassName) {
      props.setMenuClassName('menu-recepcion');
    }
    actualizarComercios();
  }, []);

  return (
    <div className="layout--base">
      <div className="quiero-pedir__page">
        <div className="quiero-pedir__titulo-container">
          <h1 className="titulo"><span className="highlight">Hacer tu pedido</span><br /> nunca fué tan fácil</h1>
        </div>
        <div className="quiero-pedir__controls">
          <IconButton
            // className="control--ubicacion-qr-selector"
            className="control--escanear-qr"
            iconName="qrcode"
            // iconFixedWidth={true}
            label="Escanear código QR"
            onClick={showQRScan}
          />
        </div>
        <div className="quiero-pedir__titulo-container">
          <p className="subtitulo">o pedí en</p>
        </div>
        <div className="quiero-pedir__comercios">
          {!!comercios && (comercios.length > 0) && comercios.map(item =>
            <TarjetaDeComercio key={item.id} {...item} onClick={() => {verCartaDe(item)}} />)}
          <TarjetaDeComercio className="tu-comercio" nombre="Tu Comercio" descripcion="Registrá tu comercio" logo="https://s3.amazonaws.com/images.softguild.com.ar/comercios/TuComercio.png" />
        </div>
      </div>
    </div>
  )
}

export default QuieroPedir;