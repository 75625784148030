import { useEffect, useState } from "react";
import APIManager from '../../../../../../lib/apiManager';


const Formulario = ({estadoDePedidoSeleccionado, errores, setErrores, limpiarErrores, onClose, onSaveEstadoDePedido}) => {

  const [nombre, setNombre] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [orden, setOrden] = useState('');

  useEffect(() => {
    console.log('SELECCION :', estadoDePedidoSeleccionado);

    limpiarErrores();

    if (estadoDePedidoSeleccionado) {
      setNombre(estadoDePedidoSeleccionado.nombre ? estadoDePedidoSeleccionado.nombre : '');
      setDescripcion(estadoDePedidoSeleccionado.descripcion ? estadoDePedidoSeleccionado.descripcion : '');
      setOrden(estadoDePedidoSeleccionado.orden ? estadoDePedidoSeleccionado.orden : '');
    }
  },[estadoDePedidoSeleccionado]);

  const guardarFormulario = (event) => {

    const erroresDelFormulario = [];

    if (!nombre) {
      console.log('nombre:', nombre);
      erroresDelFormulario.push({message: 'El nombre del estado de pedido no puede estar vacio'});
    }

    if (!orden) {
      console.log('orden:', orden);
      erroresDelFormulario.push({message: 'El orden del estado de pedido no puede estar vacio'});
    }

    setErrores(erroresDelFormulario);

    if ((erroresDelFormulario.length === 0)) {
      const itemModificado = {};

      Object.assign(itemModificado, estadoDePedidoSeleccionado);

      itemModificado.nombre = nombre;
      itemModificado.descripcion = descripcion;
      itemModificado.orden = orden;

      if (onSaveEstadoDePedido) {
        onSaveEstadoDePedido(itemModificado);
      }

    }
  }

  const cancelarFormulario = () => {
    if (onClose) {
      onClose();
    }
  }

  return (
    <div className="formulario">
      <div>
        <h2>Datos del Estado de Pedido</h2>
        <div>
          <label>Nombre de Estado</label>
          <input type='text' value={nombre} onChange={(event) => setNombre(event.target.value)}/>
        </div>
        <div>
          <label>Descripción</label>
          <input type='text' value={descripcion} onChange={(event) => setDescripcion(event.target.value)}/>
        </div>
        <div>
          <label>Orden</label>
          <input type='number' min="1" value={orden} onChange={(event) => setOrden(event.target.value)}/>
        </div>
      </div>
      <div>
        {!estadoDePedidoSeleccionado.id && <button onClick={guardarFormulario}>Crear Estado</button>}
        {estadoDePedidoSeleccionado.id && <button onClick={guardarFormulario}>Guardar</button>}
        <button onClick={cancelarFormulario}>Cancelar</button>
      </div>
    </div>
  )

}

export default Formulario;