import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ConfirmarPedidoCTA from '../../components/ConfirmarPedidoCTA';
import APIManager from '../../lib/apiManager';
import ListadoDeProductos from '../../components/ListadoDeProductos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { filtroEnPedido } from '../../redux/reducers/productos';
import { useInterval } from '../../lib/useInterval';
import { useHistory } from 'react-router';
import IconButton from '../../components/IconButton';
import Modal from '../../components/Modal';
import moment from 'moment';

const CartaDeProductos = ({setScanQR, setTiposDeNotificacionVisibles, ...props}) => {

  const history = useHistory();
  const dispatch = useDispatch();

  const productos = useSelector(state => state.productos);
  const cliente = useSelector(state => state.sistema.cliente);
  const usuario = useSelector(state => state.sistema.usuario);
  const miembro = useSelector(state => state.sistema.miembro);
  const ubicacion = useSelector(state => state.sistema.ubicacion);
  const comercio = useSelector(state => state.sistema.comercio);
  const listasDePrecios = useSelector(state => state.sistema.listasDePrecios);

  const [agrupaciones, setAgrupaciones] = useState([]);
  const [listaDePrecios, setListaDePrecios] = useState(null);
  const [itemsEnPedido, setItemsEnPedido] = useState(0);
  const [precioDelPedido, setPrecioDelPedido] = useState(0);
  const moneda = useSelector(state => state.sistema.monedaUltima);

  const [filtrosExpandidos, setFiltrosExpandidos] = useState(false);
  const [filtros, setFiltros] = useState([]);
  const [filtrosActivos, setFiltrosActivos] = useState([]);
  const filtrosExternos = useSelector(state => state.sistema.filtros);

  const [productosFiltrados, setProductosFiltrados] = useState([]);
  const [productoAgrandado, setProductoAgrandado] = useState(null);

  const [nombre, setNombre] = useState(cliente.nombre);
  const [sinNombre, setSinNombre] = useState(false);

  const [pedidoConfirmado, setPedidoConfirmado] = useState(null);

  const [showUbicacionesModal, setShowUbicacionesModal] = useState(false);
  const [ubicacionSeleccionada, setUbicacionSeleccionada] = useState(null);

  // const [pedidosPendientes, setPedidosPendientes] = useState(['uno', 'dos']);
  const [pedidosPendientes, setPedidosPendientes] = useState([]);
  const [tiempoPendiente, setTiempoPendiente] = useState(null);

  const notificaciones = useSelector(state => state.notificaciones);
  const [hayQueConsultarPedidos, setHayQueConsultarPedidos] = useState(true);

  const [tagsProhibidos, setTagsProhibidos] = useState([]);

  const marcarComoLeida = (notificacion) => {
    APIManager.current.sendRequest('notificacion', 'marcarComoLeida', { notificacion }, response => {
      if (response.success) {
        APIManager.current.dispatch({ type: 'notificaciones/read', payload: notificacion });
      }
    })
  }

  const aceptarNombre = () => {
    const nuevoCliente = Object.assign({}, cliente);
    nuevoCliente.nombre = nombre;
    APIManager.current.dispatch({ type: 'sistema/setCliente', payload: nuevoCliente });
    APIManager.current.sendRequest('usuario', 'modificarCliente', { usuario, cliente: nuevoCliente }, (response) => {
      if (response.success) {
        setSinNombre(false);
      }
    });
  }

  useEffect(() => {
    if (productos) {
      // Filtramos los productos segun los tags activos
      // Ademas filtramos los que no figuran activos/disponibles
      const filtrados = productos.filter(producto =>
        producto.active && producto.disponible
        && (!filtrosActivos || (filtrosActivos.length === 0) || filtrosActivos.every(tag => {
          const matches = producto.tags.some(productoTag => productoTag.id === tag.id);
          return matches;
        }))
      );

      const visibles = filtrados.filter(producto => {
        // producto.tags
        return producto.tags.filter(tag => tagsProhibidos.includes(tag.id)).length === 0;
      });

      visibles.sort((item1, item2) => item1.nombre.localeCompare(item2.nombre)); // Orden alfabetico

      setProductosFiltrados(visibles);
    }
  }, [productos, filtrosActivos]);

  const actualizarPedidos = () => {
    APIManager.current.getUIData('getPedidos', { comercioID: comercio.id, estados: [1] }, data => {
      let ultimaModificacion = null;
      data.listado.forEach(pedido => {
        pedido.ultimaModificacion = moment(pedido.ultimaModificacion);
        if (!ultimaModificacion || (ultimaModificacion > pedido.ultimaModificacion)) {
          ultimaModificacion = pedido.ultimaModificacion;
        }
      });
      setTiempoPendiente(ultimaModificacion);
      setPedidosPendientes(data.listado);
      setHayQueConsultarPedidos(data.listado.length > 0);
    })
  }

  const actualizarPrecios = () => {
    APIManager.getUIData('getMonedas', { comercioID: comercio.id, active: true}, (data) => {
      dispatch({ type: 'sistema/setMonedas', payload: data.listado });
    });
    APIManager.getUIData('getListasDePrecios', { comercioID: comercio.id, active: true }, (data) => {
      dispatch({ type: 'sistema/setListasDePrecios', payload: data.listado });
    });

  }

  const actualizarUbicaciones = () => {
    APIManager.current.getUIData('getUbicaciones', { comercioID: comercio.id, active: true }, data => {
      dispatch({ type: 'sistema/setUbicaciones', payload: data.listado });
    })
  }

  const actualizarProductos = () => {
    if (comercio) {
      const postData = { comercioID: comercio.id, active: true, asArray: true };
      if (listaDePrecios) {
        postData.listaDePreciosID = listaDePrecios.id;
      }
      APIManager.getList('productos', postData , (response) => {
        dispatch({ type: 'productos/set', payload: response.data.listado });
      });
    }
  }

  useEffect(() => {
    // if (!comercio) {
    //   // No tenemos comercio, nos vamos de la carta
    //   //APIManager.navigate(dispatch, '/recepcion');
    //   history.push('/recepcion');
    // } else {
      actualizarProductos();
    // }
  }, [listaDePrecios]);

  useInterval(() => {
    // Actualiza la lista de productos periodicamente
    // actualizarProductos();
    actualizarPrecios();
  }, 5 * 60 * 1000); // periodo de 5 minutos

  const verPedido = () => {
    setFiltrosActivos([filtroEnPedido]);
  }

  const enviarPedido = () => {
    if (!ubicacion) {
      seleccionarUbicacion();
    } else if (!cliente.nombre) {
      setSinNombre(true);
    } else {
      if ((itemsEnPedido > 0)) {
        const pedido = {
          estado: 'nuevo',
          cliente: cliente,
          ubicacion: ubicacion,
          productos: productos.filter(item => item.cantidad > 0),
          precio: precioDelPedido
        };
        APIManager.sendRequest('post', 'pedido', 'nuevoPedido', { pedido }, ( response ) => {
          if (response.success) {
            setHayQueConsultarPedidos(true);
            dispatch({ type: 'productos/reset' });
            // dispatch({ type: 'notificaciones/add', payload: {message: 'Pedido confirmado con el numero: ' + response.data.codigo}})
            setPedidoConfirmado(response.data);
          }
        })
      } else {
        alert('no hay productos en el pedido');
      }
    }
  }

  const toggleFiltro = (filtro) => {
    if (filtrosActivos.includes(filtro)) {
      setFiltrosActivos([...filtrosActivos.filter(item => item.id !== filtro.id)]);
    } else {
      setFiltrosActivos([...filtrosActivos, filtro]);
    }
  }

  const viendoPedido = (unicamente = false) => {
    return filtrosActivos.includes(filtroEnPedido) && (unicamente ? (filtrosActivos.length === 1) : true);
  }

  const agregarProducto = (producto) => {
    dispatch({ type: 'productos/agregarProducto', payload: producto });
  }

  const quitarProducto = (producto, total=false) => {
    if (producto.cantidad > 0) {
      if ((!total && (producto.cantidad > 1)) || !viendoPedido(false) || window.confirm('quitar producto?')) {
        if (total) {
          dispatch({ type: 'productos/quitarProducto', payload: producto });
        } else {
          dispatch({ type: 'productos/restarProducto', payload: producto });
        }
      }
    }
  }

  const cerrarPedidoConfirmado = () => {
    setPedidoConfirmado(null);
    dispatch({ type: 'productos/reset' });
  }

  const setUbicacion = (nuevaUbicacion) => {
    if (!!nuevaUbicacion) {
      APIManager.current.dispatch({ type: 'sistema/setUbicacion', payload: nuevaUbicacion });
    }
    setShowUbicacionesModal(false);
  }

  const seleccionarUbicacion = () => {
    setUbicacionSeleccionada(false);
    setShowUbicacionesModal(true);
  }

  useEffect(() => {
    if (filtros.length === 0) {
      APIManager.getList('tags', {comercioID: comercio.id, active: true, disponible: true}, (response) => {
        setFiltros(response.data.listado);
      });
    }
  }, [filtros, productos]);

  useEffect(() => {
    let cantidad = 0;
    let precio = 0;
    productos.forEach(item => {
      cantidad += item.cantidad;
      precio += item.precio * item.cantidad;
    });
    setItemsEnPedido(cantidad);
    setPrecioDelPedido(precio);
    if (cantidad > 0) {
      // Hay cantidad pedida, agregamos el filtro de "en pedido"
      setFiltros([...filtros.filter(tag => tag !== filtroEnPedido), filtroEnPedido]);
    } else {
      setFiltros(filtros.filter(tag => tag !== filtroEnPedido));
      setFiltrosActivos(filtrosActivos.filter(tag => tag !== filtroEnPedido));
    }
  }, [productos]);

  useEffect(() => {
    if (!!moneda && listasDePrecios && (listasDePrecios.length > 0)) {
      const lista = listasDePrecios.find(item => item.moneda.id === moneda.id);
      setListaDePrecios(lista);
      dispatch({ type: 'sistema/setMonedaUltima', payload: moneda });
    }
  }, [moneda, listasDePrecios, dispatch]);

  useEffect(() => {
    if (!comercio) {
      APIManager.current.navigate('/recepcion');
    } else {
      if (!ubicacion && (comercio.ubicaciones.length === 1)) {
        setUbicacion(comercio.ubicaciones[0]);
      }
      setTiposDeNotificacionVisibles(['PEDIDO_TOMADO']);
      // Levantamos las agrupaciones del comercio
      APIManager.current.getList('agrupaciones', { comercioID: comercio.id }, (response) => {
        if (response.success) {
          let agrupacionesDelComercio = response.data.listado;
          setAgrupaciones(agrupacionesDelComercio);
          console.log('actualizacion de tags para: ', miembro);
          // if (!!miembro) {
            let tagsProhibidosL = [];
            let agrupacionesExcluidas = agrupacionesDelComercio.filter(agrupacion => !miembro || !miembro.agrupaciones.find(agrupacionDelMiembro => agrupacionDelMiembro.id === agrupacion.id));
            agrupacionesExcluidas.forEach(agrupacion => {
              tagsProhibidosL = [...tagsProhibidosL, ...(agrupacion.tags)];
            });
            setTagsProhibidos(tagsProhibidosL);
          // }
        }
      })
    }
  }, [comercio]);

  useEffect(() => {
          // if (!!miembro) {
            let tagsProhibidosL = [];
            let agrupacionesExcluidas = agrupaciones.filter(agrupacion => !miembro || !miembro.agrupaciones.find(agrupacionDelMiembro => agrupacionDelMiembro.id === agrupacion.id));
            agrupacionesExcluidas.forEach(agrupacion => {
              tagsProhibidosL = [...tagsProhibidosL, ...(agrupacion.tags)];
            });
            setTagsProhibidos(tagsProhibidosL);
          // }
  }, [agrupaciones, miembro]);

  useEffect(() => {
    if (hayQueConsultarPedidos) {
      setHayQueConsultarPedidos(false);
      setTimeout(actualizarPedidos, 10000);
    }
  }, [hayQueConsultarPedidos]);

  useEffect(() => {
    // Al iniciar, si hay filtros en el state guardado (vienen de afuera), seteamos los filtros locales y limpiamos
    if (!!filtrosExternos && (filtrosExternos.length > 0)) {
      setFiltrosActivos(filtrosExternos.map(item => {
        return { idtags: item.id, id: item.id };
      }));
      dispatch({ type: 'sistema/setFiltros', payload: [] });
    }
  }, []);

  return (
    comercio && comercio.active &&
    <div className="layout--base">
      <div className="test-bar">
        <div className="session-information__location">
            <div className="location">
              <span className="location__business">
                <FontAwesomeIcon className="icon--map-marker" icon={["fas", "map-marker-alt"]} />
                <span className="location__business--name">{ comercio.nombre }</span>
              </span>
              <span className="location__seating">
                {ubicacion ? 'Ubicacion: ' + ubicacion.nombre : ''}
              </span>
            </div>
        </div>

        <IconButton className="control--filters icon-position--right" onClick={() => setFiltrosExpandidos(!filtrosExpandidos)} iconName="filter" label="Filtros" />

      </div>

      {filtros && (filtros.length > 0) && <div className={"filtros" + (((filtrosActivos.length > 0) && !viendoPedido(true)) ? ' has--active-filters' : '') + (filtrosExpandidos ? ' is--expanded' : ' is--collapsed')}>
        <ul className="filtros__list">
          {filtros.map((tag) => {
            const activo = filtrosActivos.find(item => item.id === tag.id);
            if (!!tagsProhibidos.find(item => item === tag.id)) {
              return null;
            }
            return ((tag.id !== 'enPedido') && (filtrosExpandidos || activo)) ?
            (
              <li className={"filtros__item" + (activo ? ' is--activo' : '')} key={tag.id} >
                <button onClick={() => toggleFiltro(tag)} className="control--filtro" >
                  <span className="button__label">
                    <FontAwesomeIcon className={"button__icon icon--" + (activo ? 'minus' : 'plus')} icon={["fas", (activo ? "minus" : "plus")]} />
                    {tag.nombre}
                  </span>
                </button>
              </li>
            ) : null
            }
          )}
        </ul>
      </div>}

      {!!pedidosPendientes && !!tiempoPendiente && (pedidosPendientes.length > 0)
        && <div className={"pedidos-pendientes"}>
          <button className="control--pedidos-pendientes">
            <span className="leyenda">Hay <span className="cantidad">{pedidosPendientes.length}</span> pedidos pendientes</span>
            <span className="tiempo">{ tiempoPendiente ? tiempoPendiente.fromNow() : '' }</span>
            <FontAwesomeIcon className="icon--eye" icon={["fas", "eye"]} />
          </button>
      </div>}

      <div className={"main-container" + (filtrosExpandidos ? ' has--filters-expanded' : '') + (((filtrosActivos.length > 0) && !viendoPedido(true)) ? ' has--active-filters' : '') + (itemsEnPedido > 0 ? ' has--items-in-cart' : '') + (filtrosActivos.includes(filtroEnPedido) ? ' is--showing-cart' : '')}> {/* TODO - Create a function that returns all these different states */}
        {notificaciones.lista.filter(notificacion => !notificacion.fecha_lectura && (notificacion.medio_nombre === 'POPUP') && (notificacion.remitente_id === comercio.id) && (notificacion.remitente_type === 2)).map((notificacion, index) => {
          return index === 0 ? (
            <Modal className="pedidoConfirmado">
              <FontAwesomeIcon className="modal__icon--main" icon="check-circle" />
              <p className="modal__titulo">{notificacion.mensaje.title}</p>
              <p className="modal__texto">{notificacion.mensaje.message}</p>
              <IconButton className="modal__control--single control--confirmar-pedido" label="Entendido!" onClick={() => marcarComoLeida(notificacion)} />
            </Modal>
          ) : null;
        })}

        {viendoPedido(false) &&
          <div className="carta-de-productos__page-controls">
            <IconButton
              className="control--return-to-menu"
              onClick={() => toggleFiltro(filtroEnPedido)}
              iconName="arrow-left"
              iconFixedWidth={true}
              label="Volver a la carta" />
            {!viendoPedido(true) &&
            <div className="carta-de-productos__cart-warning">
              <p className="warning-text">
                <FontAwesomeIcon className="icon--warning" icon={["fas", "exclamation-triangle"]} />
                Viendo sólo parte del carrito/pedido
              </p>
            </div>}
          </div>
          }
        <section className="section--carta">
          {!!pedidoConfirmado && <Modal className="pedidoConfirmado">
              <FontAwesomeIcon className="modal__icon--main" icon="check-circle" />
              <p className="modal__titulo">Tu pedido ha sido enviado!</p>
              <p className="modal__texto">El código es {pedidoConfirmado.codigo}</p>
              <IconButton className="modal__control--single control--confirmar-pedido" label="Entendido!" onClick={cerrarPedidoConfirmado} />
          </Modal>}
          {!!sinNombre && <Modal className="sinNombre">
              <FontAwesomeIcon className="modal__icon--main" icon="check-circle" />
              <p className="modal__titulo">Ya casi!</p>
              <p className="modal__texto">Cómo querés que te llamemos?</p>
              <input value={nombre} onChange={(event) => setNombre(event.target.value)} />
              <IconButton className="modal__control--single control--confirmar-pedido" label="Aceptar" onClick={aceptarNombre} />
          </Modal>}
          {!!showUbicacionesModal && <Modal className="modal--seleccionar-ubicacion">
              <FontAwesomeIcon className="modal__icon--main" icon="warning" />
              <p className="modal__titulo">Primero debés seleccionar una ubicación</p>
            <IconButton className="modal__control--single modal__button icon-position--right" label="Escanear código QR" iconName="qrcode" onClick={() => { setScanQR(true); setShowUbicacionesModal(false)}} />
              <p className="modal__texto">
                <span>sino</span>
              </p>
              <select
                className="modal__select"
                defaultValue={''}
              onChange={event => { setUbicacionSeleccionada(comercio.ubicaciones.find(item => item.id === parseInt(event.target.value)))}}
                >
                <option value=''>Seleccione una ubicación</option>
                {comercio.ubicaciones.map((item, index) =>
                  <option value={item.id} >{item.nombre}</option>
                )}
              </select>
              <div className="layout--control-container">
                <IconButton disabled={!ubicacionSeleccionada} className="modal__button control--confirmar" label="Confirmar" onClick={() => { setUbicacion(ubicacionSeleccionada)}} />
                <IconButton className="modal__button button--secondary" label="Cancelar" onClick={() => {setShowUbicacionesModal(false)}} />
              </div>
          </Modal>}
          {productosFiltrados && <ListadoDeProductos productos={productosFiltrados} viendoPedido={ viendoPedido } productoAgrandado={productoAgrandado} setProductoAgrandado={setProductoAgrandado} agregarProducto={agregarProducto} quitarProducto={quitarProducto} />}
        </section>
      </div>
      {viendoPedido(true)
        ? <ConfirmarPedidoCTA productos={itemsEnPedido} precio={precioDelPedido} label="Enviar pedido" onClick={enviarPedido} visible={itemsEnPedido > 0} />
        : <ConfirmarPedidoCTA productos={itemsEnPedido} precio={precioDelPedido} label="Ver pedido" onClick={verPedido} visible={itemsEnPedido > 0} />
      }
    </div>
  );
}

export default CartaDeProductos;