import { useEffect, useState } from 'react';
import ListaDeSeleccion from '../../components/ListaDeSeleccion';
import { useSelector, useDispatch } from 'react-redux';
import APIManager from '../../../../lib/apiManager';

const Disponibles = ({ setTitulo }) => {

  const [items, setItems] = useState([]);

  const [itemsSeleccionados, setItemsSeleccionados] = useState([]);
  const [itemSeleccionado, setItemSeleccionado] = useState(null);

  const comercio = useSelector(state => state.admin.comercio);

  const onItemChange = (item) => {
    APIManager.current.sendRequest('producto', 'setDisponible', { producto: {...item, disponible: !item.disponible}}, (response) => {
      if (response.success) {
        recargarLista();
      }
    });
  }

  const onItemToggle = (item) => {
    APIManager.current.sendRequest('producto', 'setDisponible', { producto: {...item, disponible: !item.disponible}}, (response) => {
      if (response.success) {
        recargarLista();
      }
    });
  }

  const columnas = [
    { field: 'nombre', title: 'Producto' },
    { field: 'disponible', title: 'Disponible', editable: true, className: 'disponible-column', onChange: onItemToggle },
  ];

  const recargarLista = (callback) => {
    APIManager.current.getList('productos', { comercioID: comercio.id, orderBy: 'id', active: true }, (response) => {
      setItems(response.data.listado.map(item => { item.editable = true; return item}));
    });
  };

  useEffect(() => {
    setTitulo('Disponibles',comercio);
    recargarLista();
  }, []);

  return (
    <>
      <h2 className="screen-title">Disponibilidad de productos activos</h2>
      <button onClick={recargarLista}>Actualizar</button>
      <ListaDeSeleccion className='disponibles' columnas={columnas} items={items} disabledOnSeleccion={false} rowSelect={false} itemSeleccionado={itemSeleccionado} itemsSeleccionados={itemsSeleccionados} />
    </>
  );
}

export default Disponibles;