import { useEffect, useState } from "react";
import APIManager from '../../../../../../lib/apiManager';


const Formulario = ({tagSeleccionada, errores, setErrores, limpiarErrores, onClose, onSaveTag}) => {

  const [nombre, setNombre] = useState('');
  const [descripcion, setDescripcion] = useState('');

  useEffect(() => {
    console.log('SELECCION :', tagSeleccionada);

    limpiarErrores();

    if (tagSeleccionada) {
      setNombre(tagSeleccionada.nombre ? tagSeleccionada.nombre : '');
      setDescripcion(tagSeleccionada.descripcion ? tagSeleccionada.descripcion : '');
    }
  },[tagSeleccionada]);

  const guardarFormulario = (event) => {

    const erroresDelFormulario = [];

    if (!nombre) {
      console.log('nombre:', nombre);
      erroresDelFormulario.push({message: 'El nombre de la etiqueta no puede estar vacio'});
    }

    setErrores(erroresDelFormulario);

    if ((erroresDelFormulario.length === 0)) {
      const itemModificado = {};

      Object.assign(itemModificado, tagSeleccionada);

      itemModificado.nombre = nombre;
      itemModificado.descripcion = descripcion;

      if (onSaveTag) {
        onSaveTag(itemModificado);
      }

    }
  }

  const cancelarFormulario = () => {
    if (onClose) {
      onClose();
    }
  }

  return (
    <div className="formulario">
      <div>
        <h2>Datos de la Etiqueta</h2>
        <div>
          <label>Etiqueta</label>
          <input type='text' value={nombre} onChange={(event) => setNombre(event.target.value)}/>
        </div>
        <div>
          <label>Descripción</label>
          <input type='text' value={descripcion} onChange={(event) => setDescripcion(event.target.value)}/>
        </div>
      </div>
      <div>
        {!tagSeleccionada.id && <button onClick={guardarFormulario}>Crear Etiqueta</button>}
        {tagSeleccionada.id && <button onClick={guardarFormulario}>Guardar</button>}
        <button onClick={cancelarFormulario}>Cancelar</button>
      </div>
    </div>
  )

}

export default Formulario;