import React from 'react';
import APIManager from '../../lib/apiManager';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

const Logout = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const logoutUsuario = () => {
    APIManager.sendRequest('post', 'usuario', 'logout', {}, (response) => {
      if (response.success) {
        dispatch({ type: 'sistema/setUsuario', payload: null });
        dispatch({ type: 'sistema/setCliente', payload: response.data.cliente });
        dispatch({ type: 'sistema/setMiembro', payload: null });
        APIManager.setAuthToken(null);
        history.goBack();
      } else {
        alert(response.error);
      }
    });
  }

  return <>{ logoutUsuario() }</>;
}

export default Logout;