import { useEffect, useState } from 'react';
import ListaDeSeleccion from '../../components/ListaDeSeleccion';
import { useSelector } from 'react-redux';
import APIManager from '../../../../lib/apiManager';
import IconButton from '../../../../components/IconButton';
import { useHistory } from 'react-router';

const ListasDePrecios = ({ setTitulo, errores, setErrores }) => {

  const history = useHistory();

  const [items, setItems] = useState([]);

  const [itemsSeleccionados, setItemsSeleccionados] = useState([]);
  const [itemSeleccionado, setItemSeleccionado] = useState(null);

  const [listasDePrecios, setListasDePrecios] = useState([]);
  // const [productos, setProductos] = useState([]);

  const comercio = useSelector(state => state.admin.comercio);
  const [monedas, setMonedas] = useState([]);

  const [listaDePrecios, setListaDePrecios] = useState(null);
  const [nombre, setNombre] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [moneda, setMoneda] = useState(null);

  const onItemChange = (item) => {
    if (!itemSeleccionado || (itemSeleccionado.id !== item.id)) {
      setItemSeleccionado(item);
      setItemsSeleccionados([item]);
    } else {
      setItemSeleccionado(null);
      setItemsSeleccionados([]);
    }

  }

  const cancelEdit = () => {
    recargarLista();
  }

  const guardarItem = (listaDePrecios) => {
    APIManager.current.sendRequest('comercio', 'modificarListaDePrecios', { listaDePrecios }, response => {
      if (response.success) {
        onFormularioClose();
        recargarLista();
        } else {
        setErrores(response.errors);
      }
    })
  }

  // const guardarItem = (item) => {
  //   APIManager.current.sendRequest('comercio', 'modificarListaDePrecios', { lista: item }, (response) => {
  //     if (response.success) {
  //       onFormularioClose();
  //       recargarLista();
  //     }
  //   });
  // }

  const bajaItem = (item) => {
    if (window.confirm('Desea eliminar la lista de precios: "' + item.nombre + '" ?'))
    {
      APIManager.current.sendRequest('comercio', 'bajaDeListaDePrecios', { listaDePrecios: item }, (response) => {
        if (response.success) {
          onFormularioClose();
          recargarLista();
        }
      });
    }
  }
  const onFormularioClose = () => {
    if (!itemSeleccionado.id) {
      setItems(items.filter(item => !!item.id));
    }
    setItemSeleccionado(null);
    setItemsSeleccionados([]);
  }

  const changeNombre = (item, value) => {
    const nuevoItem = Object.assign({}, item);
    nuevoItem.nombre = value;
    setItems(items.map(viejoItem => (viejoItem.editable && (viejoItem.id === item.id)) ? nuevoItem : viejoItem));
    setItemSeleccionado(nuevoItem);
  }

  const changeDescripcion = (item, value) => {
    const nuevoItem = Object.assign({}, item);
    nuevoItem.descripcion = value;
    setItems(items.map(viejoItem => (viejoItem.editable && (viejoItem.id === item.id)) ? nuevoItem : viejoItem));
    setItemSeleccionado(nuevoItem);
  }

  const changeCodigo = (item, value) => {
    const nuevoItem = Object.assign({}, item);
    nuevoItem.codigo = value;
    setItems(items.map(viejoItem => (viejoItem.editable && (viejoItem.id === item.id)) ? nuevoItem : viejoItem));
    setItemSeleccionado(nuevoItem);
  }

  const changeActive = (item) => {
    if (item.editable) {
      const nuevoItem = Object.assign({}, item);
      nuevoItem.active = !item.active;
      setItems(items.map(viejoItem => (viejoItem.editable && (viejoItem.id === item.id)) ? nuevoItem : viejoItem));
      setItemSeleccionado(nuevoItem);
    }
  }

  const changeVisible = (item, value) => {
    const nuevoItem = Object.assign({}, item);
    nuevoItem.active = value;
    setItems(items.map(viejoItem => (viejoItem.editable && (viejoItem.id === item.id)) ? nuevoItem : viejoItem));
    setItemSeleccionado(nuevoItem);
  }

  const setMonedaSelect = (event, item) => {
    item.moneda = monedas.find(moneda => moneda.id === parseInt(event.target.value));
  }

  const changeMoneda = (item, value) => {
    const nuevoItem = Object.assign({}, item);
    nuevoItem.moneda = monedas.find(moneda => moneda.id === value);
    setItems(items.map(viejoItem => (viejoItem.editable && (viejoItem.id === item.id)) ? nuevoItem : viejoItem));
    setItemSeleccionado(nuevoItem);
  }


  const columnas = [
    { field: 'nombre', editable: true, title: 'Nombre', onChange: changeNombre },
    { field: 'descripcion', editable: true, title: 'Descripción', onChange: changeDescripcion },
    { field: 'moneda', subfield: 'nombre', title: 'Moneda', editable: true, select: { options: monedas.map(item => { item.text = item.nombre; return item})}, onChange: changeMoneda },
    { field: 'active', editable: true, title: 'Activo', onChange: changeActive },
    // { field: 'visible', editable: true, title: 'Visible', onChange: changeVisible },
    {
      field: 'acciones', title: '', className: 'row-controls--block', component: (item) => {
        if (item.id && !item.editable) {
          return <>
            <IconButton className="admin-button" iconName="edit" label="Editar" onClick={() => { item.editable = true; onItemChange(item)}} visible={(!itemSeleccionado || itemSeleccionado === item)} />
          </>
        } else {
          return <>
            <IconButton className="admin-button" iconName="check" label="Guardar" onClick={() => guardarItem(item)} />
            <IconButton className="admin-button" iconName="times" label="Cancelar" onClick={cancelEdit} />
            <IconButton className="admin-button" iconName="trash" label="Eliminar" onClick={() => bajaItem(item)} />
          </>
        }
      }
    },
  ];

  const nuevoItem = () => {
    const itemNuevo = {
      id: null,
      comercio: comercio,
      nombre: '',
      codigo: '',
      descripcion: '',
      active: false,
      editable: true,
    };

    setItems([itemNuevo, ...items]);
    setItemSeleccionado(itemNuevo);
    setItemsSeleccionados([itemNuevo]);
  }

  const cargarMonedas = () => {
    APIManager.current.getList('monedas', {}, response => {
      setMonedas(response.data.listado);
    });
  }

  const recargarLista = () => {
    APIManager.current.getList('listasDePrecios', { comercioID: comercio.id }, (response) => {
      setItems(response.data.listado);
      setItemSeleccionado(null);
      setItemsSeleccionados([]);
    });
  }

  useEffect(() => {
    // Actualiza los valores del formulario
    if (!!listaDePrecios) {
      setNombre(listaDePrecios.nombre);
      setDescripcion(listaDePrecios.descripcion);
      setMoneda(listaDePrecios.moneda);
    }
  }, [listaDePrecios]);

  useEffect(() => {
    if (!!monedas && monedas.length > 0) {
      recargarLista();
    }
  }, [monedas]);

  useEffect(() => {
    setTitulo('Precios > ListasDePrecios',comercio);
    cargarMonedas();
  }, []);

  return (
    <>
      <div className="screen-title">
        <h2 >Listas de Precios</h2>
        <div className="screen-title__controls">
          <IconButton className="admin-button" label="Volver" iconName="arrow-left" onClick={() => history.goBack()} />
          <IconButton className="admin-button" label="Actualizar" iconName="sync" onClick={recargarLista} />
          <IconButton className="admin-button" label="Agregar" iconName="plus" onClick={nuevoItem} disabled={!!itemSeleccionado} />
        </div>
      </div>
      <ListaDeSeleccion className='items' columnas={columnas} items={items} itemSeleccionado={itemSeleccionado} itemsSeleccionados={itemsSeleccionados} disabledOnSeleccion={false}/>

    </>
  );
}

export default ListasDePrecios;