import { useEffect, useState } from 'react';
import ListaDeSeleccion from '../../components/ListaDeSeleccion';
import { useSelector } from 'react-redux';
import APIManager from '../../../../lib/apiManager';
import IconButton from '../../../../components/IconButton';

const Ubicaciones = ({ setTitulo }) => {


  const [items, setItems] = useState([]);

  const [itemsSeleccionados, setItemsSeleccionados] = useState([]);
  const [itemSeleccionado, setItemSeleccionado] = useState(null);

  const [errores, setErrores] = useState([]);

  const comercio = useSelector(state => state.admin.comercio);

  const [idQR, setIdQR] = useState(null);
  const [qrUbicacion, setQRUbicacion] = useState(null);

  const onItemChange = (item) => {
    if (!itemSeleccionado || (itemSeleccionado.id !== item.id)) {
      setItemSeleccionado(item);
      setItemsSeleccionados([item]);
    } else {
      setItemSeleccionado(null);
      setItemsSeleccionados([]);
    }

  }

  const cancelEdit = () => {
    recargarLista();
  }

  const guardarItem = (item) => {
    APIManager.current.sendRequest('comercio', 'modificarUbicacion', { ubicacion: item }, (response) => {
      if (response.success) {
        onFormularioClose();
        recargarLista();
      }
    });
  }

  const bajaItem = (item) => {
    if (window.confirm('Desea eliminar la ubicacion: "' + item.nombre + '" ?'))
    {
      APIManager.current.sendRequest('comercio', 'bajaDeUbicacion', { ubicacion: item }, (response) => {
        if (response.success) {
          onFormularioClose();
          recargarLista();
        }
      });
    }
  }

  const limpiarErrores = () => {
    setErrores([]);
  }

  const onFormularioClose = () => {
    if (!itemSeleccionado.id) {
      setItems(items.filter(item => !!item.id));
    }
    setItemSeleccionado(null);
    setItemsSeleccionados([]);
  }

  const getCodigo = (item) => {
    setQRUbicacion(null);
    APIManager.current.getUIData('getUbicacionCodigo', { ubicacionID: item.id }, (data) => {
      setIdQR(item.id);
      const url = window.location.origin + '/codigo/' + data;

      if (url) {
        navigator.clipboard.writeText(url).then(function() {
          alert('URL copiada')
        }, function() {
          alert('No se pudo copiar');
        });
      }
      setQRUbicacion(url);
    });
  }

  const changeNombre = (item, value) => {
    const nuevoItem = Object.assign({}, item);
    nuevoItem.nombre = value;
    setItems(items.map(viejoItem => (viejoItem.editable && (viejoItem.id === item.id)) ? nuevoItem : viejoItem));
    setItemSeleccionado(nuevoItem);
  }

  const changeDescripcion = (item, value) => {
    const nuevoItem = Object.assign({}, item);
    nuevoItem.descripcion = value;
    setItems(items.map(viejoItem => (viejoItem.editable && (viejoItem.id === item.id)) ? nuevoItem : viejoItem));
    setItemSeleccionado(nuevoItem);
  }

  const changeCodigo = (item, value) => {
    const nuevoItem = Object.assign({}, item);
    nuevoItem.codigo = value;
    setItems(items.map(viejoItem => (viejoItem.editable && (viejoItem.id === item.id)) ? nuevoItem : viejoItem));
    setItemSeleccionado(nuevoItem);
  }

  const changeActive = (item) => {
    if (item.editable) {
      const nuevoItem = Object.assign({}, item);
      nuevoItem.active = !item.active;
      setItems(items.map(viejoItem => (viejoItem.editable && (viejoItem.id === item.id)) ? nuevoItem : viejoItem));
      setItemSeleccionado(nuevoItem);
    }
  }

  const columnas = [
    { field: 'nombre', editable: true, title: 'Nombre', onChange: changeNombre },
    { field: 'descripcion', editable: true, title: 'Descripción', onChange: changeDescripcion },
    { field: 'codigo', editable: true, title: 'Código', onChange: changeCodigo },
    { field: 'active', editable: true, title: 'Activo', onChange: changeActive },
    {
      field: 'acciones', title: '', className: 'row-controls--block', component: (item) => {
        if (item.id && !item.editable) {
          return <>
            <IconButton className="admin-button" iconName="qrcode" label="URL para QR" onClick={() => getCodigo(item)} visible={(!itemSeleccionado || itemSeleccionado === item)} />
            <IconButton className="admin-button" iconName="edit" label="Editar" onClick={() => { item.editable = true; onItemChange(item)}} visible={(!itemSeleccionado || itemSeleccionado === item)} />
          </>
        } else {
          return <>
            <IconButton className="admin-button" iconName="check" label="Guardar" onClick={() => guardarItem(item)} />
            <IconButton className="admin-button" iconName="times" label="Cancelar" onClick={cancelEdit} />
            <IconButton className="admin-button" iconName="trash" label="Eliminar" onClick={() => bajaItem(item)} />
          </>
        }
      }
    },
  ];

  const recargarLista = (callback) => {
    APIManager.current.getList('ubicaciones', { comercioID: comercio.id, bajas: false, orderBy: 'id' }, (response) => {
      setItems(response.data.listado);
      setItemSeleccionado(null);
      setItemsSeleccionados([]);
    });
  };

  const nuevoItem = () => {
    const itemNuevo = {
      id: null,
      comercio: comercio,
      nombre: '',
      codigo: '',
      descripcion: '',
      active: false,
      editable: true,
    };

    setItems([...items, itemNuevo]);
    setItemSeleccionado(itemNuevo);
    setItemsSeleccionados([itemNuevo]);
  }

  useEffect(() => {
    setTitulo('Ubicaciones',comercio);
    recargarLista();
  }, []);

  return (
    <>
      <div className="screen-title">
        <h2>Lista de Ubicaciones</h2>
        <div className="screen-title__controls">
          <button onClick={recargarLista} >Actualizar</button>
          <button onClick={nuevoItem} disabled={!!itemSeleccionado && !itemSeleccionado.id}>Nueva ubicación</button>
        </div>
      </div>
      <ListaDeSeleccion className='items' columnas={columnas} items={items} itemSeleccionado={itemSeleccionado} itemsSeleccionados={itemsSeleccionados} disabledOnSeleccion={false}/>

    </>
  );
}

export default Ubicaciones;