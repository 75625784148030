import { useEffect, useState } from 'react';
import ListaDeSeleccion from '../../components/ListaDeSeleccion';
import { useSelector } from 'react-redux';
import APIManager from '../../../../lib/apiManager';
import IconButton from '../../../../components/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LinksQR = ({ setTitulo }) => {

  const comercio = useSelector(state => state.admin.comercio);
  const [productos, setProductos] = useState([]);
  const [tags, setTags] = useState([]);
  const [tagsSeleccionados, setTagsSeleccionados] = useState([]);
  const [etiqueta, setEtiqueta] = useState('');
  const [etiquetasSugeridas, setEtiquetasSugeridas] = useState([]);
  const [ubicaciones, setUbicaciones] = useState([]);
  const [ubicacionesSeleccionadas, setUbicacionesSeleccionadas] = useState([]);
  const [ubicacionSeleccionada, setUbicacionSeleccionada] = useState(null);
  const [productosFiltrados, setProductosFiltrados] = useState([]);

  const agregarEtiqueta = (item) => {
    setTagsSeleccionados([...tagsSeleccionados.filter(tag => tag.id !== item.id), item]);
  }

  const quitarEtiqueta = (item) => {
    setTagsSeleccionados(tagsSeleccionados.filter(tag => tag.id !== item.id));
  }

  const getCodigo = () => {
    APIManager.current.getUIData('getUbicacionCodigo', { ubicacionID: ubicacionSeleccionada.id, tags: tagsSeleccionados }, (data) => {
      const url = window.location.origin + '/codigo/' + data;

      if (url) {
        navigator.clipboard.writeText(url).then(function() {
          alert('URL copiada')
        }, function() {
          alert('No se pudo copiar');
        });
      }
    });
  }

  useEffect(() => {
    if (!!etiqueta) {
      APIManager.current.getList('tags', { nombre: etiqueta}, (response) => {
        setEtiquetasSugeridas(response.data.listado.filter(tag => !tags.find(element => element.id === tag.id)));
      });
    } else {
      setEtiquetasSugeridas(tags);
    }
  }, [etiqueta, tags]);

  const stringsSimilares = (stringA, stringB) => {
    return stringA.toLocaleUpperCase().replaceAll(' ', '').localeCompare(stringB.toLocaleUpperCase().replaceAll(' ', ''), undefined, { sensitivity: 'base' }) === 0;
  }

  const esNuevaEtiqueta = (tagName) => {
    return (!tags.find(item => stringsSimilares(tagName, item.nombre))
      && !etiquetasSugeridas.find(item => stringsSimilares(tagName, item.nombre)));
  }

  const cargarUbicaciones = (callback) => {
    APIManager.current.getList('ubicaciones', { comercioID: comercio.id, bajas: false, orderBy: 'id' }, (response) => {
      setUbicaciones(response.data.listado);
    });
  }

  const cargarProductos = (callback) => {
    APIManager.current.getList('productos', { comercioID: comercio.id, active: true, asArray: true }, (response) => {
      setProductos(response.data.listado);
    });
  }

  const cargarTags = (callback) => {
    APIManager.current.getList('tags', { comercioID: comercio.id, active: true, disponible: true }, (response) => {
      setTags(response.data.listado);
    });
  }

  const toggleFiltro = (filtro) => {
    if (tagsSeleccionados.includes(filtro)) {
      setTagsSeleccionados([...tagsSeleccionados.filter(item => item.id !== filtro.id)]);
    } else {
      setTagsSeleccionados([...tagsSeleccionados, filtro]);
    }
  }

  const columnasUbicaciones = [
    { field: 'nombre', title: 'Ubicacion' },
  ];

  const columnasProductos = [
    { field: 'nombre', title: 'Nombre' },
    // { field: 'descripcion', title: 'Descripcion' },
  ];

  const onUbicacionChange = (item) => {
    if (!ubicacionSeleccionada || (ubicacionSeleccionada.id !== item.id)) {
      setUbicacionSeleccionada(item);
      setUbicacionesSeleccionadas([item]);
    } else {
      setUbicacionSeleccionada(null);
      setUbicacionesSeleccionadas([]);
    }

  }

  useEffect(() => {
    if (productos) {
      // Filtramos los productos segun los tags activos
      // Ademas filtramos los que no figuran activos/disponibles
      const filtrados = productos.filter(producto =>
        producto.active && producto.disponible
        && (!tagsSeleccionados || (tagsSeleccionados.length === 0) || tagsSeleccionados.every(tag => {
          const matches = producto.tags.some(productoTag => productoTag.id === tag.id);
          return matches;
        }))
      );

      filtrados.sort((item1, item2) => item1.nombre.localeCompare(item2.nombre)); // Orden alfabetico

      setProductosFiltrados(filtrados);
    }
  }, [productos, tagsSeleccionados])

  useEffect(() => {
    setTitulo('Links / QR', comercio);
    cargarUbicaciones();
    cargarProductos();
    cargarTags();
  }, []);

  return (
    <div className="admin-linksQR">
      <div>
        <h2 className="form__title">Datos del Link / QR</h2>
      </div>
      <div className="form__row">
        <ListaDeSeleccion className="ubicaciones form__col" columnas={columnasUbicaciones} items={ubicaciones} itemsSeleccionados={ubicacionesSeleccionadas} itemSeleccionado={ubicacionSeleccionada} disabledOnSeleccion={false} onSelectionChange={ onUbicacionChange } />
        <div className="producto--tags">
          <label className='form__title etiquetas-title'>Etiquetas</label>
          <div className='tags__todos'>
            {tags && (tags.length > 0) && <div className={"filtros is--expanded has--active-filters"}>
              <ul className="filtros__list">
                {tags.map((tag) => {
                  const activo = tagsSeleccionados.find(item => item.id === tag.id);
                  // return activo ?
                  // (
                    return <li className={"filtros__item" + (activo ? ' is--activo' : '')} key={tag.id} >
                      <button onClick={() => toggleFiltro(tag)} className="control--filtro" >
                        <span className="button__label">
                          <FontAwesomeIcon className={"button__icon icon--" + (activo ? 'minus' : 'plus')} icon={["fas", (activo ? "minus" : "plus")]} />
                          {tag.nombre}
                        </span>
                      </button>
                    </li>
                  // ) : null
                  }
                )}
              </ul>
            </div>}
          </div>
        </div>
      </div>
      <div className='form__row'>
        {!!ubicacionSeleccionada
          ? <IconButton className="control--copiar admin-button" label="Copiar Link" onClick={getCodigo} />
          : <span className='warning'>Debe seleccionar una ubicación</span>
        }
      </div>
      <div className=''>
        <h3 className='form__title'>Productos filtrados por el link</h3>
        <ListaDeSeleccion className='productos' columnas={columnasProductos} items={productosFiltrados} />

      </div>
    </div>
  );


}

export default LinksQR;