import { useEffect, useState } from "react";
import APIManager from '../../../../../../lib/apiManager';


const Formulario = ({monedaSeleccionada, errores, setErrores, limpiarErrores, onClose, onSaveMoneda}) => {

  const [nombre, setNombre] = useState('');
  const [simbolo, setSimbolo] = useState('');
  const [descripcion, setDescripcion] = useState('');

  useEffect(() => {
    console.log('SELECCION :', monedaSeleccionada);

    limpiarErrores();

    if (monedaSeleccionada) {
      setNombre(monedaSeleccionada.nombre ? monedaSeleccionada.nombre : '');
      setSimbolo(monedaSeleccionada.simbolo ? monedaSeleccionada.simbolo : '');
      setDescripcion(monedaSeleccionada.descripcion ? monedaSeleccionada.descripcion : '');
    }
  },[monedaSeleccionada]);

  const guardarFormulario = (event) => {

    const erroresDelFormulario = [];

    if (!nombre) {
      console.log('nombre:', nombre);
      erroresDelFormulario.push({message: 'El nombre de la moneda no puede estar vacio'});
    }

    if (!simbolo) {
      console.log('simbolo', simbolo);
      erroresDelFormulario.push({message: 'El simbolo de la moneda no puede estar vacio'});
    }

    setErrores(erroresDelFormulario);

    if ((erroresDelFormulario.length === 0)) {
      const itemModificado = {};

      Object.assign(itemModificado, monedaSeleccionada);

      itemModificado.nombre = nombre;
      itemModificado.simbolo = simbolo;
      itemModificado.descripcion = descripcion;

      if (onSaveMoneda) {
        onSaveMoneda(itemModificado);
      }

    }
  }

  const cancelarFormulario = () => {
    if (onClose) {
      onClose();
    }
  }

  return (
    <div className="formulario">
      <div>
        <h2>Datos de la Moneda</h2>
        <div>
          <label>ISO Nombre</label>
          <input type='text' value={nombre} onChange={(event) => setNombre(event.target.value)}/>
        </div>
        <div>
          <label>Simbolo</label>
          <input type='text' value={simbolo} onChange={(event) => setSimbolo(event.target.value)}/>
        </div>
        <div>
          <label>Descripción</label>
          <input type='text' value={descripcion} onChange={(event) => setDescripcion(event.target.value)}/>
        </div>
      </div>
      <div>
        {!monedaSeleccionada.id && <button onClick={guardarFormulario}>Crear Moneda</button>}
        {monedaSeleccionada.id && <button onClick={guardarFormulario}>Guardar</button>}
        <button onClick={cancelarFormulario}>Cancelar</button>
      </div>
    </div>
  )

}

export default Formulario;