import { Route, Switch } from "react-router-dom";
import CodigoTest from "../CodigoTest";
import Login from '../Login';
import Logout from '../Logout';
import Signup from '../Signup';

const MainLayout = () => {
  return (
    <Switch>
      <Route path="/login" render={(myProps) => <Login {...myProps} />} />
      <Route path="/signup" render={(myProps) => <Signup {...myProps} />} />
      <Route path="/logout" render={(myProps) => <Logout {...myProps} />} />
      <Route path="/codigo/:codigo" render={(myProps) => <CodigoTest />} />
    </Switch>
  );
}

export default MainLayout;