import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';
import AdminNavegacion from './components/Navegacion';
import APIManager from '../../lib/apiManager';

import Comercios from './screens/Comercios';
import Dashboard from './screens/Dashboard';
import Monedas from './screens/Monedas';
import Tags from './screens/Tags';
// import Usuarios from './screens/Usuarios';
import EstadosDePedido from './screens/EstadosDePedido';
import Disponibles from './screens/Disponibles';
import Pedidos from './screens/Pedidos';
import Staff from './screens/Staff';
import Productos from './screens/Productos';
import Ubicaciones from './screens/Ubicaciones';
import Comercio from './screens/Comercio';
import Precios from './screens/Precios';
import Recepcion from './screens/Recepcion';
import ListasDePrecios from './screens/ListasDePrecios';
import LinksQR from './screens/LinksQR';
import Miembros from './screens/Miembros';
import Agrupaciones from './screens/Agrupaciones';

const Admin = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const adminMenu = useSelector(state => state.admin.menu);

  const usuario = useSelector(state => state.sistema.usuario);
  const [notificaciones, setNotificaciones] = useState({ visibles: false, lista: [] });
  const [titulo, setTitulo] = useState('Admin');
  const [comercioTitulo, setComercioTitulo] = useState(null);

  const comercio = useSelector(state => state.admin.comercio);

  const components = {
    Dashboard: Dashboard,
    Comercios: Comercios,
    Monedas: Monedas,
    Tags: Tags,
    EstadosDePedido: EstadosDePedido,
    Disponibles: Disponibles,
    Pedidos: Pedidos,
    Staff: Staff,
    Productos: Productos,
    Ubicaciones: Ubicaciones,
    Comercio: Comercio,
    Precios: Precios,
    Recepcion: Recepcion,
    ListasDePrecios: ListasDePrecios,
    LinksQR: LinksQR,
    Miembros: Miembros,
    Agrupaciones: Agrupaciones,
  }

  const ocultarNotificaciones = () => {
    setNotificaciones({...notificaciones, visibles: false});
  }

  const agregarNotificaciones = (nuevasNotificaciones) => {
    setNotificaciones({...notificaciones, visibles: true, lista: [...notificaciones.lista, ...nuevasNotificaciones]});
  }

  const quitarNotificaciones = (notificacionesAQuitar) => {
    setNotificaciones({...notificaciones, lista: notificaciones.lista.filter(notificacion => !notificacionesAQuitar.includes(notificacion))});
  }

  const limpiarNoficaciones = () => {
    setNotificaciones({visibles: false, lista: []});
  }

  const cambiarNotificaciones = (nuevasNotificaciones) => {
    setNotificaciones({...notificaciones, visibles: true, lista: [...nuevasNotificaciones]});
  }

  const limpiarPaginaActual = () => {
    dispatch({'type': 'sistema/setPaginaActual', payload: null});
  }

  const setTituloComponent = (titulo, comercio) => {
    setTitulo(titulo);
    setComercioTitulo(comercio);
;
  }

  const actualizarMenu = (comercio) => {
    dispatch({ type: 'admin/setComercio', payload: comercio });
    APIManager.current.getUIData('getAdminMenu', { comercio: comercio }, (data) => {
      dispatch({ type: 'admin/setMenu', payload: data });
      if (!(data.length > 0)) {
        // No hay acceso a admin de este comercio, salir del admin
        APIManager.current.navigate('/admin/recepcion');
      }
    }, (response) => {
      // Error de acceso, salir del admin
      dispatch({ type: 'admin/setComercio', payload: null });
      APIManager.current.navigate('/recepcion');
    });
  }

  useEffect(() => {
    dispatch({ type: 'admin/setItemActual', payload: null });
    dispatch({ type: 'admin/setItemsSeleccionados', payload: [] });
    if (!usuario) {
      history.push('/login');
    } else {
      // Obtenemos menu de administracion
      actualizarMenu(comercio);
    }
  }, []);

  return (
    <div className="screen--admin">
      <AdminNavegacion actualizarMenu={ actualizarMenu } />
      <div className="admin-contenido">
        <div className="admin-title">
          {comercioTitulo && comercioTitulo.nombre && <span className="admin-title__comercio">{comercioTitulo.nombre + ' > '}</span>}
          <span className="admin-title__title">{ titulo }</span>
        </div>
        <Switch>
          { // Rutas dinamicas desde el menu de administracion con componentes dinamicos
            adminMenu.map((item, index) => {
              return components[item.component]
                ? <Route key={index}
                  path={'/admin/' + item.seccion}
                  render={() => {
                    const ComponentName = components[item.component];
                    return <ComponentName
                      setTitulo={setTituloComponent}
                      errores={notificaciones.lista}
                      setErrores={cambiarNotificaciones} limpiarErrores={limpiarNoficaciones} />
                  }}
                />
                : null
            })
          }
          <Route path='/admin/listasDePrecios' render={() => <ListasDePrecios setTitulo={setTituloComponent} errores={notificaciones.lista} setErrores={cambiarNotificaciones} limpiarErrores={limpiarNoficaciones} />} />
          {(adminMenu.length > 0) && <Route
            // Ruta default a la primera opcion del menu
            render={() => {
              history.replace('/admin/' + adminMenu[0].seccion);
              return <></>
            }}
          />}
        </Switch>

        {notificaciones.visibles && notificaciones.lista.length > 0 &&
          <div className="notificaciones-seccion">
            <ul>
            {notificaciones.lista.map((item, index) =>
              <li key={index}>
                <span>{item.message}</span>
              </li>
            )}
            </ul>

            <button onClick={ocultarNotificaciones}>X</button>
          </div>
        }

      </div>
    </div>
  );
}

export default Admin;