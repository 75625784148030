import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ListadoDePedidos from '../../components/ListadoDePedidos';
import APIManager from '../../lib/apiManager';
import { useInterval } from '../../lib/useInterval';

const Historial = () => {
  const dispatch = useDispatch();

  const comercio = useSelector(state => state.sistema.comercio);
  const ubicacion = useSelector(state => state.sistema.ubicacion);
  const pedidos = useSelector(state => state.pedidos);

  const actualizarPedidos = () => {

    if (comercio) {
      APIManager.getList('pedidos', { comercioID: comercio.id, orderBy: 'id desc', active: true, conProductos: true, asArray: true }, (response) => {
        dispatch({ type: 'pedidos/set', payload: response.data.listado });
      });
    }
  }

  const actualizarEstadosDePedido = () => {
    if (comercio) {
      APIManager.getList('estadosDePedido', { comercioID: comercio.id }, (response) => {
        dispatch({ type: 'sistema/setEstadosDePedido', payload: response.data.listado });
      })
    }
  }

  useEffect(() => {
    actualizarPedidos();
    actualizarEstadosDePedido();
  }, [comercio, dispatch]);

  useInterval(() => {
    // Actualiza la lista de pedidos periodicamente
    actualizarPedidos();
  }, 5 * 60 * 1000); // periodo de 5 minutos

  return (
    <div className="layout--base">
      <div className="test-bar">
        <div className="session-information__location">
          {comercio && comercio.active ?
            <div className="location">
              <span className="location__business">
                <FontAwesomeIcon className="icon--map-marker" icon={["fas", "map-marker-alt"]} />
                <span className="location__business--name">{ comercio.nombre }</span>
              </span>
              <span className="location__seating">
                {ubicacion ? 'Ubicacion: ' + ubicacion.nombre : ''}
              </span>
            </div> : <></>}
        </div>
      </div>
      <div className={"main-container"} >
        {!!comercio &&
          <h1 className="historial__title">Pedidos En {comercio.nombre}</h1>
        }
        <ListadoDePedidos pedidos={pedidos} />
      </div>
    </div>
  );
}

export default Historial;