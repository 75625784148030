import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TarjetaDeComercio = ({className, nombre, descripcion, logo, onClick}) => {
  return (
    <div className={"comercio__card" + (!!className ? ' ' + className : '')} onClick={onClick}>
      <div className="logo" style={!!logo ? { backgroundImage: 'url("' + logo + '")' } : {}}>
        <FontAwesomeIcon className="plus-icon" icon="plus" />
      </div>
      <div className="info">
        <span className="nombre">{nombre}</span>
        <p className="descripcion">{descripcion}</p>
      </div>
    </div>
  );
}

export default TarjetaDeComercio;