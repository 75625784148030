import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import IconButton from '../IconButton';
import Notificacion from './components';
import APIManager from '../../lib/apiManager';

const Notificaciones = ({ tiposDeNotificacionVisibles }) => {

  const dispatch = useDispatch();

  const notificaciones = useSelector(state => state.notificaciones);
  const [visible, setVisible] = useState(false);

  const dismiss = () => {
    dispatch({ type: 'notificaciones/dismiss' });
    setVisible(false);
  }

  useEffect(() => {
    let mostrar = false;
    notificaciones.lista.forEach(notificacion => {
      if ((notificacion.medio === 'LOG') && notificaciones.hayNuevas) {
        mostrar = true;
      }
    });
    if (mostrar) {
      setVisible(true);
    }
  }, [notificaciones]);

  return (
    <div className={"notificaciones" + (visible ? ' visible' : ' invisible')}>
      {!!notificaciones && visible && <>
        <ul className="notificaciones__list">
          {(notificaciones.lista.length > 0) && notificaciones.hayNuevas &&
            notificaciones.lista.map((notificacion, index) => {
              return !!notificacion.nueva &&
                <li key={index} className="notificaciones__item">
                  <Notificacion notificacion={notificacion} />
                </li>
            }
          )}
        </ul>
        <IconButton className="notificaciones__dismiss-control" onClick={dismiss} label="Entendido!" iconName="check"></IconButton>
      </>
      }
    </div>
  );
}

export default Notificaciones;