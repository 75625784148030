import IconButton from '../IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MainBar = ({ navMenuToggle, className, setScanQR }) => {

  const showQRScan = () => {
    if (setScanQR) {
      setScanQR(true);
    }
  }


  return (
    <div className={"layout--app-bar" + (className ? ' ' + className : '')}>

      <div className="app-bar__controls">
        <button className="control--settings" onClick={navMenuToggle} >
          <FontAwesomeIcon className="button__icon icon--bars" icon={["fas", "bars"]} />
          <span className="button__label">Menu</span>
        </button>
        <span className="app-bar__logo pibun-logo">Pibun</span>
        <IconButton className="control--settings qrcode" iconName="qrcode" onClick={showQRScan} />
      </div>
    </div>
  );
}

export default MainBar;