import React, { useEffect, useState } from 'react';

import TarjetaDeProducto from '../../components/TarjetaDeProducto';
import TarjetaDeProductoEnCarrito from '../TarjetaDeProductoEnCarrito';

const ListadoDeProductos = ({productos, viendoPedido, productoAgrandado, setProductoAgrandado, agregarProducto, quitarProducto}) => {
  const [tarjetaRef, setTarjetaRef] = useState(null);

  const setEstaAgrandado = (producto, agrandado) => {
    agrandado ? setProductoAgrandado(producto) : setProductoAgrandado(null)
  }

  useEffect(() => {
    if (!!productoAgrandado && !!tarjetaRef.current && !!tarjetaRef.current.scrollIntoView) {
      // Scroll al nuevo producto agrandado
      tarjetaRef.current.scrollIntoView();

      if (productoAgrandado !== productos[productos.length - 1]) {
        // No es el ultimo, scrollear hacia arriba para tener centrado el producto
        window.scrollBy(0, -150);
      }
    }
  }, [productoAgrandado]);

  return (
    <ol className="listado-de-productos">
      {productos.map((producto) =>
        <li key={producto.id}>
          { viendoPedido()
          ? <TarjetaDeProductoEnCarrito {...producto}
            agregarProducto={() => agregarProducto(producto)}
            restarProducto={() => quitarProducto(producto)}
            quitarProducto={() => quitarProducto(producto, true)}
          />
          : <TarjetaDeProducto {...producto} estaAgrandado={!!productoAgrandado && (producto.id === productoAgrandado.id)} setEstaAgrandado={(agrandado) => setEstaAgrandado(producto, agrandado)} setMyRef={setTarjetaRef}
            agregarProducto={() => agregarProducto(producto)}
            restarProducto={() => quitarProducto(producto)}
            quitarProducto={() => quitarProducto(producto, true)}
          />}
        </li>
      )}
    </ol>
  );
}

export default ListadoDeProductos