import react, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import APIManager from '../../../../lib/apiManager';
import ListaDeSeleccion from '../../components/ListaDeSeleccion';
import Formulario from './components/Formulario';

const Comercios = ({setTitulo, errores, setErrores, limpiarErrores}) => {

  const dispatch = useDispatch();

  const comerciosSeleccionados = useSelector(state => state.admin.itemsSeleccionados);
  const comercioSeleccionado = useSelector(state => state.admin.itemActual);

  const [comercios, setComercios] = useState([]);

  const [comercioAEditar, setComercioAEditar] = useState(null);

  const columnas = [
    {field: 'nombre', title: 'Comercio'},
    {field: 'codigo', title: 'Codigo'},
    {field: 'active', title: 'Activo'},
  ];

  const recargarLista = (callback) => {
    APIManager.getList('comercios', {}, (response) => {
      // Si los comercios seleccionados NO estan en el listado, los elimino de la seleccion.

      const listadoFiltrado = response.data.listado.filter(
        item => comerciosSeleccionados.find(
          comercio => comercio.id === item.id
          )
        );

      if(comercioSeleccionado && !listadoFiltrado.find(item => item.id === comercioSeleccionado.id)) {
        limpiarItemActual();
      };

      dispatch({
        type: 'admin/setItemsSeleccionados',
        payload: listadoFiltrado
      });

      setComercios(response.data.listado);
      callback && callback();
    });
  };

  useEffect(recargarLista,[]);

  useEffect(() => {
    if (comercioAEditar && comerciosSeleccionados.find(item => comercioAEditar === item)) {
      setItemActual(comercioAEditar);
    }
  },[comercioAEditar, comerciosSeleccionados]);

  const comercioChange = (comercio) => {
    if (!comercioSeleccionado) {
      if (comerciosSeleccionados && comerciosSeleccionados.find(item => item.id === comercio.id)) {
        dispatch({type: 'admin/setItemsSeleccionados', payload: []});
      } else {
        dispatch({type: 'admin/setItemsSeleccionados', payload: [comercio]});
      }
    }
  }

  const limpiarItemActual = () => {
    setItemActual(null);
    setComercioAEditar(null);
  }

  const setItemActual = (item) => {
    dispatch({ type: 'admin/setItemActual', payload: item });
  }


  const modificarComercio = () => {

    APIManager.sendRequest('post', 'comercio', 'getComercio', { comercioID: comerciosSeleccionados[0].id }, (response) => {
      if (response.success) {
        // Levantamos los datos del comercio
        setItemActual(response.data);
      }
    });

  }

  const nuevoComercio = () => {
    const itemNuevo = {
      "id": null,
      "codigo": "",
      "nombre": "",
      "descripcion": "",
      "direccion": "",
      "active": null,
      "fecha_alta": null,
      "staff" : [{id: null, active: true, rol: 'ROL_ADMINISTRADOR', usuario: {id: null, nombres: '', apellidos: '', email: ''}}]
    };

    setComercios([...comercios, itemNuevo]);
    dispatch({type: 'admin/setItemsSeleccionados', payload: [itemNuevo]});
    setComercioAEditar(itemNuevo);
  }

  const activarComercios = () => {
    // TODO GRABAR
    APIManager.sendRequest('post', 'comercio', 'activarComercios', {comercios: comerciosSeleccionados}, (response) => {
      if (response.success) {
        limpiarItemActual();
        recargarLista();
      }
    });
  }

  const desactivarComercios = () => {
    // TODO GRABAR
    APIManager.sendRequest('post', 'comercio', 'desactivarComercios', {comercios: comerciosSeleccionados}, (response) => {
      if (response.success) {
        limpiarItemActual();
        recargarLista();
      }
    });
  }

  const guardarComercio = (itemModificado, administrador) => {
    APIManager.sendRequest('post', 'comercio', itemModificado.id ? 'modificarComercio' : 'nuevoComercio', {comercio: itemModificado, administrador}, (response) => {
      if (response.success) {
        limpiarItemActual();
        recargarLista();
        limpiarErrores();
      } else {
        setErrores(response.errors);
        return;
      }
    });
  }

  const onFormularioClose = () => {
    limpiarErrores();
    limpiarItemActual();
    recargarLista();
  }

  setTitulo('Comercios');

  return (
    <>
      <h2 className="screen-title">Lista de Comercios</h2>
      <div>
        <ListaDeSeleccion className='comercios' columnas={columnas} items={comercios} itemSeleccionado={comercioSeleccionado} itemsSeleccionados={comerciosSeleccionados} onSelectionChange={comercioChange} />
        {comerciosSeleccionados &&
        <div className="controles">
          <button onClick={nuevoComercio} disabled={!!comercioSeleccionado}>Agregar Comercio</button>
          <button onClick={modificarComercio} disabled={comercioSeleccionado || !(comerciosSeleccionados.length === 1)} >Modificar Comercio</button>
          <button onClick={activarComercios} disabled={comercioSeleccionado || !comerciosSeleccionados.find(comercio => !comercio.active)}>Activar Comercio</button>
          <button onClick={desactivarComercios} disabled={comercioSeleccionado || !comerciosSeleccionados.find(comercio => comercio.active)}>Desactivar Comercio</button>
        </div>}
        {comercioSeleccionado &&
        <Formulario comercioSeleccionado={comercioSeleccionado} errores={errores} setErrores={setErrores} limpiarErrores={limpiarErrores} onClose={onFormularioClose} onSaveComercio={guardarComercio} />
        }
      </div>
    </>
  );
}

export default Comercios;