import QrReader from 'react-qr-reader';
import APIManager from '../../lib/apiManager';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '../../components/IconButton';

const QRScanner = ({ onQRScan, active, setActive }) => {

  const dispatch = useDispatch();
  const comercioAnterior = useSelector(state => state.sistema.comercio);
  const ultimoComercio = useSelector(state => state.sistema.comercio);
  const monedaPreferida = useSelector(state => state.sistema.monedaPreferida);

  const setComercio = (comercio) => {
    if (!comercioAnterior || ((comercio.id !== comercioAnterior.id) && window.confirm('Si estabas armando un pedido, ser perderá'))) {
      dispatch({ type: 'sistema/setComercio', payload: comercio });
      dispatch({ type: 'productos/set', payload: [] });
      dispatch({ type: 'productos/reset'});
    }
  }

  const handleCodigo = codigo => {
    if (!!codigo) {
      if (codigo.includes('/codigo/')) {
        // El codigo contiene la URL, quitamos toda la info del origen
        codigo = codigo.substr(codigo.indexOf('/codigo/') + 8);
      }
      APIManager.current.getUIData('getUbicacion', { codigo: codigo }, (data) => {
        // Vemos si los parametros coinciden con algun comercio/ubicacion
        console.log('ubicacion:', data);
        if (!!data && !!data.ubicacion && !!data.ubicacion.id) {
          // Si la ubicacion no esta active llevamos al comercio
          if (!data.ubicacion.active) {
            APIManager.current.dispatch({ type: 'sistema/setUbicacion', payload: null });
            if (!data.ubicacion.comercio.active) {
              // El comercio tampoco esta active, llevamos a la home
              setComercio(null);
              APIManager.navigate('recepcion');
            } else {
              // Seleccionamos el comercio, pero no la ubicacion
              setComercio(data.ubicacion.comercio);
              if (!!data.tags) {
                APIManager.current.dispatch({ type: 'sistema/setFiltros', payload: data.tags });
              }
              APIManager.navigate('carta');
            }
          } else {
            APIManager.current.dispatch({ type: 'sistema/setUbicacion', payload: data.ubicacion });
            if (!ultimoComercio || (data.ubicacion.comercio.id !== ultimoComercio.id)) {
              // Cambiamos de comercio, reset de monedaUltima
              APIManager.current.dispatch({ type: 'sistema/setMonedaUltima', payload: monedaPreferida });
            }
            setComercio(data.ubicacion.comercio);
            if (!!data.tags) {
              APIManager.current.dispatch({ type: 'sistema/setFiltros', payload: data.tags });
            }
            APIManager.current.navigate('carta');
          }
        } else {
          // Si no coincide con nada, llevamos a la home (o cartel de error)
          setComercio(null);
          APIManager.current.dispatch({ type: 'sistema/setUbicacion', payload: null });
          APIManager.current.navigate('recepcion');
        }

      });
    }
  }

  const handleQRScan = codigo => {
    if (codigo) {
      setActive(false);
      if (onQRScan) {
        onQRScan(codigo);
      } else {
        handleCodigo(codigo);
      }
    }
  }

  const handleQRError = err => {
    console.error(err)
  }

  return (
    <div className={"qr-reader" + (active ? " active" : "")}>
      {active && <>
        {/* <button onClick={() => setActive(false)}>Cancelar</button> */}
        <QrReader
          className="qr-reader--scanner"
          delay={300}
          onError={handleQRError}
          onScan={handleQRScan}
        />
        <IconButton className="qr-reader__control--cancelar" iconName="times" label="Cancelar" iconClassName="icon-position--right" onClick={() => setActive(false) } />
      </>}
    </div>
  );
}

export default QRScanner;