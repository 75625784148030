import React, { useEffect, useState } from 'react';

const Modal = ({className, ...props}) => {
  return (
    <div className={"layout--modal" + (className ? ' ' + className : '')}>
      <div className="layout--modal--card">
          {props.children}
        </div>
      </div>
  );
}

export default Modal;