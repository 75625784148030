import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ConfirmarCTA from '../../components/ConfirmarCTA';
import APIManager from '../../lib/apiManager';
import { useHistory } from 'react-router';
import ListadoDeProductos from '../../components/ListadoDeProductos';
import ConfirmarPedidoCTA from '../../components/ConfirmarPedidoCTA';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TestFede = () => {
  const history = useHistory();
  const productos = useSelector(state => state.productos);
  const cliente = useSelector(state => state.sistema.cliente);
  const ubicacion = useSelector(state => state.sistema.ubicacion);
  const comercio = useSelector(state => state.sistema.comercio);
  const monedas = useSelector(state => state.sistema.monedas);
  const listasDePrecios = useSelector(state => state.sistema.listasDePrecios);
  const usuario = useSelector(state => state.sistema.usuario);

  const [listaDePrecios, setListaDePrecios] = useState(null);
  const [itemsEnPedido, setItemsEnPedido] = useState(0);
  const [precioDelPedido, setPrecioDelPedido] = useState(0);
  const [moneda, setMoneda] = useState(monedas && (monedas.length > 0) ? monedas[0] : null);

  const dispatch = useDispatch();

  const enviarPedido = () => {
    if (ubicacion) {
      if ((itemsEnPedido > 0)) {
        const pedido = {
          estado: 'nuevo',
          cliente: cliente,
          ubicacion: ubicacion,
          productos: productos.filter(item => item.cantidad > 0),
          precio: precioDelPedido
        };
        APIManager.sendRequest('post', 'pedidos', 'nuevoPedido', { pedido }, ( response ) => {
          if (response.success) {
            dispatch({ type: 'productos/reset' });
          }
        })
      } else {
        alert('seleccionar productos');
      }
    } else {
      alert('seleccionar ubicacion');
    }
  }

  useEffect(() => {
    if (!moneda && monedas && (monedas.length > 0)) {
      setMoneda(monedas[0]);
    }
  }, [monedas, moneda]);

  useEffect(() => {
    let cantidad = 0;
    let precio = 0;
    productos.forEach(item => {
      cantidad += item.cantidad;
      precio += item.precio * item.cantidad;
    });
    setItemsEnPedido(cantidad);
    setPrecioDelPedido(precio);
  }, [productos]);

  useEffect(() => {
    if (!!moneda && listasDePrecios && listasDePrecios.length > 0) {
      const lista = listasDePrecios.find(item => item.idmonedas === moneda.idmonedas);
      setListaDePrecios(lista);
    }
  }, [moneda, listasDePrecios]);

  return (
    <>
      <div className="layout--app-bar">

        <div className="app-bar__controls">
          <button className="control--settings">
            <FontAwesomeIcon className="button__icon icon--bars" icon={["fas", "bars"]} />
            <span className="button__label">Menu</span>
          </button>

          {/*
          <div className="wrapper--currency-selector"
            id="wrapper--currency-selector">
            <label
              className="label--currency-selector"
              for="control--currency-selector">
                <span>Moneda</span>
                <FontAwesomeIcon className="label__icon icon--money-bill" icon={["fas", "money-bill-wave-alt"]} />
            </label>
            <div className="wrapper--control--currency-selector">
              <select
                className="control--currency-selector"
                id="control--currency-selector"
                onChange={(event) => {setMoneda(monedas[event.target.value])}}>
                {monedas && listasDePrecios && monedas.map((moneda, index) => (
                  <option key={index} value={index}>
                    {moneda.nombre}
                  </option>
                ))}
              </select>
            </div>
          </div>
          */}

        </div>
      </div>

      <div className="layout--base">
        <div className="test-bar">
          <div className="session-information__location">
            {comercio && comercio.active ?
              <div className="location">
                <span className="location__business">
                  <FontAwesomeIcon className="icon--map-marker" icon={["fas", "map-marker-alt"]} />
                  <span className="location__business--name">{ comercio.nombre }</span>
                </span>
                <span className="location__seating">
                  {ubicacion ? 'Ubicacion: ' + ubicacion.nombre : ''}
                </span>
              </div> : <></>}
          </div>

          <button className="control--filters">
            <span className="button__label">Filtros</span>
            <FontAwesomeIcon className="button__icon icon--filter" icon={["fas", "filter"]} />
          </button>
        </div>
        <ConfirmarPedidoCTA productos={itemsEnPedido} precio={precioDelPedido} label="Confirmar pedido" onClick={enviarPedido} visible={itemsEnPedido > 0} />
        <div className="main-container">
          <section className="section--precios">
            {listaDePrecios && <ListadoDeProductos listaDePrecios={ listaDePrecios }/>}
          </section>
        </div>
      </div>
    </>
  );
}

export default TestFede;