import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import APIManager from '../../../../lib/apiManager';
import ListaDeSeleccion from '../../components/ListaDeSeleccion';
import { formatoDeFecha } from '../../../../lib/format';
import IconButton from '../../../../components/IconButton';

const Staff = ({ setTitulo }) => {
    /* Dar de alta nuevo staff (por invitacion con codigo) */
    /* Administrar el staff dado de alta (baja / cambio de rol) */

  // const dispatch = useDispatch();

  const [items, setItems] = useState([]);

  const [itemsSeleccionados, setItemsSeleccionados] = useState([]);
  const [itemSeleccionado, setItemSeleccionado] = useState(null);

  const comercio = useSelector(state => state.admin.comercio);

  const changeRol = (item, value) => {
    const nuevoItem = Object.assign({}, item);
    nuevoItem.rol = value;
    setItems(items.map(viejoItem => (viejoItem.editable && (viejoItem.id === item.id)) ? nuevoItem : viejoItem));
    setItemSeleccionado(nuevoItem);
  }

  const changeNombre = (item, value) => {
    const nuevoItem = Object.assign({}, item);
    nuevoItem.nombre = value;
    setItems(items.map(viejoItem => (viejoItem.editable && (viejoItem.id === item.id)) ? nuevoItem : viejoItem));
    setItemSeleccionado(nuevoItem);
  }

  const changeEmail = (item, value) => {
    const nuevoItem = Object.assign({}, item);
    nuevoItem.usuario = Object.assign({}, item.usuario);
    nuevoItem.usuario.email = value;
    setItems(items.map(viejoItem => (viejoItem.editable && (viejoItem.id === item.id)) ? nuevoItem : viejoItem));
    setItemSeleccionado(nuevoItem);
  }

  const columnas = [
    { field: 'nombre', title: 'Nombre', editable: true, placeholder: 'Ingrese el nombre', onChange: changeNombre },
    { field: 'usuario', subfield: 'email', title: 'Email', editable: true, placeholder: 'Ingrese el email', onChange: changeEmail},
    { field: 'rol', title: 'Rol', editable: true, select: { options: [{ value: 'ROL_ADMINISTRADOR', text: 'Administrador' }, {value: 'ROL_GESTION', text: 'Gestion'}]}, onChange: changeRol },
    { field: 'active', title: 'Activo' },
    { field: 'tipo', title: 'Estado'},
    {
      field: 'acciones', title: 'Acciones', component: (item) => {
        return (
          <div>
            {!!item.active && <IconButton className="admin-button" iconName="trash" onClick={() => bajaDeStaff(item)} />}
            {!!item.editable && <button className="admin-button" onClick={generarInvitacion}>Generar invitacion</button>}
            {(item.tipo === 'INVITADO') && <button className="admin-button" onClick={() => cancelarInvitacion(item)}>Cancelar invitacion</button>}
          </div>
        )
      }
    }
  ];

  const bajaDeStaff = (item) => {
    const data = {
      comercio: comercio,
      staff: item,
    }
    APIManager.current.sendRequest('staff', 'bajaDeStaff', data, (response) => {
      recargarLista();
    });
  }

  const cancelarInvitacion = (item) => {
    const invitacion = {
      id: item.id,
      comercio: comercio,
      staff: item,
    }
    APIManager.current.sendRequest('staff', 'cancelarInvitacion', { invitacion }, (response) => {
      recargarLista();
    });
  }

  const generarInvitacion = () => {
    const invitacion = {
      comercio: comercio,
      staff: itemSeleccionado,
    }
    APIManager.current.sendRequest('staff', 'generarInvitacion', { invitacion }, (response) => {
      recargarLista();
      alert('Invitación generada con el codigo: ' + response.data.codigo);
    });
  }

  const recargarLista = (callback) => {

    const queryData = {
      comercioID: comercio.id,
      orderBy: 'rol, apellidos, nombres',
      asArray: true,
      active: true,
    }

    APIManager.current.getList('staff', queryData, (response) => {
      setItems(response.data.listado);
      setItemSeleccionado(null);
      setItemsSeleccionados([]);
    });
  };


  const nuevoStaff = () => {
    const itemNuevo = {
      "id": null,
      "idstaff": null,
      "nombre": "",
      "usuario": { id: null, email: "", nombres: "", apellidos: "" },
      "rol": "ROL_GESTION",
      "active": false,
      "fecha_alta": null,
      "editable": true,
      "tipo": 'INVITADO',
    };

    setItems([...items, itemNuevo]);
    // dispatch({type: 'admin/setItemsSeleccionados', payload: [itemNuevo]});
    setItemSeleccionado(itemNuevo);
    setItemsSeleccionados([itemNuevo]);
  }

  const onItemChange = (item) => {
    setItemSeleccionado(item);
    setItemsSeleccionados([item]);
  }

  useEffect(() => {
    setTitulo('Staff', comercio);
    recargarLista();
  }, []);

  return (
    <>
      <h2 className="screen-title">Miembros del Staff</h2>
      <button onClick={recargarLista}>Actualizar</button>
      <button onClick={nuevoStaff} disabled={!!itemSeleccionado && !itemSeleccionado.id}>Invitar staff</button>

      <ListaDeSeleccion className='staff' columnas={columnas} items={items} itemSeleccionado={itemSeleccionado} itemsSeleccionados={itemsSeleccionados} onSelectionChange={onItemChange} disabledOnSeleccion={false} />
    </>
  );
}

export default Staff;