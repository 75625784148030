import React, { useState } from 'react';
import APIManager from '../../lib/apiManager';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import IconButton from '../../components/IconButton';

const Login = () => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);

  const history = useHistory();

  const loginUsuario = () => {
    APIManager.sendRequest('post', 'usuario', 'login', {username, password}, (response) => {
      if (response.success) {
        dispatch({ type: 'sistema/setUsuario', payload: response.data.usuario });
        dispatch({ type: 'sistema/setCliente', payload: response.data.cliente });
        // if (typeof response.data.miembro !== 'undefined') {
        //   dispatch({ type: 'sistema/setMiembro', payload: response.data.miembro });
        // }
        if (response.sistema && response.sistema.adminMenu) {
          dispatch({ 'type': 'admin/setMenu', payload: response.sistema.adminMenu });
        }
        history.goBack();
      } else {
        alert(response.errors[0].message);
      }
    });
  }

  const changeUsername = (event) => {
    setUsername(event.target.value);
  };

  const changePassword = (event) => {
    setPassword(event.target.value);
  };

  const registrarme = (event) => {
    history.push('/signup');
  }

  return (
    <div className="login-page">
      <div className='login-form'>
        <h2 className='login-title pibun-logo'>Pibun</h2>
        <p className='login-subtitle'>Inicio de sesión</p>
        <div className="form__row">
          <div className="input-combo username">
            <input className={username ? 'has-value' : ''} type='text' maxLength={100} value={username} onChange={changeUsername} />
            <label>email</label>
          </div>
        </div>
        <div className="form__row">
          <div className="input-combo password">
            <input className={password ? 'has-value' : ''} type='password' value={password} onChange={changePassword}/>
            <label>Clave</label>
          </div>
        </div>
        <div className="form__row">
          <IconButton className="admin-button login-button" label="Iniciar" onClick={loginUsuario} />
          <IconButton className="admin-button login-button" label="Registrarme" onClick={registrarme} />
        </div>
      </div>
    </div>
  );
}

export default Login;