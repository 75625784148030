import { useEffect, useState } from "react";
import ListaDeSeleccion from '../../components/ListaDeSeleccion';
import { useSelector } from 'react-redux';
import APIManager from '../../../../lib/apiManager';
import IconButton from '../../../../components/IconButton';

const Miembros = ({ setTitulo }) => {

  const [items, setItems] = useState([]);

  const [itemsSeleccionados, setItemsSeleccionados] = useState([]);
  const [itemSeleccionado, setItemSeleccionado] = useState(null);

  const comercio = useSelector(state => state.admin.comercio);

  const changeNombre = (item, value) => {
    const nuevoItem = Object.assign({}, item);
    nuevoItem.nombre = value;
    setItems(items.map(viejoItem => (viejoItem.editable && (viejoItem.id === item.id)) ? nuevoItem : viejoItem));
    setItemSeleccionado(nuevoItem);
  }

  const changeEmail = (item, value) => {
    const nuevoItem = Object.assign({}, item);
    nuevoItem.email = value;
    setItems(items.map(viejoItem => (viejoItem.editable && (viejoItem.id === item.id)) ? nuevoItem : viejoItem));
    setItemSeleccionado(nuevoItem);
  }

  const columnas = [
    { field: 'nombre', title: 'Nombre', editable: true, onChange: changeNombre },
    { field: 'email', title: 'eMail', editable: item => !item.activo, onChange: changeEmail },
    { field: 'activo', title: 'Activo' },
    { field: 'agrupaciones', title: 'Agrupaciones', component: (items) => <ul className="">{items.map((item, index) => <li key={index}><span> {item.nombre}</span></li>)}</ul> },
    {
      field: 'acciones', title: ''/* , className: 'row-controls--block' */, component: (item) => {
        if (item.id && !item.editable) {
          return <>
            {/* <IconButton className="admin-button" iconName="qrcode" label="URL para QR" onClick={() => getCodigo(item)} visible={(!itemSeleccionado || itemSeleccionado === item)} /> */}
            <IconButton className="admin-button" iconName="edit" label="Editar" onClick={() => { item.editable = true; onItemChange(item)}} visible={(!itemSeleccionado || itemSeleccionado === item)} />
          </>
        } else {
          return <>
            <IconButton className="admin-button" iconName="check" label="Guardar" onClick={() => guardarItem(item)} />
            <IconButton className="admin-button" iconName="times" label="Cancelar" onClick={cancelEdit} />
            <IconButton className="admin-button" iconName="trash" label="Eliminar" onClick={() => bajaItem(item)} />
          </>
        }
      }
    },
  ];

  const recargarLista = (callback) => {
    const queryData = {
      comercioID: comercio.id,
      asArray: true,
    }
    APIManager.current.getList('miembros', queryData, (response) => {
      setItems(response.data.listado.map(item => {
        item.activo = !!item.idusuarios;
        return item;
      }));
      setItemSeleccionado(null);
      setItemsSeleccionados([]);
    });
  };

  const actualizar = () => {
    recargarLista();
  }

  const onItemChange = (item) => {
    if (!itemSeleccionado || (itemSeleccionado.id !== item.id)) {
      setItemSeleccionado(item);
      setItemsSeleccionados([item]);
    } else {
      setItemSeleccionado(null);
      setItemsSeleccionados([]);
    }

  }

  const nuevoItem = () => {
    const itemNuevo = {
      id: null,
      nombre: '',
      email: '',
      idusuarios: null,
      agrupaciones: [{id: 0, nombre: 'General'}],
      activo: false,
      editable: true,
    };

    setItems([...items, itemNuevo]);
    setItemSeleccionado(itemNuevo);
    setItemsSeleccionados([itemNuevo]);
  }

  const guardarItem = (item) => {
    item.idcomercios = comercio.id;

    APIManager.current.sendRequest('miembro', 'modificarMiembro', { miembro: item }, (response) => {
      if (response.success) {
        recargarLista();
      }
    });
  }

  const cancelEdit = (item) => {
    recargarLista();
  }

  const bajaItem = (item) => {
    item.idcomercios = comercio.id;
    APIManager.current.sendRequest('miembro', 'bajaDeMiembro', { miembro: item }, (response) => {
      if (response.success) {
        recargarLista();
      }
    });
  }

  useEffect(() => {
    setTitulo('Miembros', comercio);
    recargarLista();
  }, []);

return (
  <>
    <div className="screen-title">
      <h2>Listado de Miembros</h2>
      <div className="screen-title__controls">
        <IconButton className="admin-button" onClick={actualizar} label="Actualizar" />
        <IconButton className="admin-button" onClick={nuevoItem} label="Nuevo Miembro"/>
      </div>
    </div>
    <ListaDeSeleccion className='items' columnas={columnas} items={items} itemSeleccionado={itemSeleccionado} itemsSeleccionados={itemsSeleccionados} disabledOnSeleccion={false} />
  </>
);
}

export default Miembros;