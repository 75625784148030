import { createAction, createReducer } from '@reduxjs/toolkit';

const setPaginaActual = createAction('admin/setPaginaActual');
const setItemActual = createAction('admin/setItemActual');
const setItemsSeleccionados = createAction('admin/setItemsSeleccionados');
const setMenu = createAction('admin/setMenu');
const setComercio = createAction('admin/setComercio');
const setEstadosDePedido = createAction('admin/setEstadosDePedido');

const initialState = {
  paginaActual: null,
  itemActual: null,
  itemsSeleccionados: [],
  menu: [],
  comercio: null,
  estadosDePedido: [],
};

const adminReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setPaginaActual, (state, action) => {
      state.paginaActual = action.payload;
    })
    .addCase(setItemActual, (state, action) => {
      state.itemActual = action.payload;
    })
    .addCase(setItemsSeleccionados, (state, action) => {
      state.itemsSeleccionados = action.payload;
    })
    .addCase(setMenu, (state, action) => {
      state.menu = action.payload;
    })
    .addCase(setComercio, (state, action) => {
      state.comercio = action.payload;
    })
    .addCase(setEstadosDePedido, (state, action) => {
      state.estadosDePedido = action.payload;
    });
})

export default adminReducer;