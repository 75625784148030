import React, { useState } from 'react';
import APIManager from '../../lib/apiManager';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import IconButton from '../../components/IconButton';

const Signup = () => {
  const dispatch = useDispatch();
  const [nombres, setNombres] = useState(null);
  const [apellidos, setApellidos] = useState(null);
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [codigo, setCodigo] = useState(null);
  const [codigoValido, setCodigoValido] = useState(false);
  const [codigoEnviado, setCodigoEnviado] = useState(false);

  const history = useHistory();

  const signupUsuario = () => {
    APIManager.sendRequest('post', 'usuario', 'signup', {nombres, apellidos, username, password, codigo, loginType: 'username'}, (response) => {
      if (response.success) {
        dispatch({ type: 'sistema/setUsuario', payload: response.data.usuario });
        dispatch({ type: 'sistema/setCliente', payload: response.data.cliente });
        if (response.sistema && response.sistema.adminMenu) {
          dispatch({ 'type': 'admin/setMenu', payload: response.sistema.adminMenu });
        }
        history.goBack();
      } else {
        alert(response.errors[0].message);
      }
    });
  }

  const changeNombres = (event) => {
    setNombres(event.target.value);
  };

  const changeApellidos = (event) => {
    setApellidos(event.target.value);
  };

  const changeUsername = (event) => {
    setUsername(event.target.value);
  };

  const changeCodigo = (event) => {
    setCodigo(event.target.value);
  };

  const changePassword = (event) => {
    setPassword(event.target.value);
  };

  const enviarCodigo = () => {
    setCodigoEnviado(false);
    APIManager.current.sendRequest('usuario', 'sendAuthCode', { username, motivo: 'VERIFICAR_EMAIL' }, (response) => {
      if (response.success) {
        setCodigoEnviado(true);
      }
    });
  }

  const validarCodigo = () => {
    setCodigoEnviado(false);
    APIManager.current.sendRequest('usuario', 'checkCodigo', { username, motivo: 'VERIFICAR_EMAIL', codigo }, (response) => {
      if (response.success) {
        setCodigoEnviado(true);
      }
    });
  }

  const cancelar = (event) => {
    APIManager.current.navigate('/');
  }

  return (
    <div className="login-page">
      <div className='login-form'>
        <h2 className='login-title pibun-logo'>Pibun</h2>
        <p className='login-subtitle'>Registro de usuario</p>
        <div className="form__row">
          <div className="input-combo nombres">
            <input className={nombres ? 'has-value' : ''} type='text' maxLength={100} value={nombres} onChange={changeNombres} />
            <label>Nombre</label>
          </div>
          <div className="input-combo apellidos">
            <input className={apellidos ? 'has-value' : ''} type='text' maxLength={100} value={apellidos} onChange={changeApellidos} />
            <label>Apellido</label>
          </div>
        </div>
        <div className="form__row">
          <div className="input-combo username">
            <input className={username ? 'has-value' : ''} type='text' maxLength={100} value={username} onChange={changeUsername} />
            <label>email</label>
          </div>
          {(!!username && !codigoValido) &&
            <IconButton className="admin-button login-button" label="Enviar codigo" onClick={enviarCodigo} />
          }
          {!!codigoEnviado &&
            <span>Código enviado, revisa tu casilla de correo electrónico</span>
          }
        </div>
        <div className="form__row">
          <div className="input-combo username">
            <input className={codigo ? 'has-value' : ''} type='text' maxLength={100} value={codigo} onChange={changeCodigo} />
            <label>Código</label>
          </div>
          {(!!codigo && !codigoValido) &&
            <IconButton className="admin-button login-button" label="Validar" onClick={validarCodigo} />
          }
        </div>
        <div className="form__row">
          <div className="input-combo password">
            <input className={password ? 'has-value' : ''} type='password' value={password} onChange={changePassword}/>
            <label>Clave</label>
          </div>
        </div>
        <div className="form__row">
          {!!codigo &&
            <IconButton className="admin-button login-button" label="Registrarme" onClick={signupUsuario} />
          }
          <IconButton className="admin-button login-button" label="Cancelar" onClick={cancelar} />
        </div>
      </div>
    </div>
  );
}

export default Signup;