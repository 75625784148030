import react, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import Palanquita from '../../../../components/Palanquita';

const ListaDeSeleccion = ({className, columnas, items, itemsSeleccionados, itemSeleccionado, onSelectionChange, disabledOnSeleccion = true, rowSelect = true}) => {
// TODO: Agregar metodo para avisar que cambio la seleccion.

  const valueComponent = (item, columna) => {
    if (!!columna.component) {
      if (!!item[columna.field]) {
        return columna.component(item[columna.field]);
      }
      return columna.component(item);
    }
    switch (typeof item[columna.field]) {
      case 'boolean':
        return <Palanquita title={columna.title} disabled={!item.editable} active={item[columna.field]} onToggle={() => columna.onChange && columna.onChange(item)} />
/*
        if (item.editable && columna.editable)
          return <select type={typeof item[columna.field]} value={item[columna.field]} onChange={(event) => columna.onChange && columna.onChange(item, event.target.value === 'true')} >
            <option value={true}>SI</option>
            <option value={false}>NO</option>
            </select>
        else
          return <span type={typeof item[columna.field]}>{item[columna.field] ? 'SI' : 'NO'}</span>
 */
      case 'object':
        if ((item[columna.field] instanceof Date) && (!!item[columna.formatField])) {
          return <Moment date={item[columna.field]} format={item[columna.formatField]} />
        }
        if (!!item[columna.field] && !!columna.subfield) {
          if (item.editable && ((columna.editable === true) || (((typeof columna.editable) === 'function') && columna.editable(item)))) {
            if (!!columna.select)
              return <select value={item[columna.field][columna.subfield]} onChange={(event) => columna.onChange && columna.onChange(item, event.target.value)}>{columna.select.options.map(option => <option key={option.value} value={option.value} >{option.text}</option>)}</select>
            else
              return <input type={typeof item[columna.field][columna.subfield]} placeholder={columna.placeholder ?? columna.subfield} value={item[columna.field][columna.subfield]} onChange={(event) => columna.onChange && columna.onChange(item, event.target.value)} />
          }
          else
            return <span>{item[columna.field][columna.subfield]}</span>;
        }
        return <span>{columna.field + ': ' + JSON.stringify(item[columna.field])}</span>;
      default:
        if (item.editable && ((columna.editable === true) || (((typeof columna.editable) === 'function') && columna.editable(item))))
        {
          if (!!columna.select)
            return <select value={item[columna.field]} onChange={(event) => columna.onChange && columna.onChange(item, event.target.value)}>{columna.select.options.map(option => <option key={option.value} value={option.value} >{option.text}</option>)}</select>
          else
            return <input type={typeof item[columna.field]} placeholder={columna.placeholder ?? columna.field} value={item[columna.field]} onChange={(event) => columna.onChange && columna.onChange(item, event.target.value)} />
        }
        else
          return <span type={typeof item[columna.field]}>{item[columna.field]}</span>
    }
  }

  const headComponent = (columna) => {
    return columna.headComponent ? columna.headComponent(columna) : <span>{columna.title}</span>;
  }

  return (
    <table className={"table table--" + className + (disabledOnSeleccion ? ' disabledOnSeleccion' : '') + (itemsSeleccionados && (itemsSeleccionados.length > 0) ? ' has--items-selected' : '')}  disabled={disabledOnSeleccion && !!itemSeleccionado}>
      <thead>
        <tr className="table-row table-row--thead">
          {columnas.map((item, index) => {
          return <th className={"table-header-cell" + (item.className ? ' ' + item.className : '')} key={index} data-field={item.field}>{headComponent(item)}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {items && (items.length > 0) && items.map((item, index) => {
          return (
            <tr key={item.id ? item.id : index}
              onClick={(event) => {(!itemSeleccionado || (itemSeleccionado.id !== item.id) || !disabledOnSeleccion) && onSelectionChange && onSelectionChange(item, event)}}
              className={"table-row table-row--tbody " +
              (rowSelect ? "table-row__selectable " : "") +
              (itemsSeleccionados && itemsSeleccionados.find(itemEncontrado => itemEncontrado.id === item.id) ? 'is--selected' : '')
              }
            >
              {columnas.map((columna, index) => {
                return <td className={"table-data-cell" + (columna.className ? ' ' + columna.className : '')} key={index} data-field={columna.field}>{valueComponent(item, columna)}</td>;
              })}
            </tr>
          )
        })}
      </tbody>
      <tfoot></tfoot>
    </table>
  )
}

export default ListaDeSeleccion;